// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { CameraAltOutlined as CameraAltOutlinedIcon } from "@mui/icons-material"
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
// import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import { useAppDispatch } from '../../../../../hooks/global';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import Loader from '../../../../common/Loader/Loader';
import { Button } from '../../../../controls';
import './CreateID.scss';
import DetailsID from './parts/Details';
import { getStringWithoutSymbols, getFullName } from '../../../../../helpers';
import { states } from '../../../../../constants/main';
import UserModalFA from './parts/CreateOrEditUserModal';
import GeneralID from './parts/General';
import { createID } from '../../../../../store/independentDispatcher/independentDispatcher';

interface ICreateDispatcher {
  createFromModal?: boolean;
  setClickOnSubmit?: any;
  clickOnSubmit?: boolean;
  setOpenModal?: any;
  setNewDispatcher?: any;
}

const CreateID: FC<ICreateDispatcher> = ({
  createFromModal,
  setClickOnSubmit,
  clickOnSubmit,
  setOpenModal,
  setNewDispatcher,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generalOpened, setGeneralOpened] = useState(true);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [billingInfoOpened, setBillingInfoOpened] = useState(true);
  const [billingInfo, setBillingInfo] = useState<any>(null);
  const [isHazmat, setIsHazmat] = useState(1);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [mailingAddressState, setMailingAddressState] = useState(null);
  const [birth, setBirth] = useState(null);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [state, setState] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);

  const [addressMailingComponents, setAddressMailingComponents] = useState<any>(null);
  const [cityMailing, setCityMailing] = useState<any>(null);
  const [zipMailing, setZipMailing] = useState<any>(null);
  const [stateMailing, setStateMailing] = useState<any>(null);
  const [addressMailing, setAddressMailing] = useState<any>(null);

  const [assigmentNotice, setAssigmentNotice] = useState({
    alias: 'assigment_notice',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [w9, setW9] = useState({
    alias: 'w9',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [liabilityInsurance, setLiabilityInsurance] = useState({
    alias: 'liability_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [operatingPermit, setOperatingPermit] = useState({
    alias: 'operating_permit',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  useEffect(() => {
    if (addressComponents) {
      let streetNumber = '';
      let route = '';
      setAddress(addressComponents.address);

      addressComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZip(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setState(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCity(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressComponents]);

  useEffect(() => {
    if (addressMailingComponents) {
      let streetNumber = '';
      let route = '';
      setAddressMailing(addressMailingComponents.address);

      addressMailingComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZipMailing(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setStateMailing(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCityMailing(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressMailingComponents]);

  useEffect(() => {
    if (clickOnSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [clickOnSubmit]);

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');

    const docs = [];

    if (assigmentNotice.file) {
      docs.push(assigmentNotice);
    }

    if (w9.file) {
      docs.push(w9);
    }

    if (liabilityInsurance.file) {
      docs.push(liabilityInsurance);
    }

    if (operatingPermit.file) {
      docs.push(operatingPermit);
    }

    if (id) {
      setSubmitLoading(true);

      const dataForUpdate: any = {
        id: id,
        title: data.carrier_name,
        info: data.carrier_info,
        mc_authority: data.mc_authority,
        tax_id: data.tax_id,
        dot_id: data.dot,
        scac: data.scac_code,
        is_hazmat: isHazmat === 0 || isHazmat === 1 ? isHazmat : 1,
        billing_info: billingInfo,
        address: {
          address: addressComponents?.shortAddress || data.address,
          full_address: addressComponents?.address || data.address,
          city: city || data.city,
          state: addressState,
          zip: zip || data.zip,
          phone: getStringWithoutSymbols(data.office_phone),
          fax: getStringWithoutSymbols(data.office_fax),
          cell_phone: getStringWithoutSymbols(data.cell_phone),
        },
        mailing_address: {
          address: addressMailingComponents?.shortAddress || data.mailing_address,
          full_address: addressMailingComponents?.address || data.mailing_address,
          city: cityMailing || data.mailing_city,
          state: mailingAddressState,
          zip: zipMailing || data.mailing_zip,
        },
        user: {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        },
      };

      if (docs.length) {
        dataForUpdate.documents = docs;
      }

      if (avatar.temp) {
        dataForUpdate.logo = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(createID(dataForUpdate)).then(res => {
        if (res.type === 'createID/fulfilled') {
          toast.success('The Independent Dispatcher was successfully created!');
          if (setClickOnSubmit && setOpenModal) {
            const fullName = getFullName(
              res.payload.resource.user.profile.first_name,
              res.payload.resource.user.profile.last_name,
            );
            setNewDispatcher({
              id: res.payload.resource.id,
              value: fullName.length ? fullName : res.payload.resource.title,
            });
          } else {
            navigate('/users-management', {
              state: {
                tab: 'independent_dispatchers',
              },
            });
          }
        } else {
          if (setClickOnSubmit) {
            setClickOnSubmit(false);
          }
          setSubmitLoading(false);
        }
      });
    }
  };

  const onChangeBillingInfo = (ev: any) => {
    setBillingInfo(ev.target.value);
  }

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Add New Independent Dispatcher</h1>
                </div>

                {!createFromModal && (
                  <Button
                    title="BACK"
                    Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                    onClick={() =>
                      navigate('/users-management', {
                        state: {
                          tab: 'independent_dispatchers',
                        },
                      })
                    }
                    className="mapBtn"
                    variant="outlined"
                  />
                )}
              </div>
            </div>

            <div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Independent Dispatcher Details</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDetailsOpened(!detailsOpened)}
                  className={`openBtn ${!detailsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <DetailsID
                detailsOpened={detailsOpened}
                setAssigmentNotice={setAssigmentNotice}
                assigmentNotice={assigmentNotice}
                isAnyFileUploded={isAnyFileUploded}
                setIsAnyFileUploded={setIsAnyFileUploded}
                setW9={setW9}
                w9={w9}
                setLiabilityInsurance={setLiabilityInsurance}
                liabilityInsurance={liabilityInsurance}
                setOperatingPermit={setOperatingPermit}
                operatingPermit={operatingPermit}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralID
                generalOpened={generalOpened}
                control={control}
                // generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setMailingAddressState={setMailingAddressState}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setAddressComponents={setAddressComponents}
                zip={zip}
                state={state}
                city={city}
                setAddressMailingComponents={setAddressMailingComponents}
                zipMailing={zipMailing}
                stateMailing={stateMailing}
                cityMailing={cityMailing}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Billing Instructions</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setBillingInfoOpened(!billingInfoOpened)}
                  className={`openBtn ${!billingInfoOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {billingInfoOpened && <div className="billingInfoField">
                <span>Billing Information</span>
                <textarea onChange={onChangeBillingInfo} defaultValue={billingInfo}></textarea>
              </div>}

              <hr className="horizontalLine fullHr" />
            </div>

            {!createFromModal && (
              <Button
                title="SAVE ALL"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn"
                loading={submitLoading}
              />
            )}
          </div>

          {!createFromModal && (
            <div className="createDriver__userInfo">
              <div className="addNewPhotoBtn">
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={e => {
                      if (e && e.target && e.target.files) {
                        const fd = new FormData();
                        fd.append('file', e.target.files[0]);
                        dispatch(fetchUploadFile(fd)).then(res => {
                          if (res.type === 'uploadFile/fulfilled') {
                            setAvatar({
                              file: res.payload.file.fullpath,
                              temp: res.payload.file.filepath,
                            });
                          }
                        });
                      }
                    }}
                  />
                  <CameraAltOutlinedIcon />
                </IconButton>
              </div>
              <div className="userInfo__avatar">
                <img src={avatar.file} alt="" />
              </div>
              <div className="userInfo__role">Independent Dispatcher</div>
            </div>
          )}

          {
            <>
              {openCreateUserModal && (
                <UserModalFA
                  open={openCreateUserModal}
                  setOpen={setOpenCreateUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onAddNewUser}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="ADD"
                  cancelTitle="CANCEL"
                  contentTitle="Add New Contact Person"
                  formType="create"
                  loading={submitLoading}
                />
              )}

              {openEditUserModal && (
                <UserModalFA
                  open={openEditUserModal}
                  setOpen={setOpenEditUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onEditUserInfo}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="EDIT"
                  cancelTitle="CANCEL"
                  contentTitle="Edit Contact Person"
                  defaultData={editUserPrevData}
                  setDefaultData={setEditUserPrevData}
                  formType="edit"
                  loading={submitLoading}
                />
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default CreateID;