import { useAppDispatch, useAppSelector } from '../../../../hooks/global';
import '../MyProfileTC/MyProfileTC.scss';
import './Payment.scss';
import GoogleAutocompleteComponent from '../../../controls/GoogleAutocomplete/Autocomplete';
import { Button, Modal, RadioButton } from '../../../controls';
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import InputMask from 'react-input-mask';
import { loadStripe, StripeCardElement, StripeCardNumberElement } from '@stripe/stripe-js';
import { addCard, confirmPayment, processPayment } from '../../../../store/payment/payment';
import { toast } from 'react-toastify';

const TransactionDetails = ({details, setTransactionDetails}: {details: any; setTransactionDetails: any}) => {
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const input1Ref = useRef(null);
  const card = useAppSelector(state => state.payment.card);
  const navigate = useNavigate();
  const publishKey = useAppSelector(state => state.payment.stripePublishKey);

  const [nameValue, setNameValue] = useState('');
  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [currentCard, setCurrentCard] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [error, setError] = useState<any>(null);
  const [changeCardModalOpened, setChangeCardModalOpened] = useState(false);
  const [submitProcess, setSubmitProcess] = useState<any>(false);
  const [radioSelectedTwo, setRadioSelectedTwo] = useState(null);
  const [payLoading, setPayLoading] = useState(false);

  useEffect(() => {
    if (card && !currentCard && card !== 'no card') {
      setCurrentCard(card);
    } else if (card !== 'no card' && card?.id !== currentCard?.id) {
      setCurrentCard(card);
      setSubmitProcess(true);
    }
  }, [card])

  const handleCardNumberChange = (e: any, name: any) => {
    if (name === 'name') {
      setNameValue(e.target.value);
    }
  };

  useEffect(() => {
    if (publishKey) {
      const stripePromise: any = loadStripe(publishKey);
      setStripePromise(stripePromise);
    }
  }, [publishKey]);

  const cardElementStyle = {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      padding: '20px 35px',
      border: '1px solid white',
      borderRadius: '10px',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '20px',
      width: 400,
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  };

  const handleSubmit = async (event: any) => {
    setPayLoading(true);
    if (stripe && elements && radioSelectedTwo === 'add new card') {
      const cardElement: StripeCardElement | StripeCardNumberElement | any = elements.getElement(CardElement);
      let zip = null;
      let city = null;
      let state = null;
      let country = null;

      const card: any = {
        name: nameValue,
        address_line1: addressComponents?.address || null,
      };

        addressComponents?.address_components?.map((component: any) => {
          component.types.map((type: any) => {
            if (type === 'postal_code') {
              zip = component.long_name;
            } else if (type === 'administrative_area_level_1') {
              state = component.short_name;
            } else if (type === 'sublocality_level_1' || type === 'locality') {
              city = component.long_name;
            } else if (type === 'country') {
              country = component.short_name;
            }
          });
        });

        if (zip) {
          card.address_zip = zip;
        } else if (city) {
          card.address_city = city;
        } else if (state) {
          card.address_state = state;
        } else if (country) {
          card.address_country = country;
        }
  
      const { error, token } = await stripe.createToken(cardElement, {
        card,
      } as any);
  
      if (error) {
        setPayLoading(false);
        setError(error?.message);
      } else {
        if (token?.id) {
          dispatch(addCard({ onetime_pm_token: token?.id }));
        }
      }
    } else if (radioSelectedTwo === 'select current card' || !radioSelectedTwo) {
      if (submitProcess) {
        setSubmitProcess('.');
        setSubmitProcess(true);
      } else {
        setSubmitProcess(true);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
  }, [submitProcess])

  useEffect(() => {
    if (submitProcess && stripe) {
      dispatch(processPayment({payment_id: details.id})).then((res: any) => {
        setPayLoading(false);
        if (res?.payload?.message === 'Request failed with status code 402' && res?.payload?.response?.data?.data?.resource?.payment_intend_id) {
          stripe.handleCardAction(res?.payload?.response?.data?.data?.resource?.payment_intend_id)
            .then(function(result: any) {
              if (result.error) {
                setPayLoading(false);
                setSubmitProcess(false);
                toast.error(result?.error?.message);
              } else {
                if (result.paymentIntent.id) {
                  dispatch(confirmPayment({
                    payment_id: details.id,
                    payment_intent_id: result.paymentIntent.id,
                  })).then(res => {
                    if (res.type === 'confirmPayment/fulfilled') {
                      setChangeCardModalOpened(false);
                      setTransactionDetails(null);
                      setSubmitProcess(false);
                      setPayLoading(false);
                    }
                  })
                }
            }
          });
        } else {
          setChangeCardModalOpened(false);
          setTransactionDetails(null);
          setSubmitProcess(false);
          setPayLoading(false);
          toast.success("The Payment was successful!");
        }
      });
    }
  }, [submitProcess])

  return (
    <div className="transactionDetails">
      <div className="transactionRow">
      <span className="transactionLabel">Status:</span>
        <div className={`statusIcon ${
                            details.status === 'free'
                              ? 'statucIconInProgress'
                              : details.status === 'paid'
                              ? 'statucIconPaid'
                              : (details.status === 'failed' || details.status === 'confirmation')
                              ? 'statucIconFailed'
                              : details.status === 'Refunding'
                              ? 'statucIconRefunding'
                              : 'statucIconPending'
                          }`}></div>
           {details.status} 
          </div>
      {details.errorMsg && <div className="transactionRow"><span className="transactionLabel">Error Description:</span> {details.errorMsg} </div>}
      <div className="transactionRow"><span className="transactionLabel">Transaction Date:</span> {details.date} </div>
      <div className="transactionRow"><span className="transactionLabel">From:</span> {details.from} </div>
      <div className="transactionRow"><span className="transactionLabel">To:</span> {details.to} </div>
      <div className="transactionRow"><span className="transactionLabel">Package:</span> {details.package} </div>
      <div className="transactionRow"><span className="transactionLabel">Total Amount:</span> {details.total_paid ? `$${details.total_paid}` : '-'}</div>
      <div className="transactionRow"><span className="transactionLabel">Monthly Amount:</span> {details.monthly_paid ? `$${details.monthly_paid}` : '-'}</div>
      <div><span className="transactionLabel">Description:</span> {details.description}</div>
      {details?.receipt && <Button
            title="Download Receipt"
            onClick={() => null}
            variant="contained"
            disabled={details.status === 'failed' ? true : false}
            className="saveBtnPayment paymentHistory paymentDetail"
    />}
          {(details?.status === 'failed' || details?.status === 'confirmation') && <Button
            title="Change Payment Method"
            onClick={() => setChangeCardModalOpened(true)}
            variant="contained"
            className={`saveBtnPayment paymentHistory paymentDetail ${details?.receipt ? '' : 'paymentFailed'}`}
    />}

    <Modal
      open={changeCardModalOpened}
      onConfirm={(ev: any) => handleSubmit(ev)}
      confirmTitle={'PAY'}
      disabled={payLoading}
      cancelTitle={'CANCEL'}
      onCancel={() => {
        setChangeCardModalOpened(false);
      }}
      modalName="change-pay-method-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
          <RadioButton
                label=""
                className="marginRight"
                firstVariant="Select Current Card"
                secondVariant="Add New Card"
                onHandleChangeValue={null}
                defaultValue={1}
                name="methods-listt"
                setNewValue={setRadioSelectedTwo}
          />

        {(radioSelectedTwo === 'select current card' || !radioSelectedTwo) &&  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div className="currentCardContainer" style={{flex: 1}}>
            <div className="currentCardWrapper" style={{border: "none"}}>
              <div className="currentCardLast4">{card?.brand} ****{card?.last4}</div>
              <div className="currentCardExpiration" style={{marginBottom: 0}}>The card expiration date: {card?.exp_month}/{card?.exp_year}</div>
            </div>
          </div>
        </div>}
          </div>

          {radioSelectedTwo === 'add new card' && <div>
        <form onSubmit={() => null} style={{ width: 500, margin: '0px 30px' }}>
          <div className={`customizedInputContainer`}>
            <div className="label">Card Number</div>
          </div>
          <CardElement options={{ style: cardElementStyle }} />
          <div
            className={`customizedInputContainer`}
            style={{ marginBottom: 15, width: '100%' }}
          >
            <div className="label">Cardholder Name</div>
            <InputMask
              className="customizedInputContainer__input"
              onChange={e => handleCardNumberChange(e, 'name')}
              ref={input1Ref}
              mask={""}
            />
          </div>
          <div
            className={`customizedInputContainer`}
            style={{ marginBottom: 15, width: '100%' }}
          >
            <div className="label">Billing Address</div>
            <GoogleAutocompleteComponent
              label=""
              setAddressComponents={setAddressComponents}
              className="paymentAddress"
            />
          </div>
          {error && <div style={{
            color: "red",
            fontSize: "18px",
            marginBottom: "20px",
          }}>{error}</div>}
        </form>
      </div>}
    </div>
      }
    />  
    </div>
  );
};

export default TransactionDetails;