import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer, CreateDriver, CreateID, EditID } from '../../..';
import './CreateCustomerModal.scss';

interface IUserModal {
  open: any;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setSelectedDispatcher?: any;
  editMode?: boolean;
  selectedDispatcher?: any;
}

const CreateDispatcherModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setSelectedDispatcher,
  editMode,
  selectedDispatcher,
}) => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newDispatcher, setNewDispatcher] = useState<any>(null);
  const [updatedDispatcher, setUpdatedDispatcher] = useState<any>(null);

  useEffect(() => {
    if (!open.open && setDefaultData) {
      setDefaultData(null);
    }
  }, [open]);

  useEffect(() => {
    if (updatedDispatcher) {
      if (updatedDispatcher.id && updatedDispatcher.value) {
        const filteredUsersList = usersList.filter((user: any) => user.id !== selectedDispatcher.id)
        setUsersList([...filteredUsersList, updatedDispatcher]);
        setSelectedDispatcher(updatedDispatcher);
        setClickOnSubmit(false);
        setOpen(false);
        setUpdatedDispatcher(null);
      }
    }
  }, [updatedDispatcher]);

  useEffect(() => {
    if (newDispatcher) {
      if (newDispatcher.id && newDispatcher.value) {
        setUsersList([...usersList, newDispatcher]);
        setSelectedDispatcher(newDispatcher);
        setClickOnSubmit(false);
        setOpen({open: false, customer_id: null, customer_type: null, customerIndex: null})
        setNewDispatcher(null);
      }
    }
  }, [newDispatcher]);

  return (
    <Modal
      open={editMode? open : open.open}
      disabled={btnDisabled}
      onConfirm={() => setClickOnSubmit(true)}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        editMode? setOpen(false) : setOpen({open: false, customer_id: null, customer_type: null, customerIndex: null})
      }}
      modalName="customer-modal"
      content={
        <>
        {editMode ? <EditID
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          selectedDispatcher={selectedDispatcher}
          setNewDispatcher={setUpdatedDispatcher}
        /> : <CreateID
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          setNewDispatcher={setNewDispatcher}
        />}
        </>
      }
    />
  );
};

export default CreateDispatcherModal;
