import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { displayProtectedImage, displayImageSrc } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { getReportAccident, getReportAccidentPdf } from '../../../store/reports/reports';
import { IReportIncidentData } from "./interfaces/interface";
// import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import { Button } from '../../controls';
import Loader from '../../common/Loader/Loader';
import { ModalPicture } from '../../controls';
import './ReportIncident.scss';

interface IReportIncident {
  partialBlockAccess?: boolean;
}
const ReportAccident: FC<IReportIncident> = ({ partialBlockAccess }) => {
  const [isGeneralInfo, setGeneralInfo] = useState<boolean>(true);
  const [isYourVehicleInfo, setYourVehicleInfo] = useState<boolean>(false);
  const [isOtherVehicleInfo, setOtherVehicleInfo] = useState<boolean>(false);
  const [isIncidentPictures, setIncidentPictures] = useState<boolean>(false);
  const [isIncidentPicturesTicket, setIncidentPicturesTicket] = useState<boolean>(false);
  const [loadInfo, setLoadInfo] = useState<IReportIncidentData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [imageTest, setImageTest] = useState<any>(null);

  const [open, setOpen] = useState(false);
  const [arrayImages, setArrayImages] = useState<string[]>([]);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { exportLoading } = useAppSelector(state => state.reports);

  const goToReportsList = () => navigate(-1);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getReportAccident({
          id: params.id,
        }),
      ).then((res: any) => {
        if (res.type === 'getReportAccident/fulfilled') {
          if (res.payload.resource) {
            setLoadInfo(res.payload.resource);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loadInfo) setLoading(false);
    if (loadInfo?.ticket_pictures && loadInfo?.ticket_pictures?.length > 0) {
      loadInfo.ticket_pictures.forEach((picture: any) => {
        displayProtectedImage(
          picture?.id,
          picture.thumbnail ? picture.thumbnail : '',
          token,
        );
        setArrayImages(
          arrayImages => (arrayImages = [...arrayImages, picture?.thumbnail]),
        );
      });
    }
    if (loadInfo?.incident_pictures && loadInfo?.incident_pictures?.length > 0) {
      loadInfo.incident_pictures.forEach((picture: any) => {
        displayProtectedImage(
          picture?.id,
          picture.thumbnail ? picture.thumbnail : '',
          token,
        );
        setArrayImages(
          arrayImages => (arrayImages = [...arrayImages, picture?.thumbnail]),
        );
      });
    }
  }, [loadInfo]);

  const handleOpen = (picture: string) => {
    setOpen(true);
    displayImageSrc('modal', picture, token).then(res => {
      // type !== "pdf" ? setImageTest(res) : setImageTest(null)
      setImageTest(res);

      // if (type === "pdf") {
      //   setImageTest(null);
      //   setPdf(res);
      // } else {
      //   setImageTest(res);
      //   setPdf(null);
      // }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPdf = () => {
    if (params.id) {
      dispatch(getReportAccidentPdf({id: params.id}))
    }
  }

  return (
    <>
      <Loader loading={loading || exportLoading} />
      <div className="reportIncident mainContainer">
        <div className="reportIncident__container">
          <div className="reportIncident__body">
            <div className="reportIncident__header">
              <div className="reportIncident__title">
                <h1>Report</h1>
              </div>

              <div className="reportIncident__btnWrapper">
                <Button
                  onClick={() => goToReportsList()}
                  Icon={<KeyboardBackspaceTwoToneIcon />}
                  className="backBtn"
                  variant="outlined"
                  title={'back'}
                />
              </div>
            </div>
            <div className="componentHeader">
              <h2 className="componentHeader__title">
                Auto Accident Report (
                {loadInfo?.load_number ? loadInfo?.load_number : ' - '})
              </h2>
            </div>
            <div className="reportIncident__main">
              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">General Information</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setGeneralInfo(!isGeneralInfo)}
                    className={`accContainer__openBtn ${!isGeneralInfo ? '-opened' : ''}`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isGeneralInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Load Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.load_number ? loadInfo?.load_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Company Name:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.company_name ? loadInfo?.company_name : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Location Address:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.location ? loadInfo?.location : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Manager Name:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.manager_name ? loadInfo?.manager_name : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Manager Phone Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.manager_phone ? loadInfo?.manager_phone : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Date | Time:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.date ? (
                            <Moment format="MM/DD/YYYY">{loadInfo?.date}</Moment>
                          ) : (
                            ' - '
                          )}
                          |
                          {loadInfo?.date ? (
                            <Moment format="hh:mm A">{loadInfo?.date}</Moment>
                          ) : (
                            ' - '
                          )}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Place of Accident:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.place ? loadInfo?.place : ' - '}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Your Vehicle Information</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setYourVehicleInfo(!isYourVehicleInfo)}
                    className={`accContainer__openBtn ${
                      !isYourVehicleInfo ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isYourVehicleInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Driven By:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.driver?.profile?.first_name
                            ? loadInfo?.driver?.profile?.first_name
                            : ' - '}
                          &nbsp;
                          {loadInfo?.driver?.profile?.last_name
                            ? loadInfo?.driver?.profile?.last_name
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Driver Phone Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.driver_phone ? loadInfo?.driver_phone : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Vehicle Year:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.vehicle_year ? loadInfo?.vehicle_year : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Make:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.vehicle_make ? loadInfo?.vehicle_make : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Model:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.vehicle_model ? loadInfo?.vehicle_model : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Passenger Name:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.passenger_name ? loadInfo?.passenger_name : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Passenger Phone Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.passenger_phone ? loadInfo?.passenger_phone : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Nature and Extent of Damage to Vehicle:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.nature_damage ? loadInfo?.nature_damage : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Describe any injuries to Driver or Passengers:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.vehicle_damage ? loadInfo?.vehicle_damage : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Witness Information 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_info_1 ? loadInfo?.witness_info_1 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Witness Name 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_name_1 ? loadInfo?.witness_name_1 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Phone Number 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_phone_1 ? loadInfo?.witness_phone_1 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Address 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_address_1
                            ? loadInfo?.witness_address_1
                            : ' - '}
                        </div>
                      </li>
                      {loadInfo?.witness_info_2 ||
                      loadInfo?.witness_name_2 ||
                      loadInfo?.witness_phone_2 ||
                      loadInfo?.witness_address_2 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Witness Information 2:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_info_2
                                ? loadInfo?.witness_info_2
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Witness Name 2:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_name_2
                                ? loadInfo?.witness_name_2
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Phone Number 2:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_phone_2
                                ? loadInfo?.witness_phone_2
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Address 2:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_address_2
                                ? loadInfo?.witness_address_2
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}
                      {loadInfo?.witness_info_3 ||
                      loadInfo?.witness_name_3 ||
                      loadInfo?.witness_phone_3 ||
                      loadInfo?.witness_address_3 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Witness Information 3:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_info_3
                                ? loadInfo?.witness_info_3
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Witness Name 3:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_name_3
                                ? loadInfo?.witness_name_3
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Phone Number 3:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_phone_3
                                ? loadInfo?.witness_phone_3
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Address 3:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_address_3
                                ? loadInfo?.witness_address_3
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}
                      {loadInfo?.witness_info_4 ||
                      loadInfo?.witness_name_4 ||
                      loadInfo?.witness_phone_4 ||
                      loadInfo?.witness_address_4 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Witness Information 4:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_info_4
                                ? loadInfo?.witness_info_4
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Witness Name 4:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_name_4
                                ? loadInfo?.witness_name_4
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Phone Number 4:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_phone_4
                                ? loadInfo?.witness_phone_4
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Address 4:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_address_4
                                ? loadInfo?.witness_address_4
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}
                      {loadInfo?.witness_info_5 ||
                      loadInfo?.witness_name_5 ||
                      loadInfo?.witness_phone_5 ||
                      loadInfo?.witness_address_5 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Witness Information 5:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_info_5
                                ? loadInfo?.witness_info_5
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Witness Name 5:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_name_5
                                ? loadInfo?.witness_name_5
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Phone Number 5:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_phone_5
                                ? loadInfo?.witness_phone_5
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Address 5:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.witness_address_5
                                ? loadInfo?.witness_address_5
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Other Vehicle Information</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setOtherVehicleInfo(!isOtherVehicleInfo)}
                    className={`accContainer__openBtn ${
                      !isOtherVehicleInfo ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isOtherVehicleInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Driven By:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_driven_by
                            ? loadInfo?.other_vehicle_driven_by
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Date of Birth:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_driver_birth ? (
                            <Moment format="MM/DD/YYYY">
                              {loadInfo?.other_vehicle_driver_birth}
                            </Moment>
                          ) : (
                            ' - '
                          )}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Driver License #:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_driver_license
                            ? loadInfo?.other_vehicle_driver_license
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Phone Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_driver_phone
                            ? loadInfo?.other_vehicle_driver_phone
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Owner of Vihicle:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_owner_name
                            ? loadInfo?.other_vehicle_owner_name
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Owner Address:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_owner_address
                            ? loadInfo?.other_vehicle_owner_address
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Owner Phone Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_owner_phone
                            ? loadInfo?.other_vehicle_owner_phone
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Insurance Company and Policy Number:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_policy_number
                            ? loadInfo?.other_vehicle_policy_number
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Vehicle Year:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_year
                            ? loadInfo?.other_vehicle_year
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Make:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_make
                            ? loadInfo?.other_vehicle_make
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Model:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_model
                            ? loadInfo?.other_vehicle_model
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">VIN #:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_vin
                            ? loadInfo?.other_vehicle_vin
                            : ' - '}
                        </div>
                      </li>

                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Passenger Name 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_passenger_name_1
                            ? loadInfo?.other_vehicle_passenger_name_1
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Passenger 1 Phone Number:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_vehicle_passenger_phone_1
                            ? loadInfo?.other_vehicle_passenger_phone_1
                            : ' - '}
                        </div>
                      </li>

                      {loadInfo?.other_vehicle_passenger_name_2 ||
                      loadInfo?.other_vehicle_passenger_phone_2 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Passenger Name 2:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_name_2
                                ? loadInfo?.other_vehicle_passenger_name_2
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Passenger 2 Phone Number:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_phone_2
                                ? loadInfo?.other_vehicle_passenger_phone_2
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}

                      {loadInfo?.other_vehicle_passenger_name_3 ||
                      loadInfo?.other_vehicle_passenger_phone_3 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Passenger Name 3:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_name_3
                                ? loadInfo?.other_vehicle_passenger_name_3
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Passenger 3 Phone Number:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_phone_3
                                ? loadInfo?.other_vehicle_passenger_phone_3
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}

                      {loadInfo?.other_vehicle_passenger_name_4 ||
                      loadInfo?.other_vehicle_passenger_phone_4 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Passenger Name 4:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_name_4
                                ? loadInfo?.other_vehicle_passenger_name_4
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Passenger 4 Phone Number:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_phone_4
                                ? loadInfo?.other_vehicle_passenger_phone_4
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}

                      {loadInfo?.other_vehicle_passenger_name_5 ||
                      loadInfo?.other_vehicle_passenger_phone_5 ? (
                        <>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">Passenger Name 5:</div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_name_5
                                ? loadInfo?.other_vehicle_passenger_name_5
                                : ' - '}
                            </div>
                          </li>
                          <li className="accInfoList__row">
                            <div className="accInfoList__title">
                              Passenger 5 Phone Number:
                            </div>
                            <div className="accInfoList__value">
                              {loadInfo?.other_vehicle_passenger_phone_5
                                ? loadInfo?.other_vehicle_passenger_phone_5
                                : ' - '}
                            </div>
                          </li>
                        </>
                      ) : null}

                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Nature and Extent of Damage:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.passenger_name ? loadInfo?.passenger_name : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Describe any injuries to Driver or Passengers:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.other_injures ? loadInfo?.other_injures : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Statements made to the other driver:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.statements ? loadInfo?.statements : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                          Any Other Important Details:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.details ? loadInfo?.details : ' - '}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Pictures of Incident</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setIncidentPictures(!isIncidentPictures)}
                    className={`accContainer__openBtn ${
                      !isIncidentPictures ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isIncidentPictures ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accPictureList">
                      {loadInfo?.incident_pictures &&
                      loadInfo?.incident_pictures?.length > 0
                        ? loadInfo.incident_pictures.map((picture: any) => {
                            return (
                              <li key={picture?.id} className="accPictureList__item">
                                <div
                                  className="accPictureList__itemBtn"
                                  onClick={() =>
                                    handleOpen(picture.file ? picture.file : '')
                                  }
                                  id={picture?.id}
                                ></div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Pictures of Incident Ticket</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setIncidentPicturesTicket(!isIncidentPicturesTicket)}
                    className={`accContainer__openBtn ${
                      !isIncidentPicturesTicket ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isIncidentPicturesTicket ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accPictureList">
                      {loadInfo?.ticket_pictures && loadInfo?.ticket_pictures?.length > 0
                        ? loadInfo.ticket_pictures.map((picture: any) => {
                            return (
                              <li key={picture?.id} className="accPictureList__item">
                                <div
                                  className="accPictureList__itemBtn"
                                  onClick={() =>
                                    handleOpen(picture.file ? picture.file : '')
                                  }
                                  id={picture?.id}
                                ></div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Signature</h2>
                </div>

                <div className={`accContainer__contentWrapper -opened`}>
                  <div className="accContainer__content">
                    <div className="incidentFooterInfo">
                      <div className="incidentFooterInfo__row">
                        <div className="incidentFooterInfo__col">
                          <div className="incidentFooterInfo__title">
                            Email Address Auto Accident Report:
                          </div>
                          <div className="incidentFooterInfo__value">
                            {loadInfo?.email ? loadInfo?.email : ' - '}
                          </div>
                        </div>
                        <div className="incidentFooterInfo__col">
                          <div className="incidentFooterInfo__title">Sign:</div>
                          <div className="incidentFooterInfo__value">
                            {loadInfo?.sign ? loadInfo?.sign : ' - '}
                          </div>
                        </div>
                        <div className="incidentFooterInfo__col">
                          <div className="incidentFooterInfo__title">Date:</div>
                          <div className="incidentFooterInfo__value">
                            {loadInfo?.sign_date ? (
                              <Moment format="MM/DD/YYYY">{loadInfo?.sign_date}</Moment>
                            ) : (
                              ' - '
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Button title="EXPORT" onClick={() => getPdf()} variant="contained" className="exportBtn" disabled={exportLoading}/>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalPicture open={open} onClose={handleClose} /> */}
      {imageTest && <ModalPicture open={open} onClose={handleClose} src={imageTest}/>}
    </>
  );
};

export default ReportAccident;
