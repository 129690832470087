import { FC, useState } from 'react';
import Moment from 'react-moment';
import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ROUTES } from '../../../../../router/routes';
import './Table.scss';
import { getFullName } from '../../../../../helpers';
import moment from 'moment';
import { useAppSelector } from '../../../../../hooks/global';

interface ITable {
  list?: any;
  changeSortType?: any;
  setPopup?: any;
  setModalMap?: any;
  changeActiveInvoice: (param: any) => void;
  setOpenBolsScan: (load: number) => void
}

const TableComponent: FC<ITable> = ({
  list,
  changeSortType,
  setPopup,
  setModalMap,
  setOpenBolsScan,
  changeActiveInvoice,
}) => {
  const navigate = useNavigate();
  const globalAccess = useAppSelector(state => state.user.global_access);
  const location = useLocation();
  const goEditLoad = (id: number) => navigate(`/edit-load/${id}`);
  const goReportsList = () => navigate(ROUTES.REPORTS_LIST);
  const [copiedPopup, setCopiedPopup] = useState<any>({
    opened: false,
    id: 0,
  });

  const userData = localStorage.getItem('user');
  let parsedData: any = null;
  if (userData) {
    parsedData = JSON.parse(userData);
  }

  let type_: 'tc' | 'fa' | 'id' | null = null;

  if (parsedData) {
    type_ =
      parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
        ? 'tc'
        : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
        ? 'fa'
        : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
        ? 'id'
        : null;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  const getStatus = (status: string | null) => {
    if (status === 'submitted') {
      return "Submitted";
    } else if (status === 'paid') {
      return "Paid";
    } else return "";
  }

  return (
    <div className="dashboardMain">
      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell>
                Load
                <img
                  src={require('../../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('load_number')}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                Company Name
                <img
                  src={require('../../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('company')}
                />{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
                Driver
                <img
                  src={require('../../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('driver')}
                />{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
                Date
                <img
                  src={require('../../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('date')}
                />{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
                Status
                <img
                  src={require('../../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('status')}
                />{' '}
              </StyledTableCell>
              <StyledTableCell>Details</StyledTableCell>
              <StyledTableCell>BOL</StyledTableCell>
            </StyledTableHeadRow>
          </TableHead>

          {/* tempolary solution for working with real data and static */}
          {list
            ? list &&
              list.data &&
              list.data.resource && (
                <TableBody>
                  {list.data.resource.data.map((row: any) => (
                    <StyledTableRow
                      key={`${row.id}`}
                      className={`${
                        row.status === 'in-progress'
                          ? 'inProgressRow'
                          : row.status === 'completed'
                          ? 'completedRow'
                          : 'pendingRow'
                      }`}
                      onClick={() => globalAccess ? changeActiveInvoice(row) : null}
                    >
                      <StyledTableCell component="th" scope="row" className="loadsCell">
                        <p onClick={() => goEditLoad(row.id)}>{row.load_number}</p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="greyCell alignLeft"
                      >
                        {type_ === 'tc' ? parsedData?.company_name : row.truck_company ? row.truck_company?.title : ' - '}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="nowrapText"
                      >
                        {getFullName(row.driver_first_name, row.driver_last_name)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="greyCell alignLeft"
                      >
                        {row.invoice ? moment(row.invoice.created_at).format('MM/DD/YYYY') : ''}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        className="nowrapText statusCell"
                      >
                        <div
                          className={`statusIcon ${
                            row.invoice_status === 'submitted'
                              ? 'statucIconSubmitted'
                              : row.invoice_status === 'paid'
                              ? 'statucIconPending'
                              : 'hiddenBlock'
                          }`}
                        ></div>
                        {row.invoice_status ? getStatus(row.invoice_status) : ""}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell">
                        {' '}
                        <img
                          src={require('../../../../../assets/images/icons/info.png')}
                          alt="info"
                          width="35px"
                          height="35px"
                          onClick={() => goEditLoad(row.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell">
                        {' '}
                        <img
                          src={require('../../../../../assets/images/icons/scanner.png')}
                          alt="document"
                          width="30px"
                          height="30px"
                          onClick={() => setOpenBolsScan(row.id)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )
            : null}
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default TableComponent;
