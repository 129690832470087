import { FC, useState, useEffect, memo } from 'react';
import Moment from 'react-moment';
// import GoogleMap from 'google-map-react';
import './MapWithMarkers.scss';
import { Polyline, GoogleMap, OverlayView } from '@react-google-maps/api';
import moment from 'moment';

interface IMapProps {
  markers?: [] | any;
  location: object | any;
  style?: object | any;
  zoom?: number;
  selected?: number;
  onPress?: any;
  driversMarkers?: [] | any;
  selectedLoadId?: any;
  selectedLoadsIds?: any;
  historyTrips?: any;
}

const MapWithMarkers: FC<IMapProps> = ({
  markers = [],
  zoom = 10,
  driversMarkers,
  selectedLoadId,
  selectedLoadsIds,
  historyTrips,
}) => {
  const [selected, setSelected] = useState<any>(false);
  const [latCenter, setLatCenter] = useState<any>(39.74968211178185);
  const [lngCenter, setLngCenter] = useState<any>(-101.5353182957625);
  const [zoomMap, setZoomMap] = useState<number>(zoom);
  const [polylines, setPolylines] = useState<any>([]);
  const [historyTripMarkers, setHistoryTripMarkers] = useState<any>([]);
  const [currentAddress, setCurrentAddress] = useState<any>(' - ');
  const [currentAddressLatLng, setCurrentAddressLatLng] = useState<any>(null);

  const getAddress: any = (latlng: any) => {
    const geocoder: any = new google.maps.Geocoder();
    let address = ' - ';

    geocoder.geocode(
      {
        latLng: latlng,
      },
      function (results: any, status: any) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            address = results[1].formatted_address;
            if (currentAddress !== address) {
              setCurrentAddress(address);
            }
          }
        } else {
          address = ' - ';
          if (currentAddress !== address) {
            setCurrentAddress(address);
          }
        }
      },
    );
  }

  useEffect(() => {
    if (currentAddressLatLng) {
      getAddress(currentAddressLatLng);
    }
  }, [currentAddressLatLng])

  useEffect(() => {
    if (historyTrips.length) {
      const polylines_: any = [];
      const historyMarkers: any = [];

      historyTrips.map((historyTrip: any, i: number) => {
        const polyHistoryTrip = historyTrip.points?.map((point: any) => new google.maps.LatLng(+point.lat, +point.lng));

        polylines_.push(polyHistoryTrip);

        historyTrip.points?.map((pin: any, index: number) => {
          const latlng = new google.maps.LatLng(+pin?.lat, +pin?.lng);

          const marker = <OverlayView
          position={{
            lat: +pin?.lat ? +pin?.lat : 0,
            lng: +pin?.lng ? +pin?.lng : 0,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={`${i}-${index}`}
        >
          <>
          {selected === pin.id ? (
            <div className="infoMarker">
              <div className="wrap-info">
                <div className="wrap-info_table">
                <p>
                                  <span>Date/Time:</span>
                                  <span>
                                    {pin.date ? (
                                      <Moment format="MM/DD/YYYY hh:mm A">
                                        {pin.date}
                                      </Moment>
                                    ) : (
                                      ' - '
                                    )}
                                  </span>
                                </p>

                                <p>
                                  <span>Address:</span>
                                  <span>
                                    {currentAddress}
                                  </span>
                                </p>
                </div>
              </div>
            </div>
          ) : null}

          {pin.customerId ? 
          (
            <div className="withTimestamp">
              {pin.timestamp && <span className="timestampWrapper">{moment(pin.timestamp).format("LLL")}</span>}
            <img
            src={require('../../../assets/images/icons/point-checked-grey.png')}
            width="15px"
            height="15px"
            onClick={(e: any) => {
              setCurrentAddressLatLng(latlng);
              setSelected((selected: any) => (selected === pin.id ? null : pin.id));
              setLatCenter(+pin.lat);
              setLngCenter(+pin.lng);
            }}
          />
          </div>
          ) : (
            <img
            src={require('../../../assets/images/icons/point-gray.png')}
            width="15px"
            height="15px"
            onClick={(e: any) => {
              setCurrentAddressLatLng(latlng);
              setSelected((selected: any) => (selected === pin.id ? null : pin.id));
              setLatCenter(+pin.lat);
              setLngCenter(+pin.lng);
            }}
          />
          )
        }
          {/* <img
            src={require('../../../assets/images/icons/point-gray.png')}
            width="15px"
            height="15px"
            onClick={(e: any) => {
              setCurrentAddressLatLng(latlng);
              setSelected((selected: any) => (selected === pin.id ? null : pin.id));
              setLatCenter(+pin.lat);
              setLngCenter(+pin.lng);
            }}
          /> */}
          </>
        </OverlayView>

        historyMarkers.push(marker);
      });

      setHistoryTripMarkers(historyMarkers);
      setPolylines(polylines_);
    });
    } else {
      setHistoryTripMarkers([]);
      setPolylines([]);
    }
  }, [historyTrips, selected, currentAddress])

  useEffect(() => {
    if (historyTrips?.length) {
      const lastTrip = historyTrips[historyTrips.length - 1];
      setLatCenter(lastTrip ? +lastTrip.points[lastTrip.points.length - 1]?.lat : 39.74968211178185);
      setLngCenter(lastTrip ? +lastTrip.points[lastTrip.points.length - 1]?.lng : -101.5353182957625);
      setZoomMap(16);
    } else {
      setLatCenter(markers?.[0] ? +markers?.[0]?.lat : 39.74968211178185);
      setLngCenter(markers?.[0] ? +markers?.[0]?.lng : -101.5353182957625);
      if (markers.length < 1) {
        setZoomMap(4);
      } else {
        setZoomMap(6);
      }
    }
  }, [markers, historyTrips]);

  const bounds = new window.google.maps.LatLngBounds();

  const Marker = (props: any) => {
    const { name, option, city, date, treiler, truck, phone, nameDriver, id, lat, lng, timestamp } =
      props;
    const location = new window.google.maps.LatLng(lat, lng);
    bounds.extend(location);

    return (
      <OverlayView
      position={{
        lat: +lat ? +lat : 0,
        lng: +lng ? +lng : 0,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      key={id}
    >
      <div className="markerContainer">
      {selected !== id && <div
        className={`marker ${option}`}
        style={{ cursor: 'pointer' }}
        title={name}
      >
        <div className="valueMarker">
          {name}
        </div>
      </div>}

      {selected === id ? (
        <div className={`infoContainer ${option}`}>
            <div className="infoMarker">
              <div className="wrap-info">
                <p className="wrap-info_tittle">{city}</p>
                <div className="wrap-info_subtitle">ETA</div>
                <div className="wrap-info_table">
                  <p>
                    <span>Date:</span>
                    <span>
                      {date ? <Moment format="MM/DD/YYYY">{date}</Moment> : ' - '}
                    </span>
                  </p>
                  <p>
                    <span>Time:</span>
                    <span>{date ? <Moment format="hh:mm A">{date}</Moment> : ' - '}</span>
                  </p>
                  <p>
                    <span>Driver:</span>
                    <span>{nameDriver}</span>
                  </p>
                  <p>
                    <span>Truck #:</span>
                    <span>{truck ? truck : ' - '}</span>
                  </p>
                  <p>
                    <span>Trailer #:</span>
                    <span>{treiler ? treiler : ' - '}</span>
                  </p>
                  {timestamp && <p style={{color: "white", fontWeight: "bold", fontSize: 13, marginTop: 20, textTransform: "initial"}}><span style={{marginLeft: 0}}>Confirmed by driver on {moment(timestamp).format("MMMM Do YYYY [at] h:mm A")}</span>
                  </p>}
                </div>
              </div>
            </div>
            </div>
          ) : null}

        {option === 'in-progress' ? <img
            src={require('../../../assets/images/icons/customer-pin-blue.png')}
            width="21px"
            height="21px"
            onClick={(e: any) => {
              setSelected((selected: any) => (selected === id ? null : id));
              setLatCenter(+lat);
              setLngCenter(+lng);
            }}
          /> : option === 'pending' ? <img
          src={require('../../../assets/images/icons/customer-pin-green.png')}
          width="21px"
          height="21px"
          onClick={(e: any) => {
            setSelected((selected: any) => (selected === id ? null : id));
            setLatCenter(+lat);
            setLngCenter(+lng);
          }}
        /> : <img
        src={require('../../../assets/images/icons/customer-pin-gray.png')}
        width="21px"
        height="21px"
        onClick={(e: any) => {
          setSelected((selected: any) => (selected === id ? null : id));
          setLatCenter(+lat);
          setLngCenter(+lng);
        }}
      />}
      </div>
      </OverlayView>
    );
  };

  const DriverMarker = (props: any) => {
    const { name, nameDriver, option, city, date, trailer, truck, phone, id, lat, lng, pinType, pinColor, dirDeg } =
      props;
    const location = new window.google.maps.LatLng(lat, lng);
    bounds.extend(location);
    return (
      <OverlayView
      position={{
        lat: +lat ? +lat : 0,
        lng: +lng ? +lng : 0,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      key={id}
    >
      <>
          {selected === name ? (
            <div className="infoMarker">
              <div className="wrap-info">
                <div className="wrap-info_table">
                  <p>
                    <span>Load #:</span>
                    <span>{name}</span>
                  </p>
                  <p>
                    <span>Driver:</span>
                    <span>{nameDriver}</span>
                  </p>
                  <p>
                    <span>Truck #:</span>
                    <span>{truck ? truck : ' - '}</span>
                  </p>
                  <p>
                    <span>Trailer #:</span>
                    <span>{trailer ? trailer : ' - '}</span>
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {pinType === 'circle' ? pinColor === 'black' ? <img
            src={require('../../../assets/images/icons/driver-pin.png')}
            width="24px"
            height="24px"
            onClick={(e: any) => {
              setSelected((selected: any) => (selected === name ? null : name));
              setLatCenter(+lat);
              setLngCenter(+lng);
            }}
          /> : <img
          src={require('../../../assets/images/icons/driver-pin-active.png')}
          width="24px"
          height="24px"
          onClick={(e: any) => {
            setSelected((selected: any) => (selected === name ? null : name));
            setLatCenter(+lat);
            setLngCenter(+lng);
          }}
        /> : <img
        src={require('../../../assets/images/icons/arrow.png')}
        width="24px"
        height="24px"
        style={{transform:`rotate(${dirDeg ? dirDeg : 0}deg)`}}
        onClick={(e: any) => {
          setSelected((selected: any) => (selected === name ? null : name));
          setLatCenter(+lat);
          setLngCenter(+lng);
        }}
      />}
          </>
      </OverlayView>
    );
  };

  const arrayOfMarkers = markers.map((marker: any) => {
    const { lat, lng, load, id, address, treiler, truck, name, date, phone, status, timestamp } =
      marker;

    return (
      (lat && lng) ? <Marker
        lat={+lat}
        lng={+lng}
        name={load}
        key={id}
        option={status}
        city={address}
        treiler={treiler}
        truck={truck}
        phone={phone}
        nameDriver={name}
        date={date}
        id={id}
        timestamp={timestamp}
      /> : null
    );
  });

  const arrayOfDriversMarkers = driversMarkers.map((marker: any) => {
    const { lat, lng, load, id, city, trailer, truck, name, date, phone, status, pinType, pinColor, dirDeg, timestamp } =
      marker;

    return (
      (lat && lng) ? <DriverMarker
        lat={+lat}
        lng={+lng}
        name={load}
        key={id}
        option={status}
        city={city}
        trailer={trailer}
        truck={truck}
        phone={phone}
        nameDriver={name}
        date={date}
        id={id}
        pinType={pinType}
        pinColor={pinColor}
        dirDeg={dirDeg}
      /> : null
    );
  });

const getOptions = (color: string, paths: any) => {
  return {
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 1.2,
    fillColor: color,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: paths,
    zIndex: 20000,
  };
};

const onLoad = (polyline: any) => {
  // console.log('polyline: ', polyline);
};

const polylinesList = polylines.map((polyline: any, index: number) => {
  return (
    <Polyline
                  onLoad={onLoad}
                  path={polyline}
                  options={getOptions('#524f4f', polyline)}
                  key={`polyline-${index}-${new Date()}`}
                />
  )
})

  return (
    <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMap
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: true,
                    fullscreenControl: false,
                  }}
                  mapContainerStyle={{
                    width: '100%',
                    height: '70vh',
                  }}
                  center={{
                    lat: latCenter,
                    lng: lngCenter,
                  }}
                  zoom={zoomMap}
                  onLoad={(map) => {
                    setLatCenter(latCenter);
                    setLngCenter(lngCenter);
                    setZoomMap(zoomMap);
                    if (markers?.[0]) map.fitBounds(bounds);
                  }}
                >
        {polylinesList}
        {historyTripMarkers}
        {arrayOfMarkers}
        {arrayOfDriversMarkers}
      </GoogleMap>
    </div>
  );
};

export default memo(MapWithMarkers);
