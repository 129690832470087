import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api, { apiWithBaseUrl } from '../../service/api';

export const addCard = createAsyncThunk('addCard', async (data: any) => {
  // const response = await api.post(`payments/add-card?onetime_pm_token=${data.onetime_pm_token}`, data);
  const response = await api.post(`payments/add-card`, data);
  return response.data;
});

export const getCardInfo = createAsyncThunk('getCardInfo', async () => {
  const response = await api.get(`payments/get-card`);
  return response;
});

export const getPaymentsList = createAsyncThunk('getPaymentsList', async () => {
  const response = await api.get(`payments/list`);
  return response;
});

export const processPayment = createAsyncThunk('processPayment', async (data: any) => {
  const response = await api.post(`payments/${data.payment_id}/process-payment`, data);
  return response;
});

export const confirmPayment = createAsyncThunk('confirmPayment', async (data: any) => {
  const response = await api.post(`payments/${data.payment_id}/confirm-payment`, data);
  return response;
});

interface PaymentState {
  carrierInfo: string | null;
  companyName: string | null;
  manageFileLoading: boolean,
  card: any;
  stripePublishKey: any;
  addCardLoading: boolean;
  listLoading: boolean;
  paymentsList: any;
  nextPaymentDate: any;
  needUpdateList: any;
}

const initialState: PaymentState = {
  carrierInfo: null,
  companyName: null,
  manageFileLoading: false,
  card: null,
  stripePublishKey: null,
  addCardLoading: false,
  paymentsList: null,
  listLoading: false,
  nextPaymentDate: null,
  needUpdateList: false,
};

export const payment = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
    .addCase(getCardInfo.fulfilled, (state, action: any) => {
      state.stripePublishKey = action.payload.data.resource.publishable_key;
      // state.card = action.payload.data.resource.items[0] ? action.payload.data.resource.items[0] : null;
      state.card = action.payload.data.resource.items[0] ? action.payload.data.resource.items[0] : 'no card';
      state.nextPaymentDate = action.payload.data.resource.next_payment_at;
    })
    .addCase(addCard.pending, (state, action: any) => {
      state.addCardLoading = true;
    })
    .addCase(addCard.fulfilled, (state, action: any) => {
      toast.success("The Card was successfully added!")
      state.card = action.payload.resource;
      state.addCardLoading = false;
    })
    .addCase(getPaymentsList.pending, (state, action: any) => {
      state.listLoading = true;
    })
    .addCase(getPaymentsList.fulfilled, (state, action: any) => {
      state.paymentsList = action?.payload?.data?.resource?.items?.data;
      state.listLoading = false;
    })
    .addCase(processPayment.fulfilled, (state, action: any) => {
      state.needUpdateList = true;
    })
    .addCase(confirmPayment.fulfilled, (state, action: any) => {
      toast.success("The Payment was successful!");
      // state.needUpdateList = true;
      state.needUpdateList = 'needeed';
    })
    .addCase(addCard.rejected, (state, action: any) => {
      state.addCardLoading = false;
    })
  },
});

export default payment.reducer;