import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { ROUTES } from './routes';
import {
  SignIn,
  SignUp,
  ResetPassword,
  Home,
  Dashboard,
  SetPassword,
  MyProfile,
  EmailVerify,
  UserManagement,
  CreateDriver,
  EditDriver,
  CreateFA,
  CreateID,
  CreateTC,
  CreateCustomer,
  EditTC,
  EditFA,
  LoadsList,
  FullMap,
  EditID,
  EditCustomer,
  MapView,
  ReportsList,
  ReportAccident,
  TicketIncident,
  CreateLoad,
  EditLoad,
  Tracking,
  ReportDamage,
  Invoicing,
  LoginAs,
} from '../components/pages';
import { ThemeProvider } from '@mui/material';
import { theme } from '../assets/styles/themes';
import NotFoundPage from '../components/common/NotFoundPage/NotFoundPage';
import Header from '../components/common/Header/Header';
import { useAppDispatch, useAppSelector } from '../hooks/global';
import { getTruckCompanyInfo } from '../store/truckCompany/truckCompany';
import { getIndependentDispatcherInfo } from '../store/independentDispatcher/independentDispatcher';
import { getFreightAgentInfo } from '../store/freightAgent/freightAgent';
import Banner from '../components/common/Banner/Banner';
import PrivacyPolicy from '../components/pages/Agreement/PrivacyPolicy';
import UserAgreement from '../components/pages/Agreement/UserAgreement';
import { toast } from 'react-toastify';

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem('token');
  const entityType = localStorage.getItem('entity_type');
  const userId = localStorage.getItem('user_id');
  const userData = localStorage.getItem('user');
  const id = localStorage.getItem('owner_id');
  const dispatch = useAppDispatch();
  const globalAccess = useAppSelector(state => state.user.global_access);

  const tcSelector = useAppSelector(state => state.truckCompany);
  const idSelector = useAppSelector(state => state.independentDispatcher);
  const faSelector = useAppSelector(state => state.freightAgent);

  const [email, setEmail] = useState<string>('Username');
  const [avatar, setAvatar] = useState<any>(null);
  const [blockAccess, setBlockAccess] = useState<boolean>(false);
  const [partialBlockAccess, setPartialBlockAccess] = useState<boolean>(false);
  const [headerHidden, setHeaderHidden] = useState(false);
  const [role, setRole] = useState<any>(null);

  useEffect(() => {
    if (
      location.pathname === '/restore-password/' ||
      location.pathname === '/set-password/'
    ) {
      const searchArr = location.search.split('=');
      if (searchArr.length && searchArr[1]?.length) {
        localStorage.setItem('recovery_token', searchArr[1]);
      }
      navigate('/restore-password');
    }

    if (location.pathname === '/user-verify/') {
      const searchArr = location.search.split('=');
      if (searchArr.length && searchArr[1]?.length) {
        localStorage.setItem('verify_token', searchArr[1]);
      }
      navigate('/email-verify');
    }

    if (location.pathname === '/login-process/') {
      localStorage.removeItem('isSeenBlockAccess');
      localStorage.setItem("loginAs", "yes");
      
      setHeaderHidden(true);
      const searchArr = location.search.split('/');
      const tokenArr = searchArr[0].split('=');
      const entityTypeArr = searchArr[1].split('=');

      if (tokenArr.length && tokenArr[1]?.length) {
        localStorage.setItem('token', tokenArr[1]);
      }

      if (entityTypeArr.length && entityTypeArr[1]?.length) {
        localStorage.setItem('entity_type', entityTypeArr[1]);
      }

      navigate('/login-process');
    }
  }, []);

  useEffect(() => {
    if (tcSelector && tcSelector.logo && role === 'tc') {
      setAvatar(tcSelector.logo);
    }
  }, [tcSelector, role]);

  useEffect(() => {
    if (idSelector && idSelector.logo && role === 'id') {
      setAvatar(idSelector.logo);
    }
  }, [idSelector, role]);

  useEffect(() => {
    if (faSelector && faSelector.logo && role === 'fa') {
      setAvatar(faSelector.logo);
    }
  }, [faSelector, role]);

  useEffect(() => {
    if (userData && id) {
      const parsedData = JSON.parse(userData);
      if (parsedData) {
        if (entityType && userId) {
          if (entityType === 'tc') {
            setRole('tc');
            dispatch(getTruckCompanyInfo(+id));
          } else if (entityType === 'id') {
            setRole('id');
            dispatch(getIndependentDispatcherInfo(+id));
          } else if (entityType === 'fa') {
            setRole('fa');
            dispatch(getFreightAgentInfo(+id));
          }
        } else {
          if (parsedData.role === 'tc-admin' || parsedData.role === 'tc-user') {
            setRole('tc');
            dispatch(getTruckCompanyInfo(+id));
          } else if (parsedData.role === 'id-admin' || parsedData.role === 'id-user') {
            setRole('id');
            dispatch(getIndependentDispatcherInfo(+id));
          } else if (parsedData.role === 'fa-admin' || parsedData.role === 'fa-user') {
            setRole('fa');
            dispatch(getFreightAgentInfo(+id));
          }
        }
        setEmail(parsedData.company_name);

        if (parsedData.creator_id) {
          setBlockAccess(true);
          setPartialBlockAccess(true);
        } else {
          if (parsedData.role.includes('user')) {
            setBlockAccess(false);
            setPartialBlockAccess(true);
          } else {
            setPartialBlockAccess(false);
            setBlockAccess(false);
          }
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!globalAccess) {
      // toast.warning('The application failed to process the recent payment. For full access, please go to the payment history section and retry the payment.', {
      //   autoClose: 10000,
      // })
      setBlockAccess(true);
      setPartialBlockAccess(true);
    }
  }, [globalAccess])

  return (
    <ThemeProvider theme={theme}>
      {isAuth && !headerHidden && <Header email={email} avatar={avatar} blockAccess={blockAccess} />}
      {isAuth && !headerHidden && <Banner />}

      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <NotProtectedRoute>
              <Home />
            </NotProtectedRoute>
          }
        />
        <Route path={ROUTES.TRACKING} element={<Tracking isAuth={isAuth} />} />
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <NotProtectedRoute>
              <SignIn />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <NotProtectedRoute>
              <SignUp />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.RECOVERY_PASSWORD}
          element={
            <NotProtectedRoute>
              <ResetPassword />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.RESTORE_PASSWORD}
          element={
            <NotProtectedRoute>
              <SetPassword />
            </NotProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EMAIL_VERIFY}
          element={
            <NotProtectedRoute>
              <EmailVerify />
            </NotProtectedRoute>
          }
        />

        <Route
          path={ROUTES.LOGIN_PROCESS}
          element={
            <NotProtectedRoute headerHidden={headerHidden}>
              <LoginAs setHeaderHidden={setHeaderHidden}/>
            </NotProtectedRoute>
          }
        />

        <Route
          path={ROUTES.DASHBOARD}
          element={
            <ProtectedRoute>
              <Dashboard blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.INVOICING}
          element={
            <ProtectedRoute>
              <Invoicing blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route path={ROUTES.LOADS_MAP} element={<FullMap />} />

        <Route
          path={ROUTES.MY_PROFILE}
          element={
            <ProtectedRoute>
              {
                <MyProfile
                  tcSelector={tcSelector}
                  idSelector={idSelector}
                  faSelector={faSelector}
                  role={role}
                />
              }
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.USER_MANAGEMENT}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <UserManagement partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.HTML_MAP_VIEW}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <MapView partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORTS_LIST}
          element={
            <ProtectedRoute>
              <ReportsList partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORT_ACCIDENT}
          element={
            <ProtectedRoute>
              <ReportAccident />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORT_DAMAGE}
          element={
            <ProtectedRoute>
              <ReportDamage />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.TICKET_INCIDENT}
          element={
            <ProtectedRoute>
              <TicketIncident partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.LOADS}
          element={
            <ProtectedRoute>
              <LoadsList blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_DRIVER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateDriver />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_DRIVER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditDriver />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_FA}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateFA />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_ID}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateID />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_TC}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateTC />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_CUSTOMER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateCustomer />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_TC}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditTC />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_CUSTOMER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditCustomer />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_FA}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditFA />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_ID}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditID />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_LOAD}
          element={
            <ProtectedRoute>
              <CreateLoad blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_LOAD}
          element={
            <ProtectedRoute>
              <EditLoad blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.PRIVACY_POLICY}
          element={
            <PrivacyPolicy />
          }
        />

        <Route
          path={ROUTES.USER_AGREEMENT}
          element={
              <UserAgreement />
          }
        />

        {location && location.pathname !== '/user-verify/' && (
          <Route
            path="*"
            element={
              <NotFoundPage title={isAuth ? 'GO TO PROFILES PAGE' : 'GO TO SIGN IN'} />
            }
          />
        )}
      </Routes>
    </ThemeProvider>
  );
};

interface IPropTypes {
  children: any;
  blockAccess?: boolean;
  partialBlockAccess?: boolean;
  headerHidden?: boolean;
}

const ProtectedRoute: React.FC<IPropTypes> = ({
  children,
  blockAccess,
  partialBlockAccess,
}) => {
  const isAuth = localStorage.getItem('token');

  if (!isAuth) {
    return <Navigate to="/sign-in" replace />;
  }

  if (blockAccess === true) {
    return <Navigate to="/dashboard" replace />;
  }

  if (partialBlockAccess === true) {
    return <Navigate to="/users-management" replace />;
  }

  return children;
};

const NotProtectedRoute: React.FC<IPropTypes> = ({ children, headerHidden }) => {
  const isAuth = localStorage.getItem('token');

  if (isAuth && !headerHidden) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default Router;
