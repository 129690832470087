import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/global';
import { fetchUploadFile } from '../../../store/uploadFile/uploadFile';
import './UploadButton.scss';
import { Visibility } from '@mui/icons-material';

interface IUploadButton {
  label: string;
  anotherLabel?: any;
  className?: string;
  fileFullName: string;
  changeValue: any;
  value: any;
  readOnly?: boolean;
}

const UploadButton: FC<IUploadButton> = ({
  label,
  anotherLabel,
  className,
  fileFullName,
  changeValue,
  value,
  readOnly,
}) => {
  const dispatch = useAppDispatch();
  // const fileFullName = 'Fsdcvsdclksdcksdjkcksdcsjkdjcsklsdljscklsdcjlksdcjscdscdsdcsdcsdcsdclcjs_qwerty.pdf';

  const [fileName, setFileName] = useState<string>('');
  const [val, setVal] = useState('');

  useEffect(() => {
    if (value && value.title && value.title.length > 40) {
      setVal(value.fullpath ? value.fullpath : value.file);
      
      const arrayFileName = value.title.split('.');
      const firstPartOfName = `${arrayFileName[0].slice(
        0,
        20,
      )}...${arrayFileName[0].slice(
        arrayFileName[0].length - 3,
        arrayFileName[0].length,
      )}.${arrayFileName[1]}`;
      setFileName(firstPartOfName);
    } else {
      setFileName(value.title);
    }
  }, [value]);

  const onHandleChange = (e: any) => {
    if (e && e.target && e.target.files) {
      const fd = new FormData();
      fd.append('file', e.target.files[0]);
      dispatch(fetchUploadFile(fd)).then((res: any) => {
        if (res.type === 'uploadFile/fulfilled') {
          changeValue({
            ...value,
            delete: 0,
            file: res.payload.file.filepath,
            title: res.payload.file.name,
            fullpath: res.payload.file.fullpath,
          });
        }
      });
    }
  };

  return (
    <div
      className={`uploadContainer ${className ? className : ''} uploadContainer__custom`}
    >
      {!anotherLabel && <div className="label">{label}</div>}
      {anotherLabel && anotherLabel}

      <div className="uploadBtnContainer">
      {value && <div className="browseBtn">
          {fileName.length ? (
            <span>{fileName}</span>
          ) : (
            <span className="hiddenBlock">Uploading file</span>
          )}

          {fileName.length ? (
            <div className="showRateCon">
              <Visibility color="primary" />
              <a
                href={val}
                target="_blank"
                className="fullpathLink"
                rel="noreferrer"
              >
                click
              </a>
            </div>
          ) : null}
        </div>}
        <div className="uploadBtn">
          <label htmlFor="file-input">
            Upload
            {!readOnly && (
              <input hidden type="file" onChange={onHandleChange} id="file-input" />
            )}
            {/* <input hidden type="file" onChange={onHandleChange} /> */}
          </label>
        </div>
      </div>
    </div>
  );
};

export default UploadButton;
