import { IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/global';
import { fetchUploadFile } from '../../../store/uploadFile/uploadFile';
import './UploadFileButton.scss';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { displayProtectedImage, getFullpathString } from '../../../helpers';

interface IUploadButton {
  label: string;
  className?: string;
  changeValue?: any;
  value?: any;
  type?: any;
}

const UploadFileButton: FC<IUploadButton> = ({
  label,
  className,
  changeValue,
  value,
  type,
}) => {
  const token = localStorage.getItem('token');
  const dispatch = useAppDispatch();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [openFile, setOpenFile] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fullpathLink, setFullpathLink] = useState<any>(null);

  function onDocumentLoadSuccess(res: any) {
    if (res && res.numPages) {
      setNumPages(res.numPages);
    }
  }

  useEffect(() => {
    if (value) {
      if (value.fullpath && token) {
        getFullpathString(value.fullpath, token).then(res => {
          setFullpathLink(res);
        });
      }
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      if (value.preview && token) {
        displayProtectedImage(value.alias, value.preview, token);
      }
    }
  }, [value]);

  const onClickPrevious = () => {
    setPageNumber(pageNumber - 1);
  };

  const onClickNext = () => {
    setPageNumber(pageNumber + 1);
  };

  const onHandleChange = (e: any) => {
    setFileLoading(true);

    if (e && e.target && e.target.files) {
      const fd = new FormData();
      fd.append('file', e.target.files[0]);
      dispatch(fetchUploadFile(fd)).then(res => {
        setTimeout(() => setFileLoading(false), 2000);

        if (res.type === 'uploadFile/fulfilled') {
          changeValue({
            ...value,
            delete: 0,
            file: res.payload.file.filepath,
            title: res.payload.file.original_name,
            preview: res.payload.file.thumbnail,
            fullpath: res.payload.file.fullpath,
          });
        }
      });
    }
  };

  const onDelete = () => {
    if (value) {
      if (value.fullpath) {
        setFullpathLink(null);
        changeValue({
          ...value,
          delete: 1,
          file: null,
          title: null,
          preview: null,
          fullpath: null,
        });
      }
    }
  };

  return (
    <div className={`uploadContainer ${className ? className : ''}`}>
      {value && value.fullpath && (
        <div className="label">
          {label && <span>{label}</span>}
          <div className="deleteFileContainer deleteFlexEnd" onClick={onDelete}>
            <span>Delete</span>
            <CancelRoundedIcon />
          </div>
        </div>
      )}
      {value && !value.fullpath && (
        <div className="label">
          {label && <span>{label}</span>}
          <div
            className="deleteFileContainer deleteFlexEnd hiddenBlock"
            onClick={onDelete}
          >
            <span>Delete</span>
            <CancelRoundedIcon />
          </div>
        </div>
      )}
      <div className={`uploadBtnContainer`}>
        {value && value.preview && (
          <div className="uploadedFileContainer" id={value.alias}></div>
        )}
        {value && value.fullpath && !value.preview && (
          <div className="uploadedFileContainer withoutFullpath"></div>
        )}

        {!fileLoading ? (
          <IconButton color="primary" aria-label="upload picture" component="label">
            {value && !value.fullpath && (
              <>
                {type === 'image' ? (
                  <input
                    hidden
                    type={'file'}
                    onChange={onHandleChange}
                    accept={'image/png, image/jpeg, image/jpg, image/svg'}
                  />
                ) : (
                  <input
                    hidden
                    type={'file'}
                    onChange={onHandleChange}
                  />
                )}
              </>
            )}
            {fullpathLink && (
              <a
                href={fullpathLink}
                rel="noreferrer"
                target="_blank"
                className="fullpathLink"
              >
                click
              </a>
            )}
            <CameraAltOutlinedIcon />
          </IconButton>
        ) : (
          <div className="spinner">
            <div />
            <div />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFileButton;
