import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { createDriverData_, createTruckCompanyData } from './testData';
import './UsersList.scss';
import { useAppDispatch } from '../../../../hooks/global';
import { getDriversList } from '../../../../store/driver/driver';
import { useNavigate } from 'react-router';
import Loader from '../../../common/Loader/Loader';
import { getMaskedPhone } from '../../../../helpers';
import { getTruckCompanyList } from '../../../../store/truckCompany/truckCompany';

interface ITCList {
  partialBlockAccess?: boolean;
}

const TCList: FC<ITCList> = ({ partialBlockAccess }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [users, setUsers] = useState<any>(null);
  const [listLoading, setListLoading] = useState<boolean>(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  const getPhoneString = (firstPhone: string | number, secondPhone: string | number) => {
    if (firstPhone && secondPhone) {
      return `${firstPhone + '\xa0\xa0' + '|' + '\xa0\xa0' + secondPhone}`;
    } else if (firstPhone && !secondPhone) {
      return firstPhone;
    } else if (!firstPhone && secondPhone) {
      return secondPhone;
    } else {
      return '';
    }
  };

  useEffect(() => {
    const id = localStorage.getItem('owner_id');

    if (id) {
      setListLoading(true);

      dispatch(getTruckCompanyList()).then(res => {
        if (res.type === 'getTruckCompanyList/fulfilled') {
          if (res.payload.resource.data) {
            const usersArr: any = [];
            res.payload.resource.data.map((user: any) => {
              usersArr.push(
                createTruckCompanyData(
                  user.title,
                  user.address.city,
                  user.address.state?.title,
                  user.mc_authority,
                  user.scac,
                  user.address.phone,
                  user.address.fax,
                  user.address.cell_phone,
                  user.id,
                ),
              );
            });

            setUsers(usersArr);
            setListLoading(false);
          }
        }
      });
    }
  }, []);

  const goToEdit = (tcId: number) => {
    if (partialBlockAccess) {
      return null;
    } else {
      navigate('/edit-truck-company', { state: { id: tcId } });
    }
  };

  return (
    <div className="tableMain">
      <Loader loading={listLoading} />

      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell align="left">
                Company Name
              </StyledTableCell>
              <StyledTableCell align="left">
                City
              </StyledTableCell>
              <StyledTableCell align="left">
                State
              </StyledTableCell>
              <StyledTableCell align="left">MC Authority #</StyledTableCell>
              <StyledTableCell align="left">SCAC Code</StyledTableCell>
              <StyledTableCell align="left">Office Phone</StyledTableCell>
              <StyledTableCell align="left">Office Fax</StyledTableCell>
              <StyledTableCell align="left">Cell Phone</StyledTableCell>
              {/* <StyledTableCell align="left">
                Status{' '}
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                />
              </StyledTableCell> */}
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {users &&
              users.map((row: any, index: number) => (
                <StyledTableRow
                  key={`${row.email}-${index}`}
                  onClick={() => goToEdit(row.id)}
                >
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.companyName}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="greyCell alignLeft">
                    {row.city}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="greenCell alignLeft">
                    {row.state}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="nowrapText">
                    {row.mcAuthority}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="alignLeft">
                    {row.scac}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="alignLeft">
                    {getMaskedPhone(row.officePhone)}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="alignLeft">
                    {getMaskedPhone(row.officeFax)}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="alignLeft">
                    {getMaskedPhone(row.cellPhone)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default TCList;
