// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { CameraAltOutlined as CameraAltOutlinedIcon } from "@mui/icons-material"
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
// import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import Loader from '../../../../common/Loader/Loader';
import { Button } from '../../../../controls';
import './EditID.scss';
import ContactPersonsID from './parts/ContactPersons';
import DetailsTC from './parts/Details';
import { getFullName, getStringWithoutSymbols } from '../../../../../helpers';
import { states } from '../../../../../constants/main';
import UserModalFA from './parts/CreateOrEditUserModal';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUsersList,
} from '../../../../../store/user/user';
import {
  getIndependentDispatcherInfo,
  updateIndependentDispatcherInfo,
  deleteIndependentDispatcher,
} from '../../../../../store/independentDispatcher/independentDispatcher';
import GeneralID from './parts/General';
import DeleteModalConfirmation from '../../../../controls/DeleteModalConfirmation/DeleteModalConfirmation';

interface IEditDispatcher {
  createFromModal?: boolean;
  setClickOnSubmit?: any;
  clickOnSubmit?: boolean;
  setOpenModal?: any;
  setNewDispatcher?: any;
  selectedDispatcher?: any;
}

const EditID: FC<IEditDispatcher> = ({
  createFromModal,
  setClickOnSubmit,
  clickOnSubmit,
  setOpenModal,
  setNewDispatcher,
  selectedDispatcher,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const userData = localStorage.getItem('user');
  const ownerId = localStorage.getItem('owner_id');

  const [generalOpened, setGeneralOpened] = useState(true);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [billingInfoOpened, setBillingInfoOpened] = useState(true);
  const [billingInfo, setBillingInfo] = useState<any>(null);
  const [generalLoading, setGeneralLoading] = useState<boolean>(false);
  const [generalInfo, setGeneralInfo] = useState<any>(null);
  const [isHazmat, setIsHazmat] = useState(1);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [addressState, setAddressState] = useState(null);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [contactPersonsList, setContactPersonsList] = useState<any>(null);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mailingAddressState, setMailingAddressState] = useState(null);
  const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [state_, setState] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);

  const [addressMailingComponents, setAddressMailingComponents] = useState<any>(null);
  const [cityMailing, setCityMailing] = useState<any>(null);
  const [zipMailing, setZipMailing] = useState<any>(null);
  const [stateMailing, setStateMailing] = useState<any>(null);
  const [addressMailing, setAddressMailing] = useState<any>(null);

  const [newUserData, setNewUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  });

  const [editUserData, setEditUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    id: '',
  });

  const [assigmentNotice, setAssigmentNotice] = useState({
    alias: 'assigment_notice',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [w9, setW9] = useState({
    alias: 'w9',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [liabilityInsurance, setLiabilityInsurance] = useState({
    alias: 'liability_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [operatingPermit, setOperatingPermit] = useState({
    alias: 'operating_permit',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  useEffect(() => {
    if (addressComponents) {
      let streetNumber = '';
      let route = '';
      setAddress(addressComponents.address);

      addressComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZip(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setState(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCity(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressComponents]);

  useEffect(() => {
    if (addressMailingComponents) {
      let streetNumber = '';
      let route = '';
      setAddressMailing(addressMailingComponents.address);

      addressMailingComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZipMailing(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setStateMailing(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCityMailing(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressMailingComponents]);

  useEffect(() => {
    if (state || selectedDispatcher) {
      if (state?.id || selectedDispatcher?.id) {
        setGeneralLoading(true);

        dispatch(getIndependentDispatcherInfo(+state?.id || +selectedDispatcher?.id)).then(res => {
          setTimeout(() => {
            setGeneralLoading(false);
          }, 1500);

          if (res.type === 'independentDispatcherInfo/fulfilled') {
            setGeneralInfo(res.payload.resource);
          }
        });

        getContactPersonsList(state?.id || selectedDispatcher?.id);
      }
    }
  }, [state]);

  useEffect(() => {
    if (editUserPrevData) {
      setEditUserData(editUserPrevData);
    }
  }, [editUserPrevData]);

  useEffect(() => {
    if (generalInfo && generalInfo.id) {
      setIsHazmat(generalInfo.is_hazmat);

      setCity(generalInfo.address.city);
      setZip(generalInfo.address.zip);
      setState(generalInfo.address.state?.code)
      setCityMailing(generalInfo.mailing_address.city);
      setZipMailing(generalInfo.mailing_address.zip);
      setStateMailing(generalInfo.mailing_address.state?.code);
      setAddress(generalInfo.address.full_address);
      setAddressMailing(generalInfo.mailing_address.full_address);
      setBillingInfo(generalInfo.billing_info);

      if (generalInfo.logo) {
        setAvatar({
          ...avatar,
          file: generalInfo.logo.file,
        });
      }

      if (generalInfo.documents && generalInfo.documents.length) {
        setIsAnyFileUploded(true);
        generalInfo.documents.map((doc: any) => {
          if (doc.alias === 'assigment_notice') {
            setAssigmentNotice({
              ...assigmentNotice,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === 'w9') {
            setW9({
              ...w9,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === 'liability_insurance') {
            setLiabilityInsurance({
              ...liabilityInsurance,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === 'operating_permit') {
            setOperatingPermit({
              ...operatingPermit,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          }
        });
      }
    }
  }, [generalInfo]);

  const getContactPersonsList = (id: number) => {
    dispatch(
      getUsersList({
        entity_type: 'id',
        entity_id: id,
      }),
    ).then(res => {
      const users: any = [];
      if (res.type === 'usersList/fulfilled' && res.payload?.resource?.length) {
        res.payload.resource.map((user: any) => {
          users.push({
            first_name: user.profile.first_name,
            last_name: user.profile.last_name,
            phone: user.address.phone,
            email: user.email,
            user_id: user.id,
          });
        });
      }

      setContactPersonsList(users);
    });
  };

  const onAddNewUser = () => {
    const userData = {
      user: {
        email: newUserData.email,
        role: 'user',
      },
      profile: {
        first_name: newUserData.first_name,
        last_name: newUserData.last_name,
      },
      address: {
        phone: newUserData.phone,
      },
    };

    if (
      generalInfo.id &&
      newUserData.first_name?.length &&
      newUserData.last_name?.length &&
      newUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        createNewUser({
          entity_type: 'id',
          entity_id: generalInfo.id,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'createUser/fulfilled') {
          getContactPersonsList(generalInfo.id);
        }
        setOpenCreateUserModal(false);
      });
    }
  };

  const onEditUserInfo = () => {
    const userData = {
      user: {
        email: editUserData.email,
        role: 'user',
      },
      profile: {
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
      },
      address: {
        phone: editUserData.phone,
      },
      id: editUserData.id,
    };

    if (
      generalInfo.id &&
      editUserData.first_name?.length &&
      editUserData.last_name?.length &&
      editUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        editUser({
          entity_type: 'id',
          entity_id: generalInfo.id,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'editUser/fulfilled') {
          getContactPersonsList(generalInfo.id);
          setEditUserData({
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            id: '',
          });

          setEditUserPrevData(null);
        }
        setOpenEditUserModal(false);
      });
    }
  };

  const onDeleteUser = (userId: number) => {
    dispatch(
      deleteUser({
        entity_type: 'id',
        entity_id: generalInfo.id,
        user_id: userId,
      }),
    ).then(res => {
      if (res.type === 'deleteUser/fulfilled') {
        getContactPersonsList(generalInfo.id);
      }
    });
  };

  const onChangeContactPerson = (field: string, formType: string) => (ev: any) => {
    if (formType === 'create') {
      setNewUserData({
        ...newUserData,
        [field]: ev.target.value,
      });
    } else if (formType === 'edit') {
      setEditUserData({
        ...editUserData,
        [field]: ev.target.value,
      });
    }
  };

  useEffect(() => {
    if (clickOnSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [clickOnSubmit]);

  const onSubmit = async (data: any) => {
    const docs = [];

    if (assigmentNotice.file) {
      docs.push(assigmentNotice);
    }

    if (w9.file) {
      docs.push(w9);
    }

    if (liabilityInsurance.file) {
      docs.push(liabilityInsurance);
    }

    if (operatingPermit.file) {
      docs.push(operatingPermit);
    }

    if (generalInfo.documents.length) {
      generalInfo.documents.map((doc: any) => {
        if (doc.alias === 'assigment_notice' && doc.file && !assigmentNotice.fullpath) {
          docs.push({
            alias: 'assigment_notice',
            delete: 1,
          });
        } else if (doc.alias === 'w9' && doc.file && !w9.fullpath) {
          docs.push({
            alias: 'w9',
            delete: 1,
          });
        } else if (
          doc.alias === 'liability_insurance' &&
          doc.file &&
          !liabilityInsurance.fullpath
        ) {
          docs.push({
            alias: 'liability_insurance',
            delete: 1,
          });
        } else if (
          doc.alias === 'operating_permit' &&
          doc.file &&
          !operatingPermit.fullpath
        ) {
          docs.push({
            alias: 'operating_permit',
            delete: 1,
          });
        }
      });
    }

    if (generalInfo.id) {
      setSubmitLoading(true);
      const dataForUpdate: any = {
        id: generalInfo.id,
        title: data.carrier_name || generalInfo.title,
        info: data.carrier_info || generalInfo.info,
        mc_authority: data.mc_authority || generalInfo.mc_authority,
        tax_id: data.tax_id || generalInfo.tax_id,
        dot_id: data.dot || generalInfo.dot_id,
        scac: data.scac_code || generalInfo.scac,
        is_hazmat: isHazmat === 0 || isHazmat === 1 ? isHazmat : 1,
        billing_info: billingInfo || generalInfo.billing_info,
        address: {
          address: addressComponents?.shortAddress || data.address || generalInfo.address.address,
          full_address: addressComponents?.address || data.address || generalInfo.address.full_address,
          city: city || data.city || generalInfo.address.city,
          state: addressState,
          zip: zip || data.zip || generalInfo.address.zip,
          phone: getStringWithoutSymbols(data.office_phone) || generalInfo.address.phone,
          fax: getStringWithoutSymbols(data.office_fax) || generalInfo.address.fax,
          cell_phone:
            getStringWithoutSymbols(data.cell_phone) || generalInfo.address.cell_phone,
        },
        mailing_address: {
          address: addressMailingComponents?.shortAddress || data.mailing_address || generalInfo.mailing_address.address,
          full_address: addressMailingComponents?.address || data.mailing_address || generalInfo.mailing_address.full_address,
          city: cityMailing || data.mailing_city || generalInfo.mailing_address.city,
          state: mailingAddressState,
          zip: zipMailing || data.mailing_zip || generalInfo.mailing_address.zip,
        },
        user: {
          id: generalInfo.user.id,
          first_name: data.first_name || generalInfo.user.profile.first_name,
          last_name: data.last_name || generalInfo.user.profile.last_name,
        },
      };

      if (data.email) {
        dataForUpdate.user.email = data.email || generalInfo.user.email;
      }

      if (docs.length) {
        dataForUpdate.documents = docs;
      }

      if (avatar.temp) {
        dataForUpdate.logo = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(updateIndependentDispatcherInfo(dataForUpdate)).then(res => {
        if (res.type === 'updatingIndependentDispatcherInfo/fulfilled') {
          if (setClickOnSubmit && setOpenModal) {
            const fullName = getFullName(
              res.payload.resource.user.profile.first_name,
              res.payload.resource.user.profile.last_name,
            );
            setNewDispatcher({
              id: res.payload.resource.id,
              value: fullName.length ? fullName : res.payload.resource.title,
            });
          } else {
            navigate('/users-management', {
              state: {
                tab: 'independent_dispatchers',
              },
            });
          }
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  const deleteUserGlobal = () => {
    if (userData) {
      setSubmitLoading(true);
      dispatch(
        deleteIndependentDispatcher({
          id: state.id,
        }),
      ).then(res => {
        if (res.type === 'deleteIndependentDispatcher/fulfilled') {
          navigate('/users-management', {
            state: {
              tab: 'independent_dispatchers',
            },
          });
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  const onChangeBillingInfo = (ev: any) => {
    setBillingInfo(ev.target.value);
  }

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Edit Independent Dispatcher</h1>
                </div>

                {!createFromModal && <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() =>
                    navigate('/users-management', {
                      state: {
                        tab: 'independent_dispatchers',
                      },
                    })
                  }
                  className="mapBtn"
                  variant="outlined"
                />}
              </div>
            </div>

            <div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Independent Dispatcher Details</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDetailsOpened(!detailsOpened)}
                  className={`openBtn ${!detailsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <DetailsTC
                detailsOpened={detailsOpened}
                setAssigmentNotice={setAssigmentNotice}
                assigmentNotice={assigmentNotice}
                isAnyFileUploded={isAnyFileUploded}
                setIsAnyFileUploded={setIsAnyFileUploded}
                setW9={setW9}
                w9={w9}
                setLiabilityInsurance={setLiabilityInsurance}
                liabilityInsurance={liabilityInsurance}
                setOperatingPermit={setOperatingPermit}
                operatingPermit={operatingPermit}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralID
                generalOpened={generalOpened}
                control={control}
                generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setMailingAddressState={setMailingAddressState}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setAddressComponents={setAddressComponents}
                zip={zip}
                state={state_}
                city={city}
                setAddressMailingComponents={setAddressMailingComponents}
                zipMailing={zipMailing}
                stateMailing={stateMailing}
                cityMailing={cityMailing}
                address={address}
                mailingAddress={addressMailing}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Billing Instructions</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setBillingInfoOpened(!billingInfoOpened)}
                  className={`openBtn ${!billingInfoOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {billingInfoOpened && <div className="billingInfoField">
                <span>Billing Information</span>
                <textarea onChange={onChangeBillingInfo} defaultValue={billingInfo}></textarea>
              </div>}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__contactPersons">
              <div className="h2-title">
                <h2 className="h2-title__text">Contact Persons</h2>

                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setContactPersonsOpened(!contactPersonsOpened)}
                  className={`openBtn ${!contactPersonsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <ContactPersonsID
                contactPersonsOpened={contactPersonsOpened}
                setOpenCreateUserModal={setOpenCreateUserModal}
                setOpenEditUserModal={setOpenEditUserModal}
                contactPersonsList={contactPersonsList}
                onDeleteUser={onDeleteUser}
                setEditUserPrevData={setEditUserPrevData}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            {!createFromModal && <div>
              <Button
                title="SAVE ALL"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn"
                loading={submitLoading}
              />

              {state?.id && (
                <Button
                  title="delete"
                  onClick={() => setDeleteModal(true)}
                  variant="contained"
                  className="cancelBtn deleteUserBtn"
                  loading={submitLoading}
                />
              )}
            </div>}
          </div>
          <DeleteModalConfirmation
            open={openDeleteModal}
            setOpen={setDeleteModal}
            deleteFunction={deleteUserGlobal}
            loading={submitLoading}
          />
          {!createFromModal && <div className="createDriver__userInfo">
            <div className="addNewPhotoBtn">
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={e => {
                    if (e && e.target && e.target.files) {
                      const fd = new FormData();
                      fd.append('file', e.target.files[0]);
                      dispatch(fetchUploadFile(fd)).then(res => {
                        if (res.type === 'uploadFile/fulfilled') {
                          setAvatar({
                            file: res.payload.file.fullpath,
                            temp: res.payload.file.filepath,
                          });
                        }
                      });
                    }
                  }}
                />
                <CameraAltOutlinedIcon />
              </IconButton>
            </div>
            <div className="userInfo__avatar">
              <img src={avatar.file} alt="" />
            </div>
            <div className="userInfo__role">Independent Dispatcher</div>
          </div>}

          {
            <>
              {openCreateUserModal && (
                <UserModalFA
                  open={openCreateUserModal}
                  setOpen={setOpenCreateUserModal}
                  onChange={onChangeContactPerson}
                  onSubmit={onAddNewUser}
                  confirmTitle="ADD"
                  cancelTitle="CANCEL"
                  contentTitle="Add New Contact Person"
                  formType="create"
                  loading={submitLoading}
                />
              )}

              {openEditUserModal && (
                <UserModalFA
                  open={openEditUserModal}
                  setOpen={setOpenEditUserModal}
                  onChange={onChangeContactPerson}
                  onSubmit={onEditUserInfo}
                  confirmTitle="EDIT"
                  cancelTitle="CANCEL"
                  contentTitle="Edit Contact Person"
                  defaultData={editUserPrevData}
                  setDefaultData={setEditUserPrevData}
                  formType="edit"
                  loading={submitLoading}
                />
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default EditID;
