import { FC, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './Filters.scss';
import moment from 'moment';

interface IFilter {
  companyList?: { id: number; value: string }[];
  // changeDateTo?: (newValue: Dayjs | null) => void;
  // changeDateFrom?: (newValue: Dayjs | null) => void;
  changeDateTo?: any;
  changeDateFrom?: any;
  changeCurrentCompany?: (event: SelectChangeEvent) => void;
  dateTo?: any;
  dateFrom?: any;
  currentCompany?: string | null;
}

const Filters: FC<IFilter> = ({
  companyList,
  changeDateTo,
  changeDateFrom,
  changeCurrentCompany,
  dateTo,
  dateFrom,
  currentCompany
}) => {

  return (
    <div className="filtersContainer">
      {/* <div className="selectDriverContainer filtersContainer__filter">
        <h3 className="helperInputText">Truck Company</h3>
        <FormControl fullWidth size="small">
          <InputLabel id="select-label">Select company</InputLabel>
          <Select
            labelId="select-label"
            value={currentCompany ? currentCompany : ' '}
            label="Age"
            onChange={changeCurrentCompany}
          >
            <MenuItem value={' '}>All</MenuItem>
            {companyList ? (
              companyList.map((company): any => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.value}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={10}>Ten</MenuItem>
            )}
          </Select>
        </FormControl>
      </div> */}

      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: From</h3>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateFrom}
              onChange={changeDateFrom}
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>
      
      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: To</h3>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateTo}
              onChange={changeDateTo}
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <img
        src={require('../../../../../assets/images/icons/dashboard-icon.png')}
        alt="Logo"
        width="36px"
        height="36px"
        style={{cursor: 'pointer'}}
        onClick={() => {
          changeDateTo(moment().format('YYYY-MM-DD'), 'reset');
          changeDateFrom(moment().format('YYYY-MM-DD'), 'reset');
        }}
      />
    </div>
  );
};

export default Filters;
