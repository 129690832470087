import React, { useState } from 'react';
import Loader from './Loader';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import ControlPanel from './ControlPanel';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import './style.scss';

const PdfView = (props) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  return (
    <div style={{ height: '750px', position: "relative", overflowY: "scroll", }}>
    {/* <div
      style={{
        backgroundColor: 'white',
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: 10,
        fontWeight: 'bold',
        position: "absolute",
        right: 0,
        // top: -40,
        top: 25,
      }}
      onClick={props.close}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: '100%',
          padding: 10,
          boxSizing: 'border-box',
        }}
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <line x1="1" y1="14" x2="14" y2="1" stroke="black" strokeWidth="2" />
        <line x1="1" y1="1" x2="14" y2="14" stroke="black" strokeWidth="2" />
      </svg>
    </div> */}
      <Loader isLoading={isLoading} />
      <section
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file="/assets/docs/file-sample.pdf"
          onClose={props.close}
        />
        <Document
          file={props.url}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdfViewer__documentWrapper"
        >
          <Page pageNumber={pageNumber} scale={scale} wrap={false}/>
        </Document>
      </section>
    </div>
  );
};

export default PdfView;

PdfView.propTypes = {
  url: PropTypes.string.isRequired,
  close: PropTypes.any.isRequired,
};