import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import { createTruck, deleteEquipmentType, deleteTrailer, deleteTruck } from '../../../../../store/load/load';
import { deleteDriver } from '../../../../../store/driver/driver';
import { deleteCustomer } from '../../../../../store/customer/customer';
import { deleteIndependentDispatcher } from '../../../../../store/independentDispatcher/independentDispatcher';

interface IDeleteModal {
  open: any;
  setOpen: any;
  option: any;
  setSelectedOption?: any;
  selectedOption?: any
  setOptionsList?: any;
  optionsList?: any;
  setSelectedOption_?: any;
  setDeletedOptions?: any;
  deletedOptions?: any;
  selectedTC?: any;
}

const DeleteOptionModal: FC<IDeleteModal> = ({
  open,
  setOpen,
  option,
  setSelectedOption,
  selectedOption,
  setOptionsList,
  optionsList,
  setSelectedOption_,
  setDeletedOptions,
  deletedOptions,
  selectedTC,
}) => {
  const dispatch = useAppDispatch();
  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  useEffect(() => {
    
  }, [option])


  const onSubmit_ = () => {
    if (id) {
      const type =
        parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
          ? 'tc'
          : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
          ? 'fa'
          : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
          ? 'id'
          : null;

          if (option.type === 'equipmentType' && selectedTC?.id) {
            const data = {
              type: 'tc',
              id: selectedTC?.id,
              equipmentTypeId: option.id,
            };
      
            dispatch(deleteEquipmentType(data)).then((res: any) => {
              if (res.type === 'deleteEquipmentTypes/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  setSelectedOption(null);
                }

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          } else if (option.type === 'truck' && selectedTC?.id) {
            const data = {
              type: 'tc',
              id: selectedTC?.id,
              truckId: option.id,
            };
      
            dispatch(deleteTruck(data)).then((res: any) => {
              if (res.type === 'deleteTruck/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  setSelectedOption(null);
                }

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          } else if (option.type === 'trailer' && selectedTC?.id) {
            const data = {
              type: 'tc',
              id: selectedTC?.id,
              trailerId: option.id,
            };
      
            dispatch(deleteTrailer(data)).then((res: any) => {
              if (res.type === 'deleteTrailer/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  setSelectedOption(null);
                }

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          } else if (option.type === 'driver' && selectedTC?.id) {
            const data = {
              type: 'tc',
              id: selectedTC?.id,
              driverId: option.id,
            };
      
            dispatch(deleteDriver(data)).then((res: any) => {
              if (res.type === 'deleteDriver/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  setSelectedOption(null);
                }

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          } else if (option.type === 'dispatcher') {
            const data = {
              id: option.id,
            };
      
            dispatch(deleteIndependentDispatcher(data)).then((res: any) => {
              if (res.type === 'deleteIndependentDispatcher/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  setSelectedOption(null);
                }

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          } else if (option.type === 'billTo' || option.type === 'shipper' || option.type === 'receiver') {
            const data = {
              type,
              id,
              customerId: option.id,
            };
            dispatch(deleteCustomer(data)).then((res: any) => {
              if (res.type === 'deleteCustomer/fulfilled') {
                if (selectedOption?.id === option?.id) {
                  if (setSelectedOption_) {
                    setSelectedOption_(null);
                  }
                  setSelectedOption(null);
                }
                setDeletedOptions([...deletedOptions, option])

                const filteredOptionsList = optionsList.filter((option_: any) => option_.id !== option.id);
                setOptionsList(filteredOptionsList);
                setOpen(false);
              }
            });
          }
    }
  };

  return (
    <Modal
      open={open}
      // disabled={btnDisabled}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={onSubmit_}
      // loading={loading}
      confirmTitle={'DELETE'}
      cancelTitle={'CANCEL'}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="delete-item-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="label">Are you sure you want to delete it?</div>
        </div>
      }
    />
  );
};

export default DeleteOptionModal;
