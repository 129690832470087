import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoadListPage } from "../../components/pages/Dashboard/interfaces/interface";
import api from '../../service/api';

interface IGetLoad {
  driver?: string | null,
  status? : string | null,
  formattedDate? : string | null,
  typeLoads? : string | null,
  sort? : string | null,
  sortDirection? : string,
  page?: number 
}

export const getLoadList = createAsyncThunk('getLoad', async(data : IGetLoad) => {
  
  const driver = data.driver && data.driver !== null ? `&driver_id=${data.driver}` : "";
  const status = data.status && data.status !== null && data.status !== null ? `&status=${data.status}` : "";
  const date = data.formattedDate && data.formattedDate !== null && data.formattedDate !== 'Invalid date' ? `&date=${data.formattedDate}` : "";
  const type = data.typeLoads && data.typeLoads !== null ? `&type=${data.typeLoads}` : "";

  const response = await api.get(`loads/list?page=${data.page}&order_by=${data.sort}&order_dir=${data.sortDirection}${driver}${status}${date}${type}`);
  return response;
});

export const getLoadMapDrivers = createAsyncThunk('getLoadMapDrivers', async(data : IGetLoad) => {
  
  // const driver = data.driver && data.driver !== null ? `&driver_id=${data.driver}` : "";
  // const status = data.status && data.status !== null && data.status !== null ? `&status=${data.status}` : "";
  // const date = data.formattedDate && data.formattedDate !== null ? `&date=${data.formattedDate}` : "";
  // const type = data.typeLoads && data.typeLoads !== null ? `&type=${data.typeLoads}` : "";

  const response = await api.get(`loads/map-drivers`);
  return response;
});

interface IInitialState{
  loading: boolean,
  loadsList: ILoadListPage | null,
}

const initialState: IInitialState = {
  loadsList: null,
  loading: false,
};

export const loads = createSlice({
  name: 'loads',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getLoadList.pending, state => {
        state.loading = true;
      })
      .addCase(getLoadList.fulfilled, (state, action) => {
        state.loadsList = action.payload;
        state.loading = false;
      })
      .addDefaultCase(() => {});
  },
});

export default loads.reducer;
