import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import './ContainedInput.scss';
import InputMask from 'react-input-mask';
import { InputAdornment, OutlinedInput, TextField } from '@mui/material';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { numberWithSpaces } from '../../../helpers';

interface IUploadButton {
  label: string;
  className?: string;
  defaultValue?: string | null | any;
  control?: any;
  id?: string;
  readOnly?: boolean;
  type?: string;
}

const CustomizedInput: FC<IUploadButton> = ({
  label,
  className,
  control,
  defaultValue,
  id,
  readOnly,
  type,
}) => {

  const [value_, setValue] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue])
  return (
    <Controller
      name={id ? id : ''}
      control={control}
      defaultValue=""
      // rules={generateRules()}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={`customizedInputContainer ${className ? className : ''}`}>
          <div className="label">{label}</div>

          {type !== 'phone' && type !== 'price' ? (
            id === 'guest_driver' && value_ ? 
            <input
              className="customizedInputContainer__input"
              value={value_}
              onChange={onChange}
              readOnly={readOnly ?? false}
            /> : <input
            className="customizedInputContainer__input"
            onChange={onChange}
            defaultValue={defaultValue}
            readOnly={readOnly ?? false}
          />
          ) : type === 'phone' ? (
            <InputMask
              mask="+1\ (999) 999-9999"
              defaultValue={defaultValue}
              onChange={onChange}
              className="customizedInputContainer__input"
              readOnly={readOnly ?? false}
            />
          ) : type === 'price' ? (
            readOnly ? (
              <input
                className="customizedInputContainer__input"
                // value={defaultValue}
                onChange={onChange}
                defaultValue={`$${defaultValue}`}
                readOnly={readOnly ?? false}
              />
            ) : (
              <TextField
                disabled={readOnly ?? false}
                defaultValue={defaultValue}
                onChange={onChange}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
                variant="outlined"
                placeholder="$0.00"
              />
            )
          ) : null}
        </div>
      )}
    />
  );
};

export default CustomizedInput;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              // value: values.value,
              value: values.value,
            },
          });
        }}
        thousandSeparator=" "
        isNumericString
        prefix="$"
      />
    );
  },
);
