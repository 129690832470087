import { FC, useEffect, useState, memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import { getDriverHistory } from '../../../../../store/load/load';
import MapWithMarkers from '../../../../controls/MapWithMarkers';
import { IMrkers, IDelivariPoint, ILoadsList } from '../../interfaces/interface';
import { checkZeroCoordinate } from '../../../../../helpers';

interface IMap {
  selectedLoadId?: any;
  driversPins?: any;
  selectedLoadsIds?: any;
}

const MapWidthMarkers: FC<IMap> = ({ selectedLoadId, driversPins, selectedLoadsIds }) => {
  const dispatch = useAppDispatch();

  const { loadsList, loading } = useAppSelector(state => state.loads);
  const [markersReceivers, setMarkersReceivers] = useState<IMrkers[]>([]);
  const [markersShippers, setMarkersShippers] = useState<IMrkers[]>([]);
  const [driversPins_, setDriversPins] = useState<any>([]);
  const [historyTrips, setHistoryTrips] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);
  const [driverDataListWithCustomer, setDriverDataListWithCustomer] = useState<any>([]);

  useEffect(() => {
    if (driversPins) {
      setDriversPins(driversPins);
    }
  }, [driversPins]);

  useEffect(() => {
    if (selectedLoadId && selectedLoadId !== 'fullMap') {
      dispatch(getDriverHistory({ loadId: selectedLoadId })).then(res => {
        if (res.type === 'getTrackingHistory/fulfilled') {
          if (res.payload.resource?.length > 0) {
            const driverArray: any = [];
            const list: any = historyTrips;
            const driversDataList: any = driverDataListWithCustomer;

            res.payload.resource.map((point: any) => {
              if (!checkZeroCoordinate(point.lat) && !checkZeroCoordinate(point.lon)) {
                driverArray.push({
                  lat: point.lat,
                  lng: point.lon,
                  date: point.updated_at,
                  id: point.id,
                  load_id: selectedLoadId,
                  customerId: point.tag ? point.tag.replace(/\D/g, '') : null,
                  timestamp: point.timestamp ?? null,
                });

                if (point.tag) {
                  driversDataList.push({
                    loadId: selectedLoadId,
                    customerId: point.tag.replace(/\D/g, ''),
                    timestamp: point.timestamp ?? null,
                  });
                }
              }
            });

            const driverObj = {
              load_id: selectedLoadId,
              points: driverArray,
            };

            if (driversDataList.length) {
              setDriverDataListWithCustomer([...driversDataList]);
            }

            list.push(driverObj);
            setHistoryTrips(list);
          }
        }
      });
    }
  }, [selectedLoadId]);

  useEffect(() => {
    const pins: any = [];
    const trips: any = [];

    driversPins?.map((pin: any) => {
      if (selectedLoadsIds?.find((id: any) => id === pin.load_id)) {
        pins.push(pin);
      }
    });

    historyTrips?.map((tripList: any) => {
      if (selectedLoadsIds?.find((id: any) => id === tripList.load_id)) {
        trips.push(tripList);
      }
    });

    setDriversPins(pins);
    setHistoryTrips(trips);
  }, [selectedLoadsIds]);

  useEffect(() => {
    const customersList = [...markersReceivers, ...markersShippers];

    const customersListWithTimestamp = customersList.map((customer: any) => {
      const filteredDataList = driverDataListWithCustomer.filter((item: any) => {
        if (
          +item.loadId === +customer.load_id &&
          +item.customerId === customer.customerId
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (filteredDataList.length) {
        return {
          ...customer,
          timestamp: filteredDataList[0].timestamp,
        };
      } else {
        return customer;
      }
    });

    // setMarkers([...markersReceivers, ...markersShippers])
    setMarkers(customersListWithTimestamp);
  }, [markersReceivers, markersShippers, driverDataListWithCustomer]);

  useEffect(() => {
    setMarkersReceivers([]);
    setMarkersShippers([]);
    if (loadsList && loadsList.data && loadsList.data.resource) {
      //================ for receivers =========================
      loadsList?.data?.resource?.data?.forEach((load: ILoadsList) => {
        (selectedLoadsIds?.find((id: any) => id === load.id) ||
          selectedLoadId === 'fullMap') &&
          load.receivers.forEach((res: IDelivariPoint) => {
            const newMarker = {
              truck: load?.truck?.number ?? ' - ',
              treiler: load?.trailer?.number ?? ' - ',
              name: load.driver
                ? `${load.driver.profile.first_name} ${load.driver.profile.last_name}`
                : ' - ',
              city: res?.customer?.address?.city ?? ' - ',
              address:
                res?.customer?.address?.full_address ??
                res?.customer?.address?.address ??
                ' - ',
              load: load.load_number ? load.load_number : ' - ',
              load_id: load.id,
              lat: res?.customer?.address?.lat ?? ' - ',
              lng: res?.customer?.address?.lon ?? ' - ',
              date: res.date ?? '',
              id: res?.id ? `${res.id + '-receiver'}` : ' - ',
              phone: res?.customer?.address?.phone ?? ' - ',
              status: load.status ?? ' - ',
              customerId: res.id,
            };
            if (!!res?.customer?.address?.lat && !!res?.customer?.address?.lon) {
              setMarkersReceivers(markersReceivers =>
                !markersReceivers.find((marker: any) => marker.id === newMarker.id)
                  ? (markersReceivers = [...markersReceivers, newMarker])
                  : markersReceivers,
              );
            }
          });
      });

      //================ for shippers =========================
      loadsList?.data?.resource?.data?.forEach((load: ILoadsList) => {
        (selectedLoadsIds?.find((id: any) => id === load.id) ||
          selectedLoadId === 'fullMap') &&
          load.shippers.forEach((ship: IDelivariPoint) => {
            const newMarker = {
              truck: load?.truck?.number ?? ' - ',
              treiler: load?.trailer?.number ?? ' - ',
              name: load.driver
                ? `${load.driver.profile.first_name} ${load.driver.profile.last_name}`
                : ' - ',
              city: ship?.customer?.address?.city ?? ' - ',
              address:
                ship?.customer?.address?.full_address ??
                ship?.customer?.address?.address ??
                ' - ',
              load: load.load_number ? load.load_number : ' - ',
              load_id: load.id,
              lat: ship?.customer?.address?.lat ?? ' - ',
              lng: ship?.customer?.address?.lon ?? ' - ',
              date: ship.date ? ship.date : '',
              id: ship.id ? ship.id + '-shipper' : ' - ',
              phone: ship?.customer?.address?.phone ?? ' - ',
              status: load.status ?? ' - ',
              customerId: ship.id,
            };
            if (!!ship?.customer?.address?.lat && !!ship?.customer?.address?.lon) {
              setMarkersShippers(markersShippers =>
                !markersShippers.find((marker: any) => marker.id === newMarker.id)
                  ? (markersShippers = [...markersShippers, newMarker])
                  : markersShippers,
              );
            }
          });
      });
    } else if (loadsList?.data?.resource?.data?.length) {
      setMarkersReceivers([]);
      setMarkersShippers([]);
    }
  }, [loadsList, selectedLoadsIds]);

  useEffect(() => {
    // console.log(markers, '*MARKERS*')
  }, [markers]);

  return (
    <>
      {!loading && (
        <MapWithMarkers
          zoom={7}
          markers={
            loadsList?.data?.resource?.data?.length
              ? // ? [...markersReceivers, ...markersShippers, ...driversPins_]
                markers
              : []
          }
          driversMarkers={driversPins_}
          selectedLoadId={selectedLoadId}
          selectedLoadsIds={selectedLoadsIds}
          historyTrips={historyTrips}
          location
        />
      )}
    </>
  );
};

export default memo(MapWidthMarkers);
