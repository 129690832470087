import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './ModalForm.scss';
import { ContainedInput } from '../../../../controls';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import { sendDocumentToEmails } from '../../../../../store/invoicing/invoicing';
import { getFullpathString } from '../../../../../helpers';
import Loader from '../../../../common/Loader/Loader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  boxShadow: 24,
  p: 4,
};

export default function ModalForm({
  changeOpenModalForm,
  openModalForm,
  load,
  document_,
}: any) {
  const dispatch = useAppDispatch();
  const { sendEmailsLoading } = useAppSelector(state => state.invoicing);
  const token = localStorage.getItem('token');

  const [emailsCount, setEmailsCount] = React.useState<number>(1);
  const [emailsArray, setEmailsArray] = React.useState<any>([]);
  const [emailsList, setEmailsList] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);

  React.useEffect(() => {
    setEmailsArray(Array.from(Array(emailsCount)));
  }, [emailsCount]);

  const onHandleChange = (ev: any, i: number) => {
    setEmailsList((prev: any) => {
      let currentEmail = null;
      if (!prev?.length) {
        return [
          {
            index: i,
            email: ev.target.value,
          },
        ];
      } else {
        currentEmail = prev.find((email: any) => email.index === i);
      }

      if (currentEmail) {
        return prev.map((email: any) => {
          if (email.index === i) {
            return {
              ...email,
              email: ev.target.value,
            };
          } else return email;
        });
      } else {
        return [
          ...prev,
          {
            index: i,
            email: ev.target.value,
          },
        ];
      }
    });
  };

  const sendDocToEmails = () => {
    if (emailsList.length) {
      if (load.invoice) {
        dispatch(
          sendDocumentToEmails({
            invoice_id: load.invoice.id,
            emails: emailsList.map((email: any) => email.email),
          }),
        ).then(res => {
          if (res.type === 'sendDocumentToEmails/fulfilled') {
            setEmailsCount(1);
            setEmailsList([]);
            setEmailsArray([]);
            changeOpenModalForm();
            window.location.reload();
          }
        });
      }
    }
  };

  const onHandleClose = () => {
    setEmailsCount(1);
    setEmailsList([]);
    setEmailsArray([]);
    changeOpenModalForm();
    window.location.reload();
  };

  const onDownload = () => {
    setLoading(true);
    if (token) {
      getFullpathString(document_.file, token).then(res => {
        const link: any = document.getElementById('download-invoice-btn');
        link.download = document_.title;
        link.href = res;
        link.click();
        setLoading(false);
      });
    }
  };

  return (
    <>
    <Loader loading={loading}/>
    <div>
      <Modal
        keepMounted
        open={openModalForm}
        onClose={onHandleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="modal-form_pdf"
      >
        <Box sx={style} className="modal-form">
          <Typography
            id="keep-mounted-modal-title"
            className="modal-form_title"
            variant="h6"
            component="h2"
          >
            submission
            <div onClick={onHandleClose} className="modal-form_close"></div>
          </Typography>
          <div className="modal-form_head">
            <Button
              className="btn-sumb"
              size="large"
              variant="contained"
              onClick={onDownload}
              disabled={loading}
            >
              download via PDF “Freight Life Invoice Packet”
            </Button>
            <a id="download-invoice-btn" />
            <div className="modal-form_subtitle">or send to email</div>
          </div>
          <div className="modal-form_input">
            {emailsArray.map((_: any, i: number) => {
              return (
                <div className="modal-form_input" key={`email-${i}`}>
                  <span className="fieldsInModal__label">
                    Email {emailsArray.length === 1 ? '' : i + 1}
                  </span>
                  <input
                    className={`marginRight`}
                    onChange={ev => onHandleChange(ev, i)}
                  />
                </div>
              );
            })}
          </div>
          <div className="addEmailBtn" onClick={() => setEmailsCount(emailsCount + 1)}>
            +Add Email
          </div>
          <Stack className="modal-form_footer-btns" spacing={2} direction="row">
            <Button className="btn-edit" size="large" variant="outlined" onClick={onHandleClose} disabled={loading}>
              CANCEL
            </Button>
            <Button
              className="btn-sumb"
              size="large"
              variant="contained"
              onClick={sendDocToEmails}
              disabled={sendEmailsLoading}
            >
              SEND
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
    </>
  );
}
