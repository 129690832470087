import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IconButton } from '@mui/material';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { states } from '../../../../constants/main';
import { getStringWithoutSymbols } from '../../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../hooks/global';
import { updateFreightAgentInfo } from '../../../../store/freightAgent/freightAgent';
import { fetchUploadFile } from '../../../../store/uploadFile/uploadFile';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUsersList,
  globalDeleteCompany,
} from '../../../../store/user/user';
import Loader from '../../../common/Loader/Loader';
import { Button, Tabs } from '../../../controls';
import UploadFileButton from '../../../controls/UploadFileButton/UploadFileButton';
import './MyProfileFA.scss';
import ContactPersonsFA from './parts_/ContactPersons';
import UserModalFA from './parts_/CreateOrEditUserModal';
import DetailsFA from './parts_/Details';
import GeneralFA from './parts_/General';
import PaymentsHistory from '../payment/PaymentsHistory';
import { getCardInfo } from '../../../../store/payment/payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DeleteModalConfirmation from '../../../controls/DeleteModalConfirmation/DeleteModalConfirmation';
import { useNavigate } from 'react-router';

const PaymentForm = React.lazy(() => import('../payment/PaymentForm'));

interface IProfile {
  selectedProfileTab: any;
  handleChangeProfileTab: any;
  profileTabsList: any;
  detailsOpened: boolean;
  generalOpened: boolean;
  setCompanyLogoOpened: any;
  companyLogoOpened: boolean;
  billingInfoOpened: boolean;
  setBillingInfoOpened: any;
  setDetailsOpened: any;
  setGeneralOpened: any;
  companyName: string | null;
  email: string | null;
  generalInfo: any;
}

const MyProfileFA: FC<IProfile> = ({
  selectedProfileTab,
  handleChangeProfileTab,
  profileTabsList,
  detailsOpened,
  generalOpened,
  setCompanyLogoOpened,
  companyLogoOpened,
  billingInfoOpened,
  setBillingInfoOpened,
  setDetailsOpened,
  setGeneralOpened,
  companyName,
  email,
  generalInfo,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const ownerId = localStorage.getItem('owner_id');
  const publishKey = useAppSelector(state => state.payment.stripePublishKey);
  const canDelete = useAppSelector(state => state.user.canDelete);
  const navigate = useNavigate();

  const [contactPersonsList, setContactPersonsList] = useState<any>(null);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] = useState(false);
  const [checkedDelete, setCheckedDelete] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [state, setState] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);

  const [addressMailingComponents, setAddressMailingComponents] = useState<any>(null);
  const [cityMailing, setCityMailing] = useState<any>(null);
  const [zipMailing, setZipMailing] = useState<any>(null);
  const [stateMailing, setStateMailing] = useState<any>(null);
  const [addressMailing, setAddressMailing] = useState<any>(null);

  const [billingInfo, setBillingInfo] = useState<any>(null);

  const [newUserData, setNewUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  });

  const [editUserData, setEditUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    id: '',
  });

  const [w9, setW9] = useState({
    alias: 'w9',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [liabilityInsurance, setLiabilityInsurance] = useState({
    alias: 'liability_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [operatingPermit, setOperatingPermit] = useState({
    alias: 'operating_permit',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [companyLogo, setCompanyLogo] = useState({
    alias: 'company_logo',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  useEffect(() => {
    if (publishKey) {
      const stripePromise: any = loadStripe(publishKey);
      setStripePromise(stripePromise);
    }
  }, [publishKey]);

  useEffect(() => {
    if (addressComponents) {
      let streetNumber = '';
      let route = '';
      setAddress(addressComponents.address);

      addressComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZip(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setState(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCity(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressComponents]);

  useEffect(() => {
    if (addressMailingComponents) {
      let streetNumber = '';
      let route = '';
      setAddressMailing(addressMailingComponents.address);

      addressMailingComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZipMailing(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setStateMailing(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCityMailing(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressMailingComponents]);

  const getContactPersonsList = () => {
    dispatch(
      getUsersList({
        entity_type: 'fa',
        entity_id: ownerId,
      }),
    ).then(res => {
      const users: any = [];
      if (res.type === 'usersList/fulfilled' && res.payload?.resource?.length) {
        res.payload.resource.map((user: any) => {
          users.push({
            first_name: user.profile.first_name,
            last_name: user.profile.last_name,
            phone: user.address.phone,
            email: user.email,
            user_id: user.id,
          });
        });
      }

      setContactPersonsList(users);
    });
  };

  useEffect(() => {
    if (
      !companyLogo.preview
    ) {
      setIsAnyFileUploded(false);
    }
  }, [
    companyLogo.preview,
  ]);


  useEffect(() => {
    if (generalInfo.logo) {
      setAvatar({
        ...avatar,
        file: generalInfo.logo,
      });
    }

    setCity(generalInfo.city);
    setZip(generalInfo.zip);
    setState(generalInfo.state)
    setCityMailing(generalInfo.billingCity);
    setZipMailing(generalInfo.billingZip);
    setStateMailing(generalInfo.billingState);
    setAddress(generalInfo.fullAddress);
    setAddressMailing(generalInfo.fullBillingAddress);
    setBillingInfo(generalInfo.billingInfo);

    if (generalInfo.documents && generalInfo.documents.length) {
      setIsAnyFileUploded(true);
      generalInfo.documents.map((doc: any) => {
        if (doc.alias === 'w9') {
          setW9({
            ...w9,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        } else if (doc.alias === 'liability_insurance') {
          setLiabilityInsurance({
            ...liabilityInsurance,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        } else if (doc.alias === 'operating_permit') {
          setOperatingPermit({
            ...operatingPermit,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        } else if (doc.alias === 'company_logo') {
          setCompanyLogo({
            ...companyLogo,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        }
      });
    }

    if (ownerId) {
      getContactPersonsList();
    }
  }, [generalInfo]);

  const onAddNewUser = () => {
    const userData = {
      user: {
        email: newUserData.email,
      },
      profile: {
        first_name: newUserData.first_name,
        last_name: newUserData.last_name,
      },
      address: {
        phone: newUserData.phone,
      },
    };

    if (
      ownerId &&
      newUserData.first_name?.length &&
      newUserData.last_name?.length &&
      newUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        createNewUser({
          entity_type: 'fa',
          entity_id: ownerId,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'createUser/fulfilled') {
          getContactPersonsList();
        }
        setOpenCreateUserModal(false);
      });
    }
  };

  const onEditUserInfo = () => {
    const userData = {
      user: {
        email: editUserData.email,
      },
      profile: {
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
      },
      address: {
        phone: editUserData.phone,
      },
      id: editUserData.id,
    };

    if (
      ownerId &&
      editUserData.first_name?.length &&
      editUserData.last_name?.length &&
      editUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        editUser({
          entity_type: 'fa',
          entity_id: ownerId,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'editUser/fulfilled') {
          getContactPersonsList();
          setEditUserData({
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            id: '',
          });

          setEditUserPrevData(null);
        }
        setOpenEditUserModal(false);
      });
    }
  };

  useEffect(() => {
    if (editUserPrevData) {
      setEditUserData(editUserPrevData);
    }
  }, [editUserPrevData]);

  const onDeleteUser = (userId: number) => {
    dispatch(
      deleteUser({
        entity_type: 'fa',
        entity_id: ownerId,
        user_id: userId,
      }),
    ).then(res => {
      if (res.type === 'deleteUser/fulfilled') {
        getContactPersonsList();
      }
    });
  };

  const onChangeContactPerson = (field: string, formType: string) => (ev: any) => {
    if (formType === 'create') {
      setNewUserData({
        ...newUserData,
        [field]: ev.target.value,
      });
    } else if (formType === 'edit') {
      setEditUserData({
        ...editUserData,
        [field]: ev.target.value,
      });
    }
  };

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');

    const docs = [];

    if (w9.file) {
      docs.push(w9);
    }

    if (liabilityInsurance.file) {
      docs.push(liabilityInsurance);
    }

    if (operatingPermit.file) {
      docs.push(operatingPermit);
    }

    if (companyLogo.file) {
      docs.push(companyLogo)
    }

    if (generalInfo.documents.length) {
      generalInfo.documents.map((doc: any) => {
        if (doc.alias === 'w9' && doc.file && !w9.fullpath) {
          docs.push({
            alias: 'w9',
            delete: 1,
          });
        } else if (
          doc.alias === 'liability_insurance' &&
          doc.file &&
          !liabilityInsurance.fullpath
        ) {
          docs.push({
            alias: 'liability_insurance',
            delete: 1,
          });
        } else if (
          doc.alias === 'operating_permit' &&
          doc.file &&
          !operatingPermit.fullpath
        ) {
          docs.push({
            alias: 'operating_permit',
            delete: 1,
          });
        } else if (
          doc.alias === 'company_logo' &&
          doc.file &&
          !companyLogo.fullpath
        ) {
          docs.push({
            alias: 'company_logo',
            delete: 1,
          });
        }
      });
    }

    if (id) {
      setSubmitLoading(true);

      const dataForUpdate: any = {
        id: id,
        title: data.fa_name || generalInfo.companyName,
        info: data.fa_info || generalInfo.carrierInfo,
        mc_authority: data.mc_authority || generalInfo.mcAuthority,
        tax_id: data.tax_id || generalInfo.taxId,
        dot_id: data.dot || generalInfo.dot,
        scac: data.scac_code || generalInfo.scacCode,
        billing_info: billingInfo || generalInfo.billingInfo,
        address: {
          address: addressComponents?.shortAddress || data.address || generalInfo.address,
          full_address: addressComponents?.address || data.address || generalInfo.fullAddress,
          city: city || data.city || generalInfo.city,
          state: addressState,
          zip: zip || data.zip || generalInfo.zip,
          fax: getStringWithoutSymbols(data.office_fax) || generalInfo.officeFax,
          cell_phone: getStringWithoutSymbols(data.cell_phone) || generalInfo.cellPhone,
        },
        billing_address: {
          address: addressMailingComponents.shortAddress || data.billing_address || generalInfo.billingAddress,
          full_address: addressMailingComponents.address || data.billing_address || generalInfo.fullBillingAddress,
          city: cityMailing || data.billing_city || generalInfo.billingCity,
          state: billingAddressState,
          zip: zipMailing || data.billing_zip || generalInfo.billingZip,
          email: data.payable_email || generalInfo.payableEmail,
          phone: getStringWithoutSymbols(data.payable_phone) || generalInfo.payablePhone,
        },
        user: {
          id: generalInfo.userId,
          email: data.email || generalInfo.email,
        },
      };

      if (docs.length) {
        dataForUpdate.documents = docs;
      }

      if (avatar.temp) {
        dataForUpdate.logo = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(updateFreightAgentInfo(dataForUpdate)).then(res => {
        if (res.type === 'updatingFreightAgentInfo/fulfilled') {
          const userData = localStorage.getItem('user');
          if (userData) {
            const parsedData = JSON.parse(userData);
            localStorage.setItem(
              'user',
              JSON.stringify({ ...parsedData, company_name: res.payload.resource.title }),
            );
          }
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  const onChangeBillingInfo = (ev: any) => {
    setBillingInfo(ev.target.value);
  }

  useEffect(() => {
    dispatch(getCardInfo());
  }, []);

  const deleteCompany = () => {
    const id = localStorage.getItem('owner_id');

    if (id) {
      dispatch(globalDeleteCompany({
        type: 'fa',
        id: id,
      })).then(res => {
        if (res.type === 'globalDeleteCompany/fulfilled') {
          localStorage.clear();
          navigate('/sign-in');
        }
      })
    }
  }

  return (
    <div className="myProfile mainContainer">
      <Loader loading={submitLoading} />

      <div className="myProfile__container">
        <div className="myProfile__body">
          <div className="myProfile__userForm">
            <div className="myProfile__header">
              <div className="myProfile__title">
                <h1>Profile</h1>
                <span>(Freight Agent)</span>
              </div>

              <Tabs
                value={selectedProfileTab}
                onChange={handleChangeProfileTab}
                tabsList={profileTabsList}
              />
            </div>

            {selectedProfileTab === 'payment_details' ? 
              stripePromise && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Elements stripe={stripePromise}>
                    <PaymentForm />
                  </Elements>
                </Suspense>
              ) : selectedProfileTab === 'payments_usage_history' 
              ? (
                <PaymentsHistory />
              )
              : <><div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Freight Agent Details</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDetailsOpened(!detailsOpened)}
                  className={`openBtn ${!detailsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <DetailsFA
                detailsOpened={detailsOpened}
                isAnyFileUploded={isAnyFileUploded}
                setIsAnyFileUploded={setIsAnyFileUploded}
                setW9={setW9}
                w9={w9}
                setLiabilityInsurance={setLiabilityInsurance}
                liabilityInsurance={liabilityInsurance}
                setOperatingPermit={setOperatingPermit}
                operatingPermit={operatingPermit}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralFA
                generalOpened={generalOpened}
                control={control}
                generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setBillingAddressState={setBillingAddressState}
                setAddressComponents={setAddressComponents}
                zip={zip}
                state={state}
                city={city}
                setAddressMailingComponents={setAddressMailingComponents}
                zipMailing={zipMailing}
                stateMailing={stateMailing}
                cityMailing={cityMailing}
                address={address}
                mailingAddress={addressMailing}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Company Logo</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setCompanyLogoOpened(!companyLogoOpened)}
                  className={`openBtn ${!companyLogoOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {companyLogoOpened && (
                <div className="details__line-1">
                  <UploadFileButton
                    label="Company Logo"
                    className="marginRight"
                    changeValue={setCompanyLogo}
                    value={companyLogo}
                    type="image"
                  />
                  <UploadFileButton className="hiddenBlock" label="hidden"/>
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Billing Instructions</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setBillingInfoOpened(!billingInfoOpened)}
                  className={`openBtn ${!billingInfoOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {billingInfoOpened && <div className="billingInfoField">
                <span>Billing Information</span>
                <textarea onChange={onChangeBillingInfo} defaultValue={billingInfo}></textarea>
              </div>}

              <hr className="horizontalLine fullHr" />
            </div>
            </>}
          </div>

          {(selectedProfileTab !== 'payment_details' && selectedProfileTab !== 'payments_usage_history') && (<div className="myProfile__contactPersons">
            <div className="h2-title">
              <h2 className="h2-title__text">Contact Persons</h2>

              <img
                src={require('../../../../assets/images/icons/expand-icon.png')}
                alt="expand"
                width="24px"
                height="auto"
                onClick={() => setContactPersonsOpened(!contactPersonsOpened)}
                className={`openBtn ${!contactPersonsOpened ? 'openBtnOpened' : ''}`}
              />
            </div>

            <ContactPersonsFA
              contactPersonsOpened={contactPersonsOpened}
              setOpenCreateUserModal={setOpenCreateUserModal}
              setOpenEditUserModal={setOpenEditUserModal}
              contactPersonsList={contactPersonsList}
              onDeleteUser={onDeleteUser}
              setEditUserPrevData={setEditUserPrevData}
            />

            <hr className="horizontalLine fullHr" />
          </div>)}

          {(selectedProfileTab !== 'payment_details' && selectedProfileTab !== 'payments_usage_history') && <>
          <Button
            title="SAVE"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="saveBtn"
            loading={submitLoading}
          />
          {canDelete && <span className="deleteCompanyBtn" onClick={() => setOpenDeleteCompanyModal(true)}>Delete Company</span>}
          </>}
        </div>

        <div className="myProfile__userInfo">
          <div className="addNewPhotoBtn">
            <IconButton color="primary" aria-label="upload picture" component="label">
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={e => {
                  if (e && e.target && e.target.files) {
                    const fd = new FormData();
                    fd.append('file', e.target.files[0]);
                    dispatch(fetchUploadFile(fd)).then(res => {
                      if (res.type === 'uploadFile/fulfilled') {
                        setAvatar({
                          file: res.payload.file.fullpath,
                          temp: res.payload.file.filepath,
                        });
                      }
                    });
                  }
                }}
              />
              <CameraAltOutlinedIcon />
            </IconButton>
          </div>
          <div className="userInfo__avatar">
            <img src={avatar.file} alt="" />
          </div>
          <div className="userInfo__name">{generalInfo.companyName}</div>
          <div className="userInfo__role">Freight Agent</div>
          <div className="userInfo__email">{email}</div>
        </div>
      </div>

      {
        <>
          {openCreateUserModal && (
            <UserModalFA
              open={openCreateUserModal}
              setOpen={setOpenCreateUserModal}
              onChange={onChangeContactPerson}
              onSubmit={onAddNewUser}
              confirmTitle="ADD"
              cancelTitle="CANCEL"
              contentTitle="Add New Contact Person"
              formType="create"
              loading={submitLoading}
            />
          )}

          {openEditUserModal && (
            <UserModalFA
              open={openEditUserModal}
              setOpen={setOpenEditUserModal}
              onChange={onChangeContactPerson}
              onSubmit={onEditUserInfo}
              confirmTitle="EDIT"
              cancelTitle="CANCEL"
              contentTitle="Edit Contact Person"
              defaultData={editUserPrevData}
              setDefaultData={setEditUserPrevData}
              formType="edit"
              loading={submitLoading}
            />
          )}

          {
            <DeleteModalConfirmation
              open={openDeleteCompanyModal}
              setOpen={setOpenDeleteCompanyModal}
              deleteFunction={() => deleteCompany()}
              title={"Are you sure you want to delete your company?"}
              loading={checkedDelete ? false : true}
              setChecked={setCheckedDelete}
              checkboxMsg={'I confirm my approval for the deletion of the company. I understand that this action is irreversible and the data cannot be recovered.'}
            />
          }
        </>
      }
    </div>
  );
};

export default MyProfileFA;
