// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { CameraAltOutlined as CameraAltOutlinedIcon } from "@mui/icons-material"
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
// import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import { useAppDispatch } from '../../../../../hooks/global';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import Loader from '../../../../common/Loader/Loader';
import { Button } from '../../../../controls';
import './CreateCustomer.scss';
import { states } from '../../../../../constants/main';
import UserModalFA from './parts/CreateOrEditUserModal';
import GeneralCustomer from './parts/General';
import { createCustomer } from '../../../../../store/customer/customer';
import { getFullName, getStringWithoutSymbols } from '../../../../../helpers';

interface ICreateCustomer {
  createFromModal?: boolean;
  setClickOnSubmit?: any;
  clickOnSubmit?: boolean;
  setOpenModal?: any;
  setNewCustomer?: any;
  customerType?: string;
  setLocation?: any;
}

const CreateTC: FC<ICreateCustomer> = ({
  createFromModal,
  setClickOnSubmit,
  clickOnSubmit,
  setOpenModal,
  setNewCustomer,
  customerType,
  setLocation,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generalOpened, setGeneralOpened] = useState(true);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [isHazmat, setIsHazmat] = useState(1);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [mailingAddressState, setMailingAddressState] = useState(null);
  const [birth, setBirth] = useState(null);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });
  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [state, setState] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);

  const [addressMailingComponents, setAddressMailingComponents] = useState<any>(null);
  const [cityMailing, setCityMailing] = useState<any>(null);
  const [zipMailing, setZipMailing] = useState<any>(null);
  const [stateMailing, setStateMailing] = useState<any>(null);
  const [addressMailing, setAddressMailing] = useState<any>(null);

  useEffect(() => {
    if (clickOnSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [clickOnSubmit]);

  useEffect(() => {
    if (setLocation && address) {
      setLocation(address)
    }
  }, [address])

  useEffect(() => {
    if (addressComponents) {
      let streetNumber = '';
      let route = '';
      setAddress(addressComponents.address);

      addressComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZip(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setState(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCity(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name
          } else if (type === 'route') {
            route = component.long_name
          }
        });
      });
    }
  }, [addressComponents]);

  useEffect(() => {
    if (addressMailingComponents) {
      let streetNumber = '';
      let route = '';
      setAddressMailing(addressMailingComponents.address);

      addressMailingComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZipMailing(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setStateMailing(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCityMailing(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressMailingComponents]);

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');
    const userData = localStorage.getItem('user');

    if (id && userData) {
      setSubmitLoading(true);
      const parsedData = JSON.parse(userData);

      const dataForCreate: any = {
        id,
        ownerType:
          parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
            ? 'tc'
            : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
            ? 'fa'
            : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
            ? 'id'
            : null,
        first_name: data.first_name,
        // last_name: data.last_name,
        last_name: null,
        address: {
          state: addressState,
          // address: addressComponents?.shortAddress || null,
          address: addressComponents?.shortAddress || data.address,
          full_address: addressComponents?.address || data.address,
          address2: data.address_line_second,
          building: '',
          apartment: '',
          city: city || 'asdda',
          zip: zip || '34544',
          phone: getStringWithoutSymbols(data.phone),
          email: data.email,
          fax: getStringWithoutSymbols(data.fax),
          lat: addressComponents?.lat.toString() || null,
          lon: addressComponents?.lon.toString() || null,
        },
        billingAddress: {
          state: billingAddressState,
          address: addressMailingComponents?.shortAddress || data.billing_address,
          full_address: addressMailingComponents?.address || data.billing_address,
          address2: data.billing_address_second,
          building: '',
          apartment: '',
          city: cityMailing || data.billing_city,
          zip: zipMailing || data.billing_zip,
          phone: getStringWithoutSymbols(data.billing_phone),
          email: data.billing_email,
        },
      };

      if (avatar.temp) {
        dataForCreate.avatar = {
          file: avatar.temp,
          delete: 0,
        };
      }

      if (setClickOnSubmit) {
        if (customerType === 'shipper' || customerType === 'receiver') {
          dataForCreate.type = 'customer';
        } else {
          dataForCreate.type = customerType;
        }
      }

      dispatch(createCustomer(dataForCreate)).then(res => {
        if (res.type === 'createCustomer/fulfilled') {
          toast.success('Customer was successfully created!');
          if (setClickOnSubmit && setOpenModal) {
            setNewCustomer({
              id: res.payload.resource.id,
              value: getFullName(
                res.payload.resource.first_name,
                res.payload.resource.last_name,
              ),
              address,
            });
          } else {
            navigate('/users-management', {
              state: {
                tab: 'customers',
              },
            });
          }
        } else {
          if (setClickOnSubmit) {
            setClickOnSubmit(false);
          }
          setSubmitLoading(false);
        }
      });
    }
  };

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Add New Customer</h1>
                </div>

                {!createFromModal && (
                  <Button
                    title="BACK"
                    Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                    onClick={() =>
                      navigate('/users-management', {
                        state: {
                          tab: 'customers',
                        },
                      })
                    }
                    className="mapBtn"
                    variant="outlined"
                  />
                )}
              </div>
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralCustomer
                generalOpened={generalOpened}
                control={control}
                // generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setBillingAddressState={setBillingAddressState}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                createFromModal={createFromModal}
                setAddressComponents={setAddressComponents}
                zip={zip}
                state={state}
                city={city}
                setAddressMailingComponents={setAddressMailingComponents}
                zipMailing={zipMailing}
                stateMailing={stateMailing}
                cityMailing={cityMailing}
                address={address}
                mailingAddress={addressMailing}
                customerType={customerType}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            {!setClickOnSubmit && (
              <Button
                title="SAVE ALL"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn"
                loading={submitLoading}
              />
            )}
          </div>

          {!createFromModal && (
            <div className="createDriver__userInfo">
              <div className="addNewPhotoBtn">
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={e => {
                      if (e && e.target && e.target.files) {
                        const fd = new FormData();
                        fd.append('file', e.target.files[0]);
                        dispatch(fetchUploadFile(fd)).then(res => {
                          if (res.type === 'uploadFile/fulfilled') {
                            setAvatar({
                              file: res.payload.file.fullpath,
                              temp: res.payload.file.filepath,
                            });
                          }
                        });
                      }
                    }}
                  />
                  <CameraAltOutlinedIcon />
                </IconButton>
              </div>
              <div className="userInfo__avatar">
                <img src={avatar.file} alt="" />
              </div>
              <div className="userInfo__role">Customer</div>
            </div>
          )}

          {
            <>
              {openCreateUserModal && (
                <UserModalFA
                  open={openCreateUserModal}
                  setOpen={setOpenCreateUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onAddNewUser}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="ADD"
                  cancelTitle="CANCEL"
                  contentTitle="Add New Contact Person"
                  formType="create"
                  loading={submitLoading}
                />
              )}

              {openEditUserModal && (
                <UserModalFA
                  open={openEditUserModal}
                  setOpen={setOpenEditUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onEditUserInfo}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="EDIT"
                  cancelTitle="CANCEL"
                  contentTitle="Edit Contact Person"
                  defaultData={editUserPrevData}
                  setDefaultData={setEditUserPrevData}
                  formType="edit"
                  loading={submitLoading}
                />
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default CreateTC;
