import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../service/api';
import {
  UpdateIndependentDispatcherModel,
  UpdateTruckCompanyModel,
} from '../../models/truckCompany/truckCompany.model';

export const getIndependentDispatcherInfo = createAsyncThunk(
  'independentDispatcherInfo',
  async (id: number) => {
    const response = await api.get(`id/${id}/get`);
    return response.data;
  },
);

export const getIndependentDispatcherInfoFromAdmin = createAsyncThunk(
  'getIndependentDispatcherInfoFromAdmin',
  async (id: number) => {
    const response = await api.get(`id/${id}/get`);
    return response.data;
  },
);

export const updateIndependentDispatcherInfo = createAsyncThunk(
  'updatingIndependentDispatcherInfo',
  async (independentDispatcher: UpdateIndependentDispatcherModel) => {
    const response = await api.post(
      `id/${independentDispatcher.id}/update`,
      independentDispatcher,
    );
    return response.data;
  },
);

export const deleteIndependentDispatcher = createAsyncThunk(
  'deleteIndependentDispatcher',
  async (id: {id : number}) => {
    const response = await api.post(
      `id/${id.id}/delete`
    );
    return response.data;
  },
);

export const createID = createAsyncThunk(
  'createID',
  async (independentDispatcher: UpdateTruckCompanyModel) => {
    const response = await api.post(`id/create`, independentDispatcher);
    return response.data;
  },
);

export const getIndependentDispatcherList = createAsyncThunk(
  'getIndependentDispatcherList',
  async () => {
    const response = await api.get(`id/list`);
    return response.data;
  },
);

interface IndependentDispatcherState {
  carrierInfo: string | null;
  companyName: string | null;
  address: string | null;
  fullAddress: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  mailingAddress: string | null;
  fullMailingAddress: string | null;
  mailingCity: string | null;
  mailingState: string | null;
  mailingZip: string | null;
  mcAuthority: string | null;
  taxId: string | null;
  dot: string | null;
  scacCode: string | null;
  isHazmat: string | null;
  officePhone: string | null;
  officeFax: string | null;
  cellPhone: string | null;
  errorMessage: string | null;
  logo: any;
  documents: [] | null;
  entityId: number | null;
  flNumber: string | null;
  email: any;
  userId: any;
  billingInfo: any;
}

const initialState: IndependentDispatcherState = {
  carrierInfo: null,
  companyName: null,
  address: null,
  fullAddress: null,
  city: null,
  state: null,
  zip: null,
  mailingAddress: null,
  fullMailingAddress: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  mcAuthority: null,
  taxId: null,
  dot: null,
  scacCode: null,
  isHazmat: null,
  officePhone: null,
  officeFax: null,
  cellPhone: null,
  errorMessage: null,
  logo: null,
  documents: [],
  entityId: null,
  flNumber: null,
  email: null,
  userId: null,
  billingInfo: null,
};

export const independentDispatcher = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getIndependentDispatcherInfo.fulfilled, (state, action) => {
      state.carrierInfo = action.payload.resource.info || null;
      state.companyName = action.payload.resource.title || null;
      state.address = action.payload.resource.address.address || null;
      state.fullAddress = action.payload.resource.address.full_address || null;
      state.city = action.payload.resource.address.city || null;
      state.state = action.payload.resource.address.state?.code || null;
      state.zip = action.payload.resource.address.zip || null;
      state.mailingAddress = action.payload.resource.mailing_address.address || null;
      state.fullMailingAddress = action.payload.resource.mailing_address.full_address || null;
      state.mailingCity = action.payload.resource.mailing_address.city || null;
      state.mailingState = action.payload.resource.mailing_address.state?.code || null;
      state.mailingZip = action.payload.resource.mailing_address.zip || null;
      state.mcAuthority = action.payload.resource.mc_authority || null;
      state.taxId = action.payload.resource.tax_id || null;
      state.dot = action.payload.resource.dot_id || null;
      state.scacCode = action.payload.resource.scac || null;
      state.isHazmat = action.payload.resource.is_hazmat || null;
      state.officePhone = action.payload.resource.address.phone || null;
      state.officeFax = action.payload.resource.address.fax || null;
      state.cellPhone = action.payload.resource.address.cell_phone || null;
      state.email = action.payload.resource.user.email || null;
      state.userId = action.payload.resource.user.id || null;
      state.logo = action.payload.resource.logo?.file || null;
      state.documents = action.payload.resource.documents || null;
      state.entityId = action.payload.resource.entity.id || null;
      state.flNumber = action.payload.resource.entity.fl_number || null;
      state.billingInfo = action.payload.resource.billing_info || null;
    });
    builder.addCase(getIndependentDispatcherInfo.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    builder.addCase(getIndependentDispatcherInfoFromAdmin.fulfilled, (state, action) => {
      const userDataFromStorage = localStorage.getItem('user');
      let parsedData: any = null;

      if (userDataFromStorage) {
        parsedData = JSON.parse(userDataFromStorage);
      }

      const userData = {
        ...parsedData,
        email: action.payload.resource.user.email,
        // role: `${localStorage.getItem('entity_type')}-admin`,
        company_name: action.payload.resource.title,
        is_profile_filled: true,
        creator_id: action.payload.resource.entity.creator_id,
        profile_id: action.payload.resource.user.profile_id,
        id: action.payload.resource.user.id,
      };

      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem(
        'entity_id',
        JSON.stringify(action.payload.resource.entity.id),
      );
    });

    builder.addCase(updateIndependentDispatcherInfo.fulfilled, (state, action) => {
      toast.success('Profile has been successfully changed!');

      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    });
  },
});

export default independentDispatcher.reducer;
