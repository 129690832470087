import React, { FC, useState } from 'react';
import { ContainedInput_, RadioButton } from '../../../../controls';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import DeleteModalConfirmation from '../../../../controls/DeleteModalConfirmation/DeleteModalConfirmation';

interface IContactPersons {
  contactPersonsOpened: boolean;
  setOpenCreateUserModal: any;
  setOpenEditUserModal?: any;
  contactPersonsList: any;
  onDeleteUser: any;
  setEditUserPrevData: any;
}

const ContactPersonsID: FC<IContactPersons> = ({
  contactPersonsOpened,
  setOpenCreateUserModal,
  contactPersonsList,
  onDeleteUser,
  setOpenEditUserModal,
  setEditUserPrevData,
}) => {
  return (
    <>
      {contactPersonsOpened && (
        <>
          {contactPersonsList?.length
            ? contactPersonsList.map((contactPerson: any, index: number) => {
                return (
                  <ContactPerson
                    key={contactPerson.user_id}
                    contactPerson={contactPerson}
                    index={index}
                    onDeleteUser={onDeleteUser}
                    setOpenEditUserModal={setOpenEditUserModal}
                    setEditUserPrevData={setEditUserPrevData}
                  />
                );
              })
            : null}

          <div className="contactPersonBtns">
            <span
              className="contactPersonBtns__addContact"
              onClick={() => setOpenCreateUserModal(true)}
            >
              <span className="addIconSpan">+</span>
              <span className="contactPersonBtns__text">Add Contact Person</span>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(ContactPersonsID);

interface IContactPerson {
  contactPerson: any;
  index: number;
  setEditUserPrevData: any;
  setOpenEditUserModal: any;
  onDeleteUser: any;
}

const ContactPerson: FC<IContactPerson> = ({
  contactPerson,
  index,
  setEditUserPrevData,
  setOpenEditUserModal,
  onDeleteUser,
}) => {
  const [openDeleteModal, setDeleteModal] = useState<boolean>(false);

  return (
    <>
      {contactPerson ? (
        <div className="general__line-1" key={contactPerson.user_id}>
          <ContainedInput_
            label={`Person ${index + 1} Name`}
            className="marginRight"
            readOnly={true}
            defaultValue={`${contactPerson.first_name + ' ' + contactPerson.last_name}`}
          />
          <ContainedInput_
            label="Email"
            className="marginRight"
            readOnly={true}
            defaultValue={contactPerson.email}
          />
          <ContainedInput_
            label="Phone"
            className="marginRight"
            readOnly={true}
            defaultValue={contactPerson.phone}
            type={contactPerson.phone ? 'phone' : ''}
          />
          <RadioButton
            label="User Role"
            firstVariant="Admin"
            secondVariant="Regular User"
            name="users-list"
            defaultValue={contactPerson.user_role}
            className="marginRight radioBtnGContactPerson"
          />
          <div className="userActions">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => {
                setEditUserPrevData({
                  id: contactPerson.user_id,
                  first_name: contactPerson.first_name,
                  last_name: contactPerson.last_name,
                  phone: contactPerson.phone,
                  email: contactPerson.email,
                  userRole: contactPerson.user_role,
                });
                setOpenEditUserModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="deleteBtnAction"
              onClick={() => setDeleteModal(true)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <DeleteModalConfirmation
            open={openDeleteModal}
            setOpen={setDeleteModal}
            deleteFunction={() => onDeleteUser(contactPerson.user_id)}
          />
        </div>
      ) : null}
    </>
  );
};
