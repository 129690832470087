import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { getNotificateCounters } from '../../../store/notification/notification';
import { getLoadList, getLoadMapDrivers } from '../../../store/loads/loads';
import { getDriversList } from '../../../store/driver/driver';
import { getSpecificLoad } from '../../../store/specificLoad/specificLoad';
import { ROUTES } from '../../../router/routes';
import { Badge } from '@mui/material';
import Loader from '../../common/Loader/Loader';
import { Button, Portal } from '../../controls';
import Dropdown from './parts/Dropdown/Dropdown';
import Filters from './parts/Filters/Filters';
import TableComponent from './parts/Table';
import Pagination from '../../controls/Pagination/Pagination';
import { DataPack } from './parts/DataPack/DataPack';
import { ModalMap } from './parts/ModalMap/ModalMap';
import { ModalDriverMap } from './parts/ModalDriverMap/ModalDriverMap';
import MapWidthMarkers from './parts/MapWidthMarkers/MapWidthMarkers';
import moment from 'moment';
import './Dashboard.scss';
import { getFullName } from '../../../helpers';

interface IDashboard {
  blockAccess?: boolean;
}

const Dashboard: FC<IDashboard> = ({ blockAccess }) => {
  const [activeDropdown, setActiveDropdown] = useState<string>('');
  const [amountPages, setAmountPages] = useState<number>(1);
  const [sort, setSort] = useState<null | string>(null);
  const [page, setPage] = useState<number>(1);
  const [driversList, setDriversList] = useState<any>([]);
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [status, setStatus] = useState<null | string>(null);
  const [driver, setDriver] = useState<null | string>(null);
  const [dateValue, setDate] = useState<null | string>(null);
  const [formattedDate, setFormattedDate] = useState<null | string>(null);
  const [typeLoads, setTypeLoads] = useState<null | string>('all');
  const [specificLoad, setSpecificLoad] = useState<null | number>(null);
  const [dataPack, setDataPack] = useState<boolean>(false);
  const [openModalMap, setOpenModalMap] = useState<boolean>(false);
  const [openModalDriverMap, setOpenModalDriverMap] = useState<boolean>(false);
  const [openModalProfile, setOpenModalProfile] = useState<boolean>(false);
  const [typeMediaOpen, setTypeMediaOpen] = useState<string>("");
  const [selectedLoadId, setSelectedLoadId] = useState<any>(null);
  const [selectedLoadsIds, setSelectedLoadsIds] = useState<any>([]);
  const [driversPins, setDriversPins] = useState<any>([]);

  const { counters } = useAppSelector(state => state.notification);
  const { loadsList, loading } = useAppSelector(state => state.loads);
  const card = useAppSelector(state => state.payment.card);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificateCounters());
    dispatch(getLoadMapDrivers({})).then((res: any) => {
      if (res.type === 'getLoadMapDrivers/fulfilled') {
        const pins: any = [];
        res?.payload?.data?.resource?.map((pin: any) => {
          if (pin.pin) {
            let driverName = ' - ';

            if (pin?.driver?.profile) {
              driverName = getFullName(pin?.driver?.profile?.first_name, pin?.driver?.profile?.last_name);
            }

            pins.push({
              driver_id: pin.driver_id,
              truck: pin.truck ? pin.truck?.number : ' - ',
              trailer: pin.trailer ? pin.trailer?.number : ' - ',
              name: driverName,
              load_id: pin.id,
              load: pin.load_number,
              is_active: pin.pin.is_active,
              is_moving: pin.pin.is_moving,
              lat: pin.pin.lat,
              lng: pin.pin.lon,
              dir: pin.pin?.dir ?? null,
              dirDeg: pin.pin?.dirDeg ?? 0,
              pinType: pin.pin.is_active === false ? 'circle' : pin.pin.is_moving === true ? 'arrow' : 'circle',
              pinColor: pin.pin.is_active === false ? 'black' : pin.pin.is_moving === true ? '-' : 'green',
            });
          }
        })

        setDriversPins(pins)
      }
    });

  }, []);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const parsedData = JSON.parse(userData);
      if (!parsedData.is_profile_filled) {
        setOpenModalProfile(true);
      }
    }
  }, []);

  useEffect(() => {
    if (loadsList && loadsList.data && loadsList.data.resource) {
      setAmountPages(
        loadsList.data.resource.last_page ? loadsList.data.resource.last_page : 1,
      );
    }
  }, [loadsList, sort]);

  useEffect(() => {
    getList(page);
  }, [sort, sortDirection, status, dateValue, driver, typeLoads]);

  const changeSortType = (sortType: string | null) => {
    setSort(sortType);
    setSortDirection(sortDirection => (sortDirection === 'asc' ? 'desc' : 'asc'));
    setPage(1);
  };

  const getList = (page: number) => {
    dispatch(
      getLoadList({ page, sort, sortDirection, status, formattedDate, driver, typeLoads }),
    );
  };

  const changePage = (page: number) => {
    setPage(page);
    sessionStorage.setItem('page', page.toString());
    getList(page);
  };

  const changedataPack = (type: boolean) => setDataPack(type);
  const changeOpenModalMap = (type: boolean) => setOpenModalMap(type);
  const changeOpenModalDriverMap = (type: boolean) => setOpenModalDriverMap(type);

  const setPopup = (load: number, type: string) => {
    setDataPack(true);
    setTypeMediaOpen(type)
    
    setSpecificLoad(load);
    const root: HTMLElement | null = document.querySelector('html');
    root ? (root.style.overflow = 'hidden') : null;
    root ? (root.style.paddingRight = '17px') : null;
  };

  const setModalMap = (load: number) => {
    dispatch(getSpecificLoad(load));
    setOpenModalMap(true);
    setSpecificLoad(load);
  };

  const setModalDriverMap = (load: number) => {
    dispatch(getSpecificLoad(load));
    setOpenModalDriverMap(true);
    setSpecificLoad(load);
  };

  const goToNewLoad = () => navigate(ROUTES.ADD_NEW_LOAD);

  const changeTypeLoads = (type: string) => {
    sessionStorage.setItem('type', type);
    setTypeLoads(type);
  };
  const changeDriver = (id: string) => setDriver(id === 'all' ? '' : id);

  const changeStatus = (status: string) => setStatus(status === 'all' ? '' : status);

  const changeDate = (date: string) => {
    if (date) {
      setFormattedDate(moment(date).format('YYYY-MM-DD'));
      setDate(date);
    } else {
      setFormattedDate(null);
      setDate(null);
    }
  };

  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  useEffect(() => {
    if (id) {
      const billTo: any = [];
      const customers: any = [];
      const drivers: any = [];
      const indepDispatchers: any = [];
      const type =
        parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
          ? 'tc'
          : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
          ? 'fa'
          : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
          ? 'id'
          : null;

      const dataForDrivers: any = {
        id,
        type,
      };

      dispatch(getDriversList(dataForDrivers)).then(res => {
        if (res.type === 'getDriversList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((driver: any) => {
                let fullName = '';
                if (driver.profile.first_name && driver.profile.last_name) {
                  fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
                } else if (driver.profile.first_name && !driver.profile.last_name) {
                  fullName = driver.profile.first_name;
                } else if (!driver.profile.first_name && driver.profile.last_name) {
                  fullName = driver.profile.last_name;
                }

                drivers.push({ id: driver.id, value: fullName });
              });

              setDriversList(drivers);
            }
          }
        }
      });
    }
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <Portal>
        {dataPack && (
          <DataPack
            changedataPack={changedataPack}
            dataPack={dataPack}
            loadId={specificLoad}
            typeMediaOpen={typeMediaOpen}
          />
        )}
      </Portal>
      {openModalMap && (
        <ModalMap
          changeOpenModalMap={changeOpenModalMap}
          loadId={specificLoad}
          openModalMap={openModalMap}
        />
      )}

      {openModalDriverMap && (
        <ModalDriverMap
          changeOpenModalMap={changeOpenModalDriverMap}
          loadId={specificLoad}
          openModalMap={openModalDriverMap}
        />
      )}

      <div className="dashboard mainContainer">
        <div className="dashboard__container">
          <div className="dashboard__header">
            <h1>FreightLife Dashboard</h1>

            {(!blockAccess && card && card !== 'no card') && (
              <Button
                className="newLoadBtn"
                onClick={goToNewLoad}
                variant="contained"
                title="+ ADD NEW LOAD"
              />
            )}
          </div>

          <div className="dashboard__body">
            <div className="componentHeader">
              <h1 className="componentHeader__title">Full Map</h1>
              <div className="componentHeader__filters">
              <Badge color="primary" badgeContent={counters?.data?.resource?.handoffs}>
                  <Button
                    onClick={() =>
                      setActiveDropdown(activeDropdown =>
                        activeDropdown === 'Handoffs' ? '' : 'Handoffs',
                      )
                    }
                    className="mapBtn"
                    variant="outlined"
                    title="Hand Off Requests"
                  />
                  {activeDropdown === 'Handoffs' ? (
                    <Dropdown title="Hand Off Requests" shortTitle="Handoffs" />
                  ) : null}
                </Badge>
                
                <Badge color="primary" badgeContent={counters?.data?.resource?.etas}>
                  <Button
                    onClick={() =>
                      setActiveDropdown(activeDropdown =>
                        activeDropdown === 'ETA' ? '' : 'ETA',
                      )
                    }
                    className="mapBtn"
                    variant="outlined"
                    title="ETA Confirmation"
                  />
                  {activeDropdown === 'ETA' ? (
                    <Dropdown title="ETA Confirmation" shortTitle="ETA" />
                  ) : null}
                </Badge>

                <Badge color="primary" badgeContent={counters?.data?.resource?.delays}>
                  <Button
                    onClick={() =>
                      setActiveDropdown(activeDropdown =>
                        activeDropdown === 'Delay' ? '' : 'Delay',
                      )
                    }
                    className="mapBtn"
                    variant="outlined"
                    title="Delay Notification"
                  />
                  {activeDropdown === 'Delay' ? (
                    <Dropdown title="Delay Notification" shortTitle="Delay" />
                  ) : null}
                </Badge>

                <Badge badgeContent={counters?.data?.resource?.reports} color="primary">
                  <Button
                    onClick={() =>
                      setActiveDropdown(activeDropdown =>
                        activeDropdown === 'Report' ? '' : 'Report',
                      )
                    }
                    className="mapBtn"
                    variant="outlined"
                    title="My Incident
                  Report"
                  />
                  {activeDropdown === 'Report' ? (
                    <Dropdown title="My Incident Report" shortTitle="Report" />
                  ) : null}
                </Badge>
              </div>
            </div>

            <div className="mapPic">
              <MapWidthMarkers selectedLoadId={selectedLoadId} driversPins={driversPins} selectedLoadsIds={selectedLoadsIds}/>
            </div>

            <div>
              <div className="componentHeader">
                <h1 className="componentHeader__title">Full Loads List</h1>
                <div className="componentHeader__filters">
                  <Filters
                    drivers={driversList}
                    changeDriver={changeDriver}
                    status={status}
                    changeStatus={changeStatus}
                    driver={driver}
                    dateValue={dateValue}
                    changeDate={changeDate}
                    changeTypeLoads={changeTypeLoads}
                    typeLoads={typeLoads}
                  />
                </div>
              </div>

              {loadsList && (
                <TableComponent
                  list={loadsList}
                  changeSortType={changeSortType}
                  setPopup={setPopup}
                  setModalMap={setModalMap}
                  setModalDriverMap={setModalDriverMap}
                  setSelectedLoadId={setSelectedLoadId}
                  setSelectedLoadsIds={setSelectedLoadsIds}
                  selectedLoadsIds={selectedLoadsIds}
                />
              )}
              <div className="pagination__body">
                <div className="totalList">
                  Displaying &nbsp;
                  {loadsList && loadsList.data
                    ? !loadsList.data.resource.to
                      ? 0
                      : loadsList.data.resource.to
                    : 1}
                  &nbsp; of &nbsp;
                  {loadsList && loadsList.data ? loadsList.data.resource.total : 1}
                </div>
                {loadsList &&
                loadsList.data &&
                loadsList.data.resource &&
                loadsList.data.resource.data &&
                loadsList.data.resource.data.length > 0 ? (
                  <Pagination
                    pageNumber={page}
                    changePage={changePage}
                    amountPages={amountPages}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        open={openModalProfile}
        onClose={() => setOpenModalProfile(false)}
        onConfirm={() => navigate('/my-profile')}
        confirmTitle="PROFILE"
        contentText="please fill and complete 
        your profile"
      /> */}
    </>
  );
};

export default Dashboard;
