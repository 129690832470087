import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal, Selection } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import {
  createTruck,
  deleteEquipmentType,
  deleteTrailer,
  deleteTruck,
} from '../../../../../store/load/load';
import { deleteDriver } from '../../../../../store/driver/driver';
import { deleteCustomer } from '../../../../../store/customer/customer';
import { deleteIndependentDispatcher } from '../../../../../store/independentDispatcher/independentDispatcher';

interface ISelectCustomerModal {
  open: any;
  setOpen: any;
  // onSubmit?: any;
  customers: any;
  addFileHandler: any;
}

const SelectCustomerModal: FC<ISelectCustomerModal> = ({ open, setOpen, customers, addFileHandler }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [eFile, setEFile] = useState<any>(null);

  const onSubmit = () => {
    addFileHandler(selectedCustomer, 'bols_scan', eFile, null)
  }

  return (
    <Modal
      open={open}
      onConfirm={onSubmit}
      confirmTitle={'ADD FILE'}
      cancelTitle={'CANCEL'}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="delete-item-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div className="label">
            1.Please select the shipper/receiver
            <Selection
              label=""
              className="inputFlexOne fullWidthSelect"
              items={customers}
              name="customer"
              setValue={setSelectedCustomer}
              defaultValue={selectedCustomer}
            />
          </div>
          <div className="label">
            2.
            <input
                          type="file"
                          onChange={e => setEFile(e)}
                          id="file-input"
                          // hidden
                          style={{marginLeft: 10}}
                          disabled={!selectedCustomer}
            />
          </div>
        </div>
      }
    />
  );
};

export default SelectCustomerModal;