import React from 'react';
import './Loader.scss';
import Loading from 'react-fullscreen-loading';
import PropTypes from 'prop-types';

const Loader = (props) => {
  const {loading} = props;

  return (
    <>
      <Loading loading={loading} background="#00000087" loaderColor="#4cd964" />
    </>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
}

export default Loader;
