import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import './Banner.scss';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Banner = () => {
  return (
    <div className="mainBanner">
      <div className="mainBanner__innerBlock active">
        <span className="betaTesting">beta testing</span>
        <span className="active">Active</span>
      </div>
      <div className="mainBanner__innerBlock hr">
        <hr></hr>
      </div>
      <div className="mainBanner__innerBlockBigger">
       During the beta testing period, access to Freight Life is being provided at <span>no cost.</span>
      </div>
    </div>
  );
};

export default Banner;
