import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import { createEquipmentType, createTruck, editEquipmentType } from '../../../../../store/load/load';

interface IUserModal {
  open: any;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setLocation?: any;
  setEquipmentType?: any;
  editMode?: boolean;
  equipmentType?: any;
  selectedTC?: any;
}

const CreateEquipmentTypeModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setLocation,
  setEquipmentType,
  editMode,
  equipmentType,
  selectedTC,
}) => {
  const dispatch = useAppDispatch();
  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

  const [typeTitle, setTypeTitle] = useState<any>(null);
  const [typeId, setTypeId] = useState<any>(null);

  useEffect(() => {
    if (equipmentType?.id) {
      setTypeId(equipmentType.id);
      setTypeTitle(equipmentType.value);
    }
  }, [equipmentType])

  const onSubmit_ = () => {
    if (selectedTC?.id) {
      const type =
        parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
          ? 'tc'
          : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
          ? 'fa'
          : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
          ? 'id'
          : null;

      const data = {
        type: 'tc',
        id: selectedTC?.id,
        title: typeTitle,
      };

      const dataForUpdate = {
        type: 'tc',
        id: selectedTC?.id,
        title: typeTitle,
        equipmentTypeId: equipmentType?.id,
      }

      if (editMode) {
        dispatch(editEquipmentType(dataForUpdate)).then((res: any) => {
          if (res.type === 'editEquipmentTypes/fulfilled') {
            const filteredUsersList = usersList.filter((item: any) => item.id !== equipmentType.id);
            setUsersList([
              ...filteredUsersList,
              {
                id: equipmentType.id,
                value: typeTitle,
              },
            ]);
            setOpen(false);
          }
        });
      } else {
        dispatch(createEquipmentType(data)).then((res: any) => {
          if (res.type === 'createEquipmentTypes/fulfilled') {
            setTypeId(res.payload.resource.id);
            setUsersList([
              ...usersList,
              {
                id: res.payload.resource.id,
                value: typeTitle,
              },
            ]);
          }
        });
      }
    }
  };

  useEffect(() => {
    setEquipmentType({
      id: typeId,
      value: typeTitle,
    });

    if (typeId && !editMode) {
      setOpen(false);
    }
  }, [typeId, typeTitle]);

  const onChange_ = (e: any) => {
    setTypeTitle(e.target.value);
  };

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={onSubmit_}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="label">Equipment Type</div>
          <input
            className="customizedInputContainer__input"
            defaultValue={typeTitle}
            onChange={onChange_}
            style={{ width: '50%', marginBottom: '10px' }}
          />
        </div>
      }
    />
  );
};

export default CreateEquipmentTypeModal;
