import React, { FC, useEffect, useState } from 'react';
import { Modal, Checkbox } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer, CreateDriver, EditDriver } from '../../..';
import './CreateCustomerModal.scss';

interface IUserModal {
  open: boolean;
  setOpen: any;
  onSubmit: any;
}

const DeleteLoadModal: FC<IUserModal> = ({ open, setOpen, onSubmit }) => {
  const [agree, setAgree] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      onConfirm={() => (agree ? onSubmit() : null)}
      confirmTitle={'Delete Load'}
      cancelTitle={'Back'}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="delete-load-modal"
      disabled={agree ? false : true}
      content={
        <div>
          <div className="deleteLoadTitle">
            Are you sure you want to delete this load?
          </div>

          <div className="checkboxDeleteLoad">
            <Checkbox setCheckedValue={setAgree} defaultChecked={false} />
            I agree with the loss of load data
          </div>
        </div>
      }
    />
  );
};

export default DeleteLoadModal;