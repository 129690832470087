import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../controls';
import './HomeStyles.scss';

const Home: FC = () => {
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <div className="homeContainer l-popup" id="home-page">
      <img
        src={require('../../../assets/images/main-logo.png')}
        alt="Logo"
        className="mainLogo"
      />

      <div className="getStarted">
        <h1 className="getStarted__title">
          Bols Freight Life and Bols Confirmation/Automatic Invoicing
        </h1>

        <p className="getStarted__mainText">
          Start to Finish Visibility, Freight Life Package, Live GPS Tracking, Invoicing
          Support, Paperless
        </p>

        <Button
          title="Get started"
          onClick={goToSignIn}
          variant="contained"
          color="primary"
          minWidth="270px"
          textColor="white"
          className="startBtn"
          id="getStarted"
        />
      </div>
    </div>
  );
};

export default Home;
