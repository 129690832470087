import React, { FC, useEffect, useState, memo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Checkbox, ContainedInput, Selection } from '../../../controls';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { shipper_types } from '../../../../constants/main';
import AutocompleteComponent from '../../../controls/AutocompleteSelection/Autocomplete';
import { getFullName } from '../../../../helpers';
import moment from 'moment';
import DeleteOptionModal from '../CreateLoad/parts/DeleteOptionModal';
import CreateCustomerModal from '../EditLoad/parts/CreateCustomerModal';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IReceiver {
  control: any;
  receiverId: number | string;
  isSelected: boolean;
  items: any;
  setModalOpen?: any;
  setReceiversUsers: any;
  receiversUsers: any;
  readOnly?: boolean;
  setSelectedUsers?: any;
  selectedUsers?: any;
  usersList?: any;
  setUsersList?: any;
}

const Receiver: FC<IReceiver> = ({
  control,
  receiverId,
  isSelected,
  items,
  setModalOpen,
  setReceiversUsers,
  receiversUsers,
  readOnly,
  setSelectedUsers,
  selectedUsers,
  usersList,
  setUsersList,
}) => {
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [dateAndTime, setDateAndTime] = useState<any>(null);
  const [receiver, setReceiver] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [showTimeChecked, setShowTimeChecked] = useState<any>(true);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [defaultAddress, setDefaultAddress] = useState<any>(null);
  const [receiverInfo, setReceiverInfo] = useState<any>(null);
  const [editCustomerModal, setEditCustomerModal] = useState<any>(false);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [optionForDelete, setOptionForDelete] = useState<any>();
  const [deletedCustomers, setDeletedCustomers] = useState<any>([]);

  const onHandleEditField = (type: string, option: any) => {
    setSelectedCustomer(option);
    setEditCustomerModal(true);
  };

  const onHandleDeleteField = (type: string, option: any) => {
    setOptionForDelete({
      id: option.id,
      type,
    });
    setDeleteModal(true);
  };

  useEffect(() => {
    const date_ = moment(date);
    const time_ = moment(time);
    const correctDate_ = moment(date_, 'DD/MM/YYYY').format('DD/MM/YYYY');
    const correctTime_ = moment(time_, 'HH:mm').format('HH:mm');
    const dateTime = moment(correctDate_ + ' ' + correctTime_, 'DD/MM/YYYY HH:mm');
    const correctDateTime = dateTime.format('YYYY-MM-DD HH:mm');
    setDateAndTime(correctDateTime);
  }, [date, time]);

  useEffect(() => {
    if (dateAndTime) {
      if (receiversUsers.length) {
        const receiversUsers_: any = [];

        receiversUsers.map((receiver_: any) => {
          if (receiver_.index !== receiverId) {
            receiversUsers_.push(receiver_);
          } else {
            receiversUsers_.push({
              ...receiver_,
              dateAndTime: dateAndTime,
            });
          }
        });

        setReceiversUsers(receiversUsers_);
      }
    }
  }, [dateAndTime]);

  useEffect(() => {
    if (receiver && receiversUsers.length) {
      if (receiver.id && setSelectedUsers) {
        if (!selectedUsers.length) {
          setSelectedUsers([
            {
              index: receiverId,
              customer_id: receiver.id,
            },
          ]);
        } else {
          setSelectedUsers((prev: any) => {
            const users_: any = [];
            if (prev.length) {
              prev.map((prevItem: any) => {
                if (prevItem.index === receiverId) {
                  users_.push({
                    index: receiverId,
                    customer_id: receiver.id,
                  });
                } else {
                  users_.push(prevItem);
                  users_.push({
                    index: receiverId,
                    customer_id: receiver.id,
                  });
                }
              });
            }

            return [...users_];
          });
        }
      }
      if (!receiver || receiver.id !== selectedCustomer?.id) {
        const receiversUsers_: any = [];

        receiversUsers.map((shipper_: any) => {
          if (shipper_.index !== receiverId) {
            receiversUsers_.push(shipper_);
          } else {
            receiversUsers_.push({
              ...shipper_,
              customer_id: receiver.id,
            });
          }
        });

        setReceiversUsers(receiversUsers_);
      }
    }
  }, [receiver]);

  useEffect(() => {
    if (type && receiversUsers.length) {
      const receiversUsers_: any = [];

      receiversUsers.map((shipper_: any) => {
        if (shipper_.index !== receiverId) {
          receiversUsers_.push(shipper_);
        } else {
          receiversUsers_.push({
            ...shipper_,
            cargo_type: type,
          });
        }
      });

      setReceiversUsers(receiversUsers_);
    }
  }, [type]);

  useEffect(() => {
    if (receiversUsers) {
      if (receiversUsers.length) {
        const shippersUsers_: any = [];

        receiversUsers.map((receiver_: any) => {
          if (receiver_.index !== receiverId) {
            shippersUsers_.push(receiver_);
          } else {
            shippersUsers_.push({
              ...receiver_,
              is_time_shown: showTimeChecked ? 1 : 0,
            });
          }
        });

        setReceiversUsers(shippersUsers_);
      }
    }
  }, [showTimeChecked]);

  const handleChange = (newValue: any, type: string) => {
    if (receiversUsers?.length) {
      const receiversUsers_: any = [];

      if (type === 'date') {
        setDate(newValue);
        receiversUsers.map((shipper_: any) => {
          if (shipper_.index !== receiverId) {
            receiversUsers_.push(shipper_);
          } else {
            receiversUsers_.push({
              ...shipper_,
              date: newValue,
            });
          }
        });
      }

      if (type === 'time') {
        setTime(newValue);
        receiversUsers.map((shipper_: any) => {
          if (shipper_.index !== receiverId) {
            receiversUsers_.push(shipper_);
          } else {
            receiversUsers_.push({
              ...shipper_,
              time: newValue,
            });
          }
        });
      }

      setReceiversUsers(receiversUsers_);
    }
  };

  useEffect(() => {
    if (items.length) {
      items.map((item: any) => {
        if (item.selected) {
          if (item.open) {
            if (
              item.open.customer_id === receiverId &&
              item.open.customer_type === 'receiver'
            ) {
              setSelectedCustomer(item);
              if (item.address) {
                setDefaultAddress(item.address);
              }
            }
          }
        }
      });
    }
  }, [items]);

  useEffect(() => {
    if (receiversUsers?.length) {
      receiversUsers.map((receiver: any) => {
        if (receiver.index === receiverId) {
          setReceiverInfo(receiver);
        }
      });
    }
  }, [receiversUsers]);

  useEffect(() => {
    const isDeleted = deletedCustomers.find(
      (customer: any) => customer.id === receiverInfo.customer.id,
    );

    if (!selectedCustomer?.id && !isDeleted) {
      if (receiverInfo) {
        if (receiverInfo.customer) {
          setSelectedCustomer({
            id: receiverInfo.customer.id,
            value: getFullName(
              receiverInfo.customer.first_name,
              receiverInfo.customer.last_name,
            ),
          });
          setDefaultAddress(
            receiverInfo.customer.address?.full_address ??
              receiverInfo.customer.address?.address,
          );
          setDate(receiverInfo.date);
          setTime(receiverInfo.date);
        }
      }
    }
  }, [selectedCustomer, receiverInfo]);

  useEffect(() => {
    if (!type) {
      if (receiverInfo) {
        if (receiverInfo.cargo_type) {
          setType(receiverInfo.cargo_type);
        }
      }
    }
  }, [type, receiverInfo]);

  return (
    <>
      {receiverInfo && (
        <div className={`receiverForm ${!isSelected ? 'formHidden' : ''}`}>
          <div className="general__line-1">
            <AutocompleteComponent
              items={items}
              label="Receiver"
              labelId="receiver"
              className="inputFlexOne"
              setModalOpen={setModalOpen}
              setEditModalOpen={onHandleEditField}
              setDeleteModalOpen={onHandleDeleteField}
              setValueForInput={setReceiver}
              defaultValue={selectedCustomer}
              customerType="receiver"
              customerId={receiverId}
              disabled={readOnly}
              control={control}
              editable={true}
            />
            <ContainedInput
              label="Location"
              className="marginRight inputFlexOne"
              control={control}
              id={`receiver.${receiverId}.location`}
              readOnly
              defaultValue={receiver?.full_address ?? receiver?.address ?? defaultAddress}
            />
            {readOnly ? (
              <ContainedInput
                label="Date"
                className="marginRight inputFlexOne"
                control={control}
                readOnly
                defaultValue={date ? moment(date).format('YYYY/MM/DD') : null}
              />
            ) : (
              <div className="marginRight birth inputFlexOne">
                <div className="label">Date</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={date}
                    onChange={(newValue: any) => handleChange(newValue, 'date')}
                    renderInput={params => <TextField {...params} />}
                    disabled={readOnly}
                  />
                </LocalizationProvider>
              </div>
            )}
            {readOnly ? (
              <ContainedInput
                label="Show Time"
                className="marginRight inputFlexOne"
                control={control}
                readOnly
                defaultValue={date ? moment(date).format('hh:mm A') : null}
              />
            ) : (
              <div className="birth inputFlexOne">
                <div className="label showTimeLabel">
                  Show Time <Checkbox setCheckedValue={setShowTimeChecked} />
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    value={time}
                    onChange={(newValue: any) => handleChange(newValue, 'time')}
                    disabled={readOnly}
                    renderInput={params => (
                      <TextField
                        disabled={readOnly}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'hh:mm am/pm',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>

          <div className="general__line-1">
            <div className="inputsContainer inputFlexOne marginRight">
              <ContainedInput
                label="Description"
                className="inputFlexOne"
                control={control}
                id={`receiver.${receiverId}.description`}
                readOnly={readOnly}
                defaultValue={receiverInfo?.desc}
              />
            </div>
            <div className="inputsContainer inputFlexOne marginRight">
              <Selection
                label="Type"
                className="customWidthState inputFlexOne"
                items={shipper_types}
                name="shipper_type"
                setValue={setType}
                defaultValue={type}
                readOnly={readOnly}
                control={control}
              />
            </div>
            <div className="inputsContainer inputFlexOne">
              <ContainedInput
                label="Qty"
                className="marginRight inputFlexOne"
                control={control}
                id={`receiver.${receiverId}.qty`}
                readOnly={readOnly}
                defaultValue={receiverInfo?.qty}
              />
              <ContainedInput
                label="Weight"
                className="marginRight inputFlexOne"
                control={control}
                id={`receiver.${receiverId}.weight`}
                readOnly={readOnly}
                defaultValue={receiverInfo?.weight}
              />
            </div>
            <div className="inputsContainer inputFlexOne">
              <ContainedInput
                label="Value"
                control={control}
                id={`receiver.${receiverId}.value`}
                readOnly={readOnly}
                className="inputFlexOne"
                type="price"
                defaultValue={receiverInfo?.value ? receiverInfo.value : null}
              />
            </div>
          </div>

          <div className="general__line-1">
            <ContainedInput
              label="Delivery Notes"
              className="marginRight"
              control={control}
              id={`receiver.${receiverId}.shipping_notes`}
              readOnly={readOnly}
              defaultValue={receiverInfo?.notes}
            />
            <ContainedInput
              label="P.O. Numbers"
              className="marginRight"
              control={control}
              id={`receiver.${receiverId}.po_numbers`}
              readOnly={readOnly}
              defaultValue={receiverInfo?.po_numbers}
            />
            <ContainedInput label="" className="hiddenBlock" control={control} />
          </div>

          {editCustomerModal && (
            <CreateCustomerModal
              open={editCustomerModal}
              setOpen={setEditCustomerModal}
              onChange={() => null}
              onSubmit={() => null}
              confirmTitle="UPDATE"
              cancelTitle="CANCEL"
              contentTitle=""
              formType="create"
              loading={false}
              usersList={usersList}
              setUsersList={setUsersList}
              customerType="receiver"
              setSelectedCustomer={setSelectedCustomer}
              selectedCustomer={selectedCustomer}
              editMode={true}
            />
          )}

          {deleteModal && optionForDelete && (
            <DeleteOptionModal
              open={deleteModal}
              setOpen={setDeleteModal}
              option={optionForDelete}
              setDeletedOptions={setDeletedCustomers}
              deletedOptions={deletedCustomers}
              selectedOption={receiver}
              setSelectedOption={setReceiver}
              setSelectedOption_={setSelectedCustomer}
              optionsList={usersList}
              setOptionsList={setUsersList}
            />
          )}
        </div>
      )}
    </>
  );
};

export default memo(Receiver);
