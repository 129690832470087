import React, { FC, useState } from 'react';
import { Modal } from '../../../controls';

export const Title = ({ title }: { title: string }) => {
  return (
    <div style={{width: "100%", marginBottom: 15}}>
        <h1 style={{color: "#4fda3f", marginBottom: 10}}>{title}</h1>
        <hr style={{borderColor: "#4cda3f"}}/>
    </div>
  );
};

export const Subtitle = ({ title }: { title: string }) => {
    return (
      <div style={{width: "100%", marginBottom: 10}}>
          <h2 style={{color: "#4fda3f"}}>{title}</h2>
      </div>
    );
  };

  export const SubtitleSmaller = ({ title }: { title: string }) => {
    return (
      <div style={{width: "100%", marginBottom: 10}}>
          <h3 style={{color: "#4fda3f"}}>{title}</h3>
      </div>
    );
  };
