import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import './ModalPicture.scss';
import ImageViewer from '../ImageViewer/ImageViewer';

export const ModalPicture = (props: any) => {
  return (
    <Modal
      className="modalPicture"
      {...props}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box className="modalPicture__modalWrapper">
          {/* <button
            className="modalPicture__modalCloseBtn"
            onClick={props.onClose}
          ></button>
          <img src={props.src} id="modal" alt="modalPicture" className="modalPicture__modalImg" /> */}
          <ImageViewer image={props.src} closeImage={props.onClose}/>
        </Box>
      </Fade>
    </Modal>
  );
};
