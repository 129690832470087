import React, { FC, useEffect, useState, useRef, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
// import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import Loader from '../../../common/Loader/Loader';
import {
  Button,
  Checkbox,
  ContainedInput,
  Selection,
  UploadButton,
} from '../../../controls';
import { IconButton, TextField } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Delete as DeleteIcon } from "@mui/icons-material"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './CreateLoad.scss';
import Shipper from '../parts/Shipper';
import {
  equimpmentTypes,
  shipper_types,
  statuses,
  types,
} from '../../../../constants/main';
import Receiver from '../parts/Receiver';
import AutocompleteComponent from '../../../controls/AutocompleteSelection/Autocomplete';
import { useAppDispatch } from '../../../../hooks/global';
import { getCustomersList } from '../../../../store/customer/customer';
import { getIndependentDispatcherList } from '../../../../store/independentDispatcher/independentDispatcher';
import CreateCustomerModal from './parts/CreateCustomerModal';
import AsyncAutocompleteComponent from '../../../controls/AsyncAutocomplete.tsx/Autocomplete';
import {
  createLoad,
  getEquipmentTypes,
  getTrailersList,
  getTrucksList,
  searchFLorTitle,
} from '../../../../store/load/load';
import { getDriversList } from '../../../../store/driver/driver';
import CreateDriverModal from './parts/CreateDriverModal';
import CreateDispatcherModal from './parts/CreateDispatcherModal';
import GoogleAutocompleteComponent from '../../../controls/GoogleAutocomplete/Autocomplete';
import CreateTruckModal from './parts/CreateTruckModal';
import CreateTrailerModal from './parts/CreateTrailerModal copy';
import CreateEquipmentTypeModal from './parts/CreateEquipmentTypeModal';
import { ButtonComponent } from '../../../controls/Button/Button';
import CreateGuestDriverModal from './parts/CreateGuestDriverModal';
import DeleteOptionModal from './parts/DeleteOptionModal';
import { getTruckCompanyList } from '../../../../store/truckCompany/truckCompany';

interface ICreateLoad {
  blockAccess?: boolean;
}

const CreateLoad: FC<ICreateLoad> = ({ blockAccess }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const { handleSubmit, control } = useForm();
  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  let type_: 'tc' | 'fa' | 'id' | null = null;

  if (parsedData) {
    type_ =
      parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
        ? 'tc'
        : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
        ? 'fa'
        : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
        ? 'id'
        : null;
  }

  const [submitLoading, setSubmitLoading] = useState(false);
  const [birth, setBirth] = useState(null);
  const [value, setValue] = useState<any>(null);
  const [shippersCount, setShippersCount] = useState<number>(1);
  const [shippers, setShippers] = useState<any>([1]);
  const [selectedShipper, setSelectedShipper] = useState<number | string>(1);
  const [selectedShippers, setSelectedShippers] = useState<any>([]);
  const [selectedReceivers, setSelectedReceivers] = useState<any>([]);
  const [receiversCount, setReceiversCount] = useState<number>(1);
  const [receivers, setReceivers] = useState<any>([1]);
  const [selectedReceiver, setSelectedReceiver] = useState<number | string>(1);
  const [billToList, setBillToList] = useState<any>([]);
  const [tcList, setTcList] = useState<any>([]);
  const [independentDispatchersList, setIndependentDispatchersList] = useState<any>([]);
  const [customersList, setCustomersList] = useState<any>([]);
  const [driversList, setDriversList] = useState<any>([]);
  const [trucksList, setTrucksList] = useState<any>([]);
  const [trailersList, setTrailersList] = useState<any>([]);
  const [equipmentTypesList, setEquipmentTypesList] = useState<any>([]);
  const [createBillToModal, setCreateBillToModal] = useState<boolean>(false);
  const [createShipperModal, setCreateShipperModal] = useState<any>({
    open: false,
    customer_type: null,
    customer_id: null,
  });
  const [createGuestDriverModal, setCreateGuestDriverModal] = useState<boolean>(false);
  const [createDriverModal, setCreateDriverModal] = useState<boolean>(false);
  const [createTruckModal, setCreateTruckModal] = useState<boolean>(false);
  const [createEquipmentTypeModal, setCreateEquipmentTypeModal] =
    useState<boolean>(false);
  const [createTrailerModal, setCreateTrailerModal] = useState<boolean>(false);
  const [createReceiverModal, setCreateReceiverModal] = useState<any>({
    open: false,
    customer_type: null,
    customer_id: null,
  });
  const [hideRateConChecked, setHideRateConChecked] = useState<any>(true);
  const [createIDModal, setCreateIDModal] = useState<boolean>(false);
  const [sharingList, setSharingList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>(null);
  const [shippersUsers, setShippersUsers] = useState<any>([]);
  const [receiversUsers, setReceiversUsers] = useState<any>([]);

  useEffect(() => {
    if (blockAccess) {
      navigate('/loads');
      return;
    }
  }, []);

  const [editTruckModal, setEditTruckModal] = useState<boolean>(false);
  const [editDispatcherModal, setEditDispatcherModal] = useState<boolean>(false);
  const [editBillToModal, setEditBillToModal] = useState<boolean>(false);
  const [editDriverModal, setEditDriverModal] = useState<boolean>(false);
  const [editTrailerModal, setEditTrailerModal] = useState<boolean>(false);

  const [sharingList_, setSharingList_] = useState<any>([]);
  const [guestDriver, setGuestDriver] = useState<any>(null);
  const [truckId, setTruckId] = useState<any>(null);
  const [trailerId, setTrailerId] = useState<any>(null);
  const [rateCon, setRateCon] = useState<any>({
    file: null,
    title: '',
    delete: 0,
    fullpath: null,
  });

  const [type, setType] = useState<any>(null);
  const [equipmentType, setEquipmentType] = useState<any>(null);
  const [selectedBillTo, setSelectedBillTo] = useState<any>(null);
  const [selectedDriver, setSelectedDriver] = useState<any>(null);
  const [selectedDispatcher, setSelectedDispatcher] = useState<any>(null);
  const [selectedTC, setSelectedTC] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [optionForDelete, setOptionForDelete] = useState<any>({
    id: '',
    type: '',
  });
  const [editEquipmentTypeModal, setEditEquipmentTypeModal] = useState<boolean>(false);

  const filter = createFilterOptions<any>();

  useEffect(() => {
    if (type_ === 'tc') {
      setSelectedTC({
        id: id,
        value: parsedData?.company_name,
      })
    }
  }, [type_])

  const onHandleEditField = (type: string, option: any) => {
    if (type === 'equipmentType') {
      setEquipmentType(option);
      setEditEquipmentTypeModal(true);
    } else if (type === 'trailer') {
      setTrailerId(option);
      setEditTrailerModal(true);
    } else if (type === 'truck') {
      setTruckId(option);
      setEditTruckModal(true);
    } else if (type === 'driver') {
      setSelectedDriver(option);
      setEditDriverModal(true);
    } else if (type === 'dispatcher') {
      setSelectedDispatcher(option);
      setEditDispatcherModal(true);
    } else if (type === 'billTo') {
      setSelectedBillTo(option);
      setEditBillToModal(true);
    }
  };

  const onHandleDeleteField = (type: string, option: any) => {
    setOptionForDelete({
      id: option.id,
      type,
    });
    setDeleteModal(true);
  };

  useEffect(() => {
    if (guestDriver) {
      setSelectedDriver({
        id: 'guest_driver',
        value: guestDriver.name,
      });
    }
  }, [guestDriver]);

  useEffect(() => {
    if (selectedDriver) {
      if (selectedDriver.id !== 'guest_driver') {
        setGuestDriver(null);
      }
      setTruckId(selectedDriver?.truck);
      setTrailerId(selectedDriver?.trailer);
    }
  }, [selectedDriver]);

  useEffect(() => {
    const shippersArray: any = [];

    for (let i = 1; i <= shippersCount; i++) {
      shippersArray.push(i);
    }

    if (shippersCount > 1) {
      setShippersUsers([...shippersUsers, {
        index: shippersCount,
      }])
    } else {
      setShippersUsers([...shippersUsers, {
        index: 1,
      }])
    }

    setShippers(shippersArray);
  }, [shippersCount]);

  useEffect(() => {
    if (searchValue) {
      const result_: any = [];
      dispatch(searchFLorTitle(searchValue)).then((res: any) => {
        if (res.payload.errors.resource) {
          if (res.payload.errors.resource.length) {
            res.payload.errors.resource.map((result: any) => {
              result_.push({
                id: result.id,
                value: `${result.fl_number} | ${result.owner.title}`,
                fl_number: result.fl_number,
              });
            });

            setSharingList(result_);
          }
        }
      });
    }
  }, [searchValue]);

  useEffect(() => {
    const receiversArray: any = [];

    for (let i = 1; i <= receiversCount; i++) {
      receiversArray.push(i);
    }

    if (receiversCount > 1) {
      setReceiversUsers([...receiversUsers, {
        index: receiversCount,
      }])
    } else {
      setReceiversUsers([...receiversUsers, {
        index: 1,
      }])
    }

    setReceivers(receiversArray);
  }, [receiversCount]);

  useEffect(() => {
    if (selectedTC?.id) {
      const drivers: any = [];
      const equipmentTypes: any = [];
      const trucks: any = [];
      const trailers: any = [];

      const dataForDrivers: any = {
        id: selectedTC?.id,
        type: 'tc',
      };

      dispatch(getDriversList(dataForDrivers)).then(res => {
        if (res.type === 'getDriversList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((driver: any) => {
                let fullName = '';
                if (driver.profile.first_name && driver.profile.last_name) {
                  fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
                } else if (driver.profile.first_name && !driver.profile.last_name) {
                  fullName = driver.profile.first_name;
                } else if (!driver.profile.first_name && driver.profile.last_name) {
                  fullName = driver.profile.last_name;
                }

                drivers.push({
                  id: driver.id,
                  value: fullName,
                  trailer: {
                    id: driver.profile?.trailer_number_id,
                    value: driver.profile?.trailer_number,
                  },
                  truck: {
                    id: driver.profile?.truck_number_id,
                    value: driver.profile?.truck_number,
                  },
                });
              });

              setDriversList(drivers);
            } else {
              setDriversList([]);
            }
          }
        }
      });

      dispatch(getEquipmentTypes(dataForDrivers)).then(res => {
        if (res.type === 'getEquipmentTypes/fulfilled') {
          if (res.payload.resource.data.length) {
            res.payload.resource.data.map((type: any) => {
              equipmentTypes.push({ id: type.id, value: type.title });
            });

            setEquipmentTypesList(equipmentTypes);
          } else {
            setEquipmentTypesList([]);
          }
        }
      });

      dispatch(getTrucksList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrucksList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((truck: any) => {
                trucks.push({ id: truck.id, value: truck.number });
              });

              setTrucksList(trucks);
            } else {
              setTrucksList([]);
            }
          }
        }
      });

      dispatch(getTrailersList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrailersList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((trailer: any) => {
                trailers.push({ id: trailer.id, value: trailer.number });
              });

              setTrailersList(trailers);
            } else {
              setTrailersList([]);
            }
          }
        }
      });
    }
  }, [selectedTC])

  useEffect(() => {
    if (id) {
      const billTo: any = [];
      const customers: any = [];
      const drivers: any = [];
      const equipmentTypes: any = [];
      const trucks: any = [];
      const trailers: any = [];
      const indepDispatchers: any = [];

      const dataForGetListBillTo: any = {
        id,
        type: type_,
        customer_type: 'bill_to',
      };

      const dataForGetListCustomers: any = {
        id,
        type: type_,
        customer_type: 'customer',
      };

      const dataForDrivers: any = {
        id,
        type: type_,
      };

      dispatch(getTruckCompanyList()).then(res => {
        if (res.type === 'getTruckCompanyList/fulfilled') {
          if (res.payload.resource.data) {
            const usersArr: any = [];
            res.payload.resource.data.map((user: any) => {
              usersArr.push({
                id: user.id,
                value: user.title,
              });
            });

            setTcList(usersArr);
          }
        }
      });

      dispatch(getIndependentDispatcherList()).then(res => {
        if (res.type === 'getIndependentDispatcherList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((user: any) => {
                let fullName = '';
                if (user.user.profile.first_name && user.user.profile.last_name) {
                  fullName = `${user.user.profile.first_name} ${user.user.profile.last_name}`;
                } else if (user.user.profile.first_name && !user.user.profile.last_name) {
                  fullName = user.user.profile.first_name;
                } else if (!user.user.profile.first_name && user.user.profile.last_name) {
                  fullName = user.user.profile.last_name;
                }

                indepDispatchers.push({
                  id: user.id,
                  value: fullName.length ? fullName : user.title,
                  title: fullName.length ? fullName : user.title,
                });
              });

              setIndependentDispatchersList(indepDispatchers);
            }
          }
        }
      });

      dispatch(getCustomersList(dataForGetListBillTo)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                billTo.push({ id: customer.id, value: fullName });
              });

              setBillToList(billTo);
            }
          }
        }
      });

      dispatch(getCustomersList(dataForGetListCustomers)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                customers.push({
                  id: customer.id,
                  value: fullName,
                  address: customer.address.full_address ?? customer.address.address,
                });
              });

              setCustomersList(customers);
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (truckId) {
      if (truckId.id && !trucksList.find((truck: any) => truck.id === truckId?.id)) {
        setTrucksList([...trucksList, { id: truckId.id, value: truckId.number }]);
      }
    }
  }, [truckId]);

  useEffect(() => {
    if (equipmentType) {
      if (equipmentType.id) {
        setEquipmentTypesList([
          ...equipmentTypesList,
          { id: equipmentType.id, value: equipmentType.title },
        ]);
      }
    }
  }, [equipmentType]);

  useEffect(() => {
    if (trailerId) {
      if (trailerId.id && !trailersList.find((trailer: any) => trailer.id === trailerId?.id)) {
        setTrailersList([...trailersList, { id: trailerId.id, value: trailerId.number }]);
      }
    }
  }, [trailerId]);

  const onSubmit = async (data: any) => {
    const shippers: any = [];
    const receivers: any = [];

    const dataForCreate: any = {
      customer_id: selectedBillTo?.id || null,
      equipment_type_id: equipmentType?.id || null,
      // driver_id: selectedDriver?.id || null,
      truck_id: truckId?.id || null,
      trailer_id: trailerId?.id || null,
      sales_rep: data.sales_rep || null,
      wo: data.confirmation_number || null,
      load_type: type || null,
      rate: data.rate || null,
      rate_usd: data.rate_usd || null,
      fsc: '',
      flat_rate: data.rate_con,
      other_charges: data.other_charges,
      hide_rate_con: hideRateConChecked ? 1 : 0,
      instructions: data.instructions || null,
    };

    if (type_ === 'id') {
      dataForCreate.independent_dispatcher_id = id;
    } else {
      dataForCreate.independent_dispatcher_id = selectedDispatcher?.id || null;
    }

    if (type_ === 'tc') {
      dataForCreate.truck_company_id = id;
    } else {
      if (guestDriver && selectedTC?.id) {
        dataForCreate.truck_company_id = selectedTC?.id
      } else if (guestDriver && !selectedTC?.id) {
        //--
      } else {
        dataForCreate.truck_company_id = selectedTC?.id || null;
      }
    }

    if (data.shipper) {
      data.shipper.map((item: any, index: any) => {
        shippersUsers.map((user: any, i: number) => {
          if (user.index === index) {
            const dataForShipper: any = {
              index: i + 1,
              date:
                user.dateAndTime && user.dateAndTime !== 'Invalid date'
                  ? user.dateAndTime
                  : null,
              is_time_shown: 1,
              cargo_type: user?.cargo_type || null,
              qty: item?.qty || null,
              weight: item?.weight || null,
              value: item?.value || null,
              po_numbers: item?.po_numbers,
              custom_fa: item?.custom_fa || null,
              desc: item?.description || null,
              notes: item?.shipping_notes || null,
            };

            if (selectedShippers.length) {
              const findedId: any =
                selectedShippers.find((item: any) => item.index === user.index) || null;
              if (findedId) {
                dataForShipper.customer_id = findedId.customer_id;
              }
            }

            shippers.push(dataForShipper);
          }
        });
      });
    }

    if (data.receiver) {
      data.receiver.map((item: any, index: any) => {
        receiversUsers.map((user: any, i: number) => {
          if (user.index === index) {
            const dataForReceiver: any = {
              index: i + 1,
              date:
                user.dateAndTime && user.dateAndTime !== 'Invalid date'
                  ? user.dateAndTime
                  : null,
              is_time_shown: 1,
              cargo_type: user?.cargo_type || null,
              qty: item?.qty || null,
              weight: item?.weight || null,
              value: item?.value || null,
              po_numbers: item?.po_numbers,
              desc: item?.description || null,
              notes: item?.shipping_notes || null,
            };

            if (selectedReceivers.length) {
              const findedId: any =
                selectedReceivers.find((item: any) => item.index === user.index) || null;
              if (findedId) {
                dataForReceiver.customer_id = findedId.customer_id;
              }
            }
            receivers.push(dataForReceiver);
          }
        });
      });
    }

    dataForCreate.shippers = shippers;
    dataForCreate.receivers = receivers;
    dataForCreate.sharing = sharingList_.map((item: any) => item.fl_number);

    if (rateCon.file) {
      dataForCreate.rate_con = { file: rateCon.file };
    }

    if (guestDriver) {
      dataForCreate.guest_driver = guestDriver;
    } else {
      dataForCreate.driver_id = selectedDriver?.id || null;
    }

    dispatch(createLoad(dataForCreate)).then((res: any) => {
      if (res.type === 'createLoad/fulfilled') {
        navigate(-1);
      }
    });
  };

  const onDeleteSharing = (sharing: any) => {
    const notDeletedItems: any = [];
    sharingList_.map((item: any) => {
      if (item.fl_number !== sharing.fl_number) {
        notDeletedItems.push(item);
      }
    });

    setSharingList_(notDeletedItems);
  };

  const onDeleteCustomer = (id: string | number, type: string) => {
    if (id) {
      if (type === 'shipper') {
        const updatedShippers = shippersUsers.filter(
          (shipper: any) => shipper.index !== id,
        );
        setShippersUsers(updatedShippers);
        setShippersCount(updatedShippers.length);
        setShippers(shippers.filter((shipper: any) => shipper !== id));
        if (selectedShipper === id) {
          setSelectedShipper(1);
        }
      } else if (type === 'receiver') {
        const updatedReceivers = receiversUsers.filter(
          (receiver: any) => receiver.index !== id,
        );
        setReceiversUsers(updatedReceivers);
        setReceiversCount(updatedReceivers.length);
        setReceivers(receivers.filter((receiver: any) => receiver !== id));
        if (selectedReceiver === id) {
          setSelectedReceiver(1);
        }
      }
    }
  };

  useEffect(() => {}, [
    shippers,
    shippersUsers,
    receiversUsers,
    receivers,
    customersList,
  ]);

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer createLoadContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>New Active Load</h1>
                </div>

                <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() => navigate(-1)}
                  className="mapBtn"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="createLoadForm">
              <div className="sharingAndDriver">
                <div className="sharingContainer">
                  <AsyncAutocompleteComponent
                    items={sharingList}
                    label="Add FL# To connect TC/FA/ID"
                    setSearchValue={setSearchValue}
                    setSharingList={setSharingList_}
                    sharingList={sharingList_}
                    className="sharingInput"
                  />

                  <div className="selectedSharingContainer">
                    {sharingList_.map((number: any) => {
                      return (
                        <div key={number.value} className="selectedSharing">
                          {number.value}
                          <div
                            className="deleteSharing"
                            onClick={() => onDeleteSharing(number)}
                          >
                            Delete
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {guestDriver ? (
                  <div className="guestDriverInfo">
                    <div>{guestDriver.name}</div>
                    <div className="guestDriverEmail">
                      {guestDriver.email}{' '}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        className="deleteBtnAction"
                        onClick={() => {
                          setGuestDriver(null);
                          setSelectedDriver(null);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <ButtonComponent
                    title="+ADD GUEST DRIVER"
                    onClick={() => setCreateGuestDriverModal(true)}
                    variant="contained"
                    className="guestDriver-btn"
                  />
                )}
              </div>
              <div className="customerFormContainer">
                <div>
                  <h2 className="loadBlockTitle">Load Information</h2>

                  <div className="increasingBlocks">
                    <div className={'increasingBlock increasingBlockSelected'}>
                      Customer
                    </div>
                  </div>

                  <div className="customerForm">
                    <div className="general__line-1">
                      <div className="inputsContainer inputFlexOne">
                        <ContainedInput
                          label="Load Number"
                          className="marginRight inputFlexOne"
                          control={control}
                          id="load_number"
                          readOnly
                        />
                        <AutocompleteComponent
                          items={billToList}
                          label="Bill To"
                          labelId="billTo"
                          className="inputFlexOne"
                          setModalOpen={setCreateBillToModal}
                          setEditModalOpen={onHandleEditField}
                          setDeleteModalOpen={onHandleDeleteField}
                          setValueForInput={setSelectedBillTo}
                          defaultValue={selectedBillTo}
                          editable={true}
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne">
                        {type_ === 'id' && (
                          <ContainedInput
                            label="Dispatcher"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="dispatcher"
                            defaultValue={
                              parsedData
                                ? parsedData.first_name && parsedData.last_name
                                  ? `${parsedData.first_name} ${parsedData.last_name}`
                                  : parsedData.company_name
                                : null
                            }
                          />
                        )}
                        {type_ === 'fa' && blockAccess && (
                          <ContainedInput
                            label="Dispatcher"
                            className="marginRight inputFlexOne"
                            control={control}
                            readOnly
                          />
                        )}
                        {type_ !== 'id' && !blockAccess && (
                          <AutocompleteComponent
                            items={independentDispatchersList}
                            label="Dispatcher"
                            labelId="dispatcher"
                            setEditModalOpen={onHandleEditField}
                            setDeleteModalOpen={onHandleDeleteField}
                            className="inputFlexOne"
                            setModalOpen={setCreateIDModal}
                            setValueForInput={setSelectedDispatcher}
                            defaultValue={selectedDispatcher}
                            editable={true}
                          />
                        )}
                        <ContainedInput
                          label="Sales Rep"
                          className="marginRight inputFlexOne"
                          control={control}
                          id="sales_rep"
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne">
                        <ContainedInput
                          label="Status"
                          className="inputFlexOne marginRight"
                          control={control}
                          id="confirmation_number"
                          defaultValue={'Pending'}
                          readOnly
                        />
                        <ContainedInput
                          label="Confirmation #"
                          className="inputFlexOne"
                          control={control}
                          id="confirmation_number"
                        />
                      </div>
                    </div>

                    <div className="general__line-1">
                      <div className="inputsContainer inputFlexOne">
                        <Selection
                          label="Type"
                          className="marginRight customWidthState inputFlexOne"
                          items={types}
                          name="type"
                          setValue={setType}
                          defaultValue={type}
                        />
                        <ContainedInput
                          label="Rate"
                          className="marginRight inputFlexOne"
                          control={control}
                          id="rate"
                          type="price"
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne">
                        <ContainedInput
                          label="Other Charges"
                          className="marginRight inputFlexOne"
                          control={control}
                          id="other_charges"
                          type="price"
                        />
                        <ContainedInput
                          label="Rate USD $"
                          className="marginRight inputFlexOne"
                          control={control}
                          id="rate_usd"
                          type="price"
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne"></div>
                    </div>

                    <div className="general__line-1">
                      <div className="inputsContainer inputFlexOne">
                        <AutocompleteComponent
                          items={tcList}
                          label="Truck Company*"
                          labelId="truckCompany"
                          className="inputFlexOne"
                          // setModalOpen={setCreateDriverModal}
                          // setEditModalOpen={onHandleEditField}
                          // setDeleteModalOpen={onHandleDeleteField}
                          setValueForInput={setSelectedTC}
                          defaultValue={selectedTC}
                          // editable={true}
                          withoutAddNew={true}
                          disabled={type_ === 'tc' ? true : false}
                          control={control}
                        />
                        <AutocompleteComponent
                          items={driversList}
                          label="Driver"
                          labelId="driver"
                          className="inputFlexOne"
                          setModalOpen={setCreateDriverModal}
                          setEditModalOpen={onHandleEditField}
                          setDeleteModalOpen={onHandleDeleteField}
                          setValueForInput={setSelectedDriver}
                          defaultValue={selectedDriver}
                          editable={true}
                          control={control}
                          disabled={(!selectedTC?.id || guestDriver) ? true : false}
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne">
                        <AutocompleteComponent
                          items={equipmentTypesList}
                          label="Equipment Type"
                          labelId="equipmentType"
                          className="inputFlexOne marginRight"
                          setModalOpen={setCreateEquipmentTypeModal}
                          setEditModalOpen={onHandleEditField}
                          setDeleteModalOpen={onHandleDeleteField}
                          defaultValue={equipmentType}
                          setValueForInput={setEquipmentType}
                          editable={true}
                          control={control}
                          disabled={!selectedTC?.id ? true : false}
                        />
                        <AutocompleteComponent
                          items={trucksList}
                          label="Truck"
                          labelId="truck"
                          className="inputFlexOne"
                          setModalOpen={setCreateTruckModal}
                          setEditModalOpen={onHandleEditField}
                          setDeleteModalOpen={onHandleDeleteField}
                          defaultValue={truckId}
                          setValueForInput={setTruckId}
                          editable={true}
                          control={control}
                          disabled={!selectedTC?.id ? true : false}
                        />
                      </div>
                      <div className="inputsContainer inputFlexOne">
                        <AutocompleteComponent
                          items={trailersList}
                          label="Trailer"
                          labelId="trailer"
                          className="inputFlexOne"
                          setModalOpen={setCreateTrailerModal}
                          setEditModalOpen={onHandleEditField}
                          setDeleteModalOpen={onHandleDeleteField}
                          defaultValue={trailerId}
                          setValueForInput={setTrailerId}
                          editable={true}
                          control={control}
                          disabled={!selectedTC?.id ? true : false}
                        />
                        <ContainedInput
                          label="Flat Rate"
                          className="inputFlexOne"
                          control={control}
                          id="flat_rate"
                          type="price"
                        />
                      </div>
                    </div>

                    <div className="general__line-1">
                      <div className="inputsContainer inputFlexTwo">
                        <ContainedInput
                          label="Special Instructions and Confirmation"
                          className="marginRight inputFlexTwo"
                          control={control}
                          id="instructions"
                        />
                      </div>
                      <UploadButton
                        label="Rate Con"
                        className="inputFlexOne"
                        fileFullName={''}
                        changeValue={setRateCon}
                        value={rateCon}
                        anotherLabel={
                          <div className="label showTimeLabel labelRateCon">
                            Rate Con
                            <div>
                              <Checkbox setCheckedValue={setHideRateConChecked} /> Hide
                              the Rate Con from the Driver
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="shipperFormContainer">
                <h2 className="loadBlockTitle">Shipper Information</h2>

                <div className="increasingBlocks">
                  {shippers.map((shipper: any) => {
                    return (
                      <div
                        className={`increasingBlock ${
                          selectedShipper === shipper ? 'increasingBlockSelected' : ''
                        }`}
                        key={shipper}
                      >
                        <span onClick={() => setSelectedShipper(shipper)}>
                          Shipper {shipper}
                        </span>

                        {shipper !== 1 && (
                          <div
                            className="deleteCustomerBtn"
                            onClick={() => onDeleteCustomer(shipper, 'shipper')}
                          >
                            +
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className="addIncreasingBlockBtn"
                    // onClick={() => setReceiversCount(receiversCount + 1)}
                    onClick={() =>
                      setShippersCount(
                        shippersUsers[shippersUsers.length - 1]?.index + 1,
                      )
                    }
                  >
                    +
                  </div>
                </div>

                {shippers.map((shipper: any) => {
                  return (
                    <Shipper
                      control={control}
                      receiverId={shipper}
                      key={`shipper-${shipper}`}
                      isSelected={selectedShipper === shipper}
                      items={customersList}
                      setModalOpen={setCreateReceiverModal}
                      setReceiversUsers={setShippersUsers}
                      receiversUsers={shippersUsers}
                      setSelectedUsers={setSelectedShippers}
                      selectedUsers={selectedShippers}
                      usersList={customersList}
                      setUsersList={setCustomersList}
                    />
                  );
                })}
              </div>

              <div className="receiverFormContainer">
                <h2 className="loadBlockTitle">Receiver Information</h2>

                <div className="increasingBlocks">
                  {receivers.map((receiver: any) => {
                    return (
                      <div
                        className={`increasingBlock ${
                          selectedReceiver === receiver ? 'increasingBlockSelected' : ''
                        }`}
                        key={receiver}
                      >
                        <span onClick={() => setSelectedReceiver(receiver)}>
                          Receiver {receiver}
                        </span>

                        {receiver !== 1 && (
                          <div
                            className="deleteCustomerBtn"
                            onClick={() => onDeleteCustomer(receiver, 'receiver')}
                          >
                            +
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className="addIncreasingBlockBtn"
                    // onClick={() => setReceiversCount(receiversCount + 1)}
                    onClick={() =>
                      setReceiversCount(
                        receiversUsers[receiversUsers.length - 1]?.index + 1,
                      )
                    }
                  >
                    +
                  </div>
                </div>

                {receivers.map((receiver: any) => {
                  return (
                    <Receiver
                      control={control}
                      receiverId={receiver}
                      key={`shipper-${receiver}`}
                      isSelected={selectedReceiver === receiver}
                      items={customersList}
                      setModalOpen={setCreateReceiverModal}
                      setReceiversUsers={setReceiversUsers}
                      receiversUsers={receiversUsers}
                      setSelectedUsers={setSelectedReceivers}
                      selectedUsers={selectedReceivers}
                      usersList={customersList}
                      setUsersList={setCustomersList}
                    />
                  );
                })}
              </div>
            </div>
            <div className="buttonsContainer">
              <Button
                title="SAVE"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn saveLoadBtn"
                loading={submitLoading}
              />
            </div>

            {
              <>
                {createBillToModal && (
                  <CreateCustomerModal
                    open={createBillToModal}
                    setOpen={setCreateBillToModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={billToList}
                    setUsersList={setBillToList}
                    customerType="bill_to"
                    setSelectedCustomer={setSelectedBillTo}
                  />
                )}
                {editBillToModal && (
                  <CreateCustomerModal
                    open={editBillToModal}
                    setOpen={setEditBillToModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="UPDATE"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={billToList}
                    setUsersList={setBillToList}
                    customerType="bill_to"
                    setSelectedCustomer={setSelectedBillTo}
                    selectedCustomer={selectedBillTo}
                    editMode={true}
                  />
                )}

                {editDriverModal && (
                  <CreateDriverModal
                    open={editDriverModal}
                    setOpen={setEditDriverModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="UPDATE"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={driversList}
                    setUsersList={setDriversList}
                    selectedDriver={selectedDriver}
                    setSelectedDriver={setSelectedDriver}
                    editMode={true}
                    selectedTC={selectedTC}
                  />
                )}

                {editDispatcherModal && (
                  <CreateDispatcherModal
                    open={editDispatcherModal}
                    setOpen={setEditDispatcherModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="UPDATE"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={independentDispatchersList}
                    setUsersList={setIndependentDispatchersList}
                    setSelectedDispatcher={setSelectedDispatcher}
                    selectedDispatcher={selectedDispatcher}
                    editMode={true}
                  />
                )}
                {editTrailerModal && (
                  <CreateTrailerModal
                    open={editTrailerModal}
                    setOpen={setEditTrailerModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="EDIT"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={trailersList}
                    setUsersList={setTrailersList}
                    setTrailerId_={setTrailerId}
                    editMode={true}
                    trailerId_={trailerId}
                    selectedTC={selectedTC}
                  />
                )}
                {editEquipmentTypeModal && (
                  <CreateEquipmentTypeModal
                    open={editEquipmentTypeModal}
                    setOpen={setEditEquipmentTypeModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="EDIT"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={equipmentTypesList}
                    setUsersList={setEquipmentTypesList}
                    setEquipmentType={setEquipmentType}
                    editMode={true}
                    equipmentType={equipmentType}
                    selectedTC={selectedTC}
                  />
                )}
                {editTruckModal && (
                  <CreateTruckModal
                    open={editTruckModal}
                    setOpen={setEditTruckModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={trucksList}
                    setUsersList={setTrucksList}
                    setTruckId_={setTruckId}
                    editMode={true}
                    truckId_={truckId}
                    selectedTC={selectedTC}
                  />
                )}
                {createShipperModal.open && (
                  <CreateCustomerModal
                    open={createShipperModal}
                    setOpen={setCreateShipperModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={customersList}
                    setUsersList={setCustomersList}
                    customerType="shipper"
                  />
                )}
                {createReceiverModal.open && (
                  <CreateCustomerModal
                    open={createReceiverModal}
                    setOpen={setCreateReceiverModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={customersList}
                    setUsersList={setCustomersList}
                    customerType="receiver"
                  />
                )}
                {createDriverModal && (
                  <CreateDriverModal
                    open={createDriverModal}
                    setOpen={setCreateDriverModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={driversList}
                    setUsersList={setDriversList}
                    setSelectedDriver={setSelectedDriver}
                    selectedTC={selectedTC}
                  />
                )}
                {createGuestDriverModal && (
                  <CreateGuestDriverModal
                    open={createGuestDriverModal}
                    setOpen={setCreateGuestDriverModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    setGuestDriver={setGuestDriver}
                  />
                )}
                {createIDModal && (
                  <CreateDispatcherModal
                    open={createIDModal}
                    setOpen={setCreateIDModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={independentDispatchersList}
                    setUsersList={setIndependentDispatchersList}
                    setSelectedDispatcher={setSelectedDispatcher}
                  />
                )}

                {createEquipmentTypeModal && (
                  <CreateEquipmentTypeModal
                    open={createEquipmentTypeModal}
                    setOpen={setCreateEquipmentTypeModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={driversList}
                    setUsersList={setDriversList}
                    setEquipmentType={setEquipmentType}
                    selectedTC={selectedTC}
                  />
                )}
                {createTruckModal && (
                  <CreateTruckModal
                    open={createTruckModal}
                    setOpen={setCreateTruckModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={trucksList}
                    setUsersList={setTrucksList}
                    setTruckId_={setTruckId}
                    selectedTC={selectedTC}
                  />
                )}
                {createTrailerModal && (
                  <CreateTrailerModal
                    open={createTrailerModal}
                    setOpen={setCreateTrailerModal}
                    onChange={() => null}
                    onSubmit={() => null}
                    confirmTitle="ADD"
                    cancelTitle="CANCEL"
                    contentTitle=""
                    formType="create"
                    loading={submitLoading}
                    usersList={trailersList}
                    setUsersList={setTrailersList}
                    setTrailerId_={setTrailerId}
                    selectedTC={selectedTC}
                  />
                )}

                {deleteModal && optionForDelete && (
                  <DeleteOptionModal
                    open={deleteModal}
                    setOpen={setDeleteModal}
                    option={optionForDelete}
                    selectedTC={selectedTC}
                    selectedOption={
                      optionForDelete.type === 'equipmentType'
                        ? equipmentType
                        : optionForDelete.type === 'truck'
                        ? truckId
                        : optionForDelete.type === 'trailer'
                        ? trailerId
                        : optionForDelete.type === 'driver'
                        ? selectedDriver
                        : optionForDelete.type === 'dispatcher'
                        ? selectedDispatcher
                        : optionForDelete.type === 'billTo'
                        ? selectedBillTo
                        : null
                    }
                    setSelectedOption={
                      optionForDelete.type === 'equipmentType'
                        ? setEquipmentType
                        : optionForDelete.type === 'truck'
                        ? setTruckId
                        : optionForDelete.type === 'trailer'
                        ? setTrailerId
                        : optionForDelete.type === 'driver'
                        ? setSelectedDriver
                        : optionForDelete.type === 'dispatcher'
                        ? setSelectedDispatcher
                        : optionForDelete.type === 'billTo'
                        ? setSelectedBillTo
                        : null
                    }
                    optionsList={
                      optionForDelete.type === 'equipmentType'
                        ? equipmentTypesList
                        : optionForDelete.type === 'truck'
                        ? trucksList
                        : optionForDelete.type === 'trailer'
                        ? trailersList
                        : optionForDelete.type === 'driver'
                        ? driversList
                        : optionForDelete.type === 'dispatcher'
                        ? independentDispatchersList
                        : optionForDelete.type === 'billTo'
                        ? billToList
                        : null
                    }
                    setOptionsList={
                      optionForDelete.type === 'equipmentType'
                        ? setEquipmentTypesList
                        : optionForDelete.type === 'truck'
                        ? setTrucksList
                        : optionForDelete.type === 'trailer'
                        ? setTrailersList
                        : optionForDelete.type === 'driver'
                        ? setDriversList
                        : optionForDelete.type === 'dispatcher'
                        ? setIndependentDispatchersList
                        : optionForDelete.type === 'billTo'
                        ? setBillToList
                        : null
                    }
                  />
                )}
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CreateLoad);
