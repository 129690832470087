import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import {
  getNotification,
  getNotificateCounters,
  changeViewednotification,
} from '../../../../../store/notification/notification';
import {
  INotificate,
  IIncidentNotificate,
  INotificateItem,
  IIncidentNotificateItem,
} from '../../interfaces/interface';
import Pagination from '../../../../controls/Pagination/Pagination';
import './Dropdown.scss';
import { getFullName } from '../../../../../helpers';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';

interface Inotificate {
  title: string;
  shortTitle: string;
}

const Dropdown: FC<Inotificate> = ({ title, shortTitle }) => {
  const { data, loading, changednotificate } = useAppSelector(
    state => state.notification,
  );
  const [listnotification, setListnotification] = useState<
    INotificate | IIncidentNotificate | null
  >(null);
  const [listIds, setListIds] = useState<number[]>([]);
  const [amountPages, setAmountPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [isSendPost, setIsSendPost] = useState<boolean>(false);
  const [isPostSingleNaticatetion, setIsPostSingleNaticatetion] =
    useState<boolean>(false);
  const [singleSend, setSingleSend] = useState<number | null>();
  const [clickedLoad, setClickedLoad] = useState<number | null>();
  const [clickOnType, setClickOnType] = useState<boolean | null>(false);
  const [whatIsType, setWhatIsType] = useState<string>('');

  const isLoadedList =
    listnotification &&
    listnotification.data &&
    listnotification.data.resource &&
    listnotification.data.resource.data;

  const typeNotification =
    shortTitle === 'ETA'
      ? 'etas'
      : shortTitle === 'Delay'
      ? 'delays'
      : shortTitle === 'Report'
      ? 'reports'
      : shortTitle === 'Handoffs'
      ? 'handoffs'
      : '';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getList(page);
  }, [page]);

  const changePage = (page: number) => setPage(page);

  const getList = (page: number) => {
    dispatch(getNotification({ page, typeNotification }));
  };

  useEffect(() => {
    setListnotification(data);
    if (data) {
      data.data.resource.data.forEach((id: any) => {
        setListIds(listIds => (listIds = [...listIds, id.id]));
      });
      setAmountPages(data.data.resource.last_page ? data.data.resource.last_page : 1);
    }
  }, [data]);

  const arrayIds: { ids: number[] } = {
    ids: [],
  };

  const changeStatusnotificate = (id: number, parrent: number, see: number) => {
    setIsSendPost(true);
    setClickedLoad(parrent);
    setIsPostSingleNaticatetion(true);
    setSingleSend(id);
    arrayIds.ids = [id];
    if (see === 0) {
      dispatch(changeViewednotification({ arrayIds, typeNotification }));
    } else if (see > 0) {
      navigate(`/edit-load/${parrent}`);
    }
  };

  const goToDetailReport = (
    id: number,
    parrent: number | null,
    see: number | null,
    type: string,
  ) => {
    setClickOnType(true);
    setWhatIsType(type);
    setIsSendPost(true);
    setClickedLoad(parrent);
    setIsPostSingleNaticatetion(true);
    setSingleSend(id);
    arrayIds.ids = [id];
    if (see === 0) {
      dispatch(changeViewednotification({ arrayIds, typeNotification }));
    } else {
      switch (type.replace(/[^a-zа-яё]/gi, '')) {
        case 'AppModelsViolationReport':
          return navigate(`/violation-report/${parrent}`);
          break;
        case 'AppModelsDamageReport':
          return navigate(`/damage-reports/${parrent}`);
          break;
        case 'AppModelsAccidentReport':
          return navigate(`/accident-report/${parrent}`);
          break;
        default:
          return navigate(`/reports-list`);
      }
    }
  };

  const changeStatusnotificateForAll = () => {
    setIsSendPost(true);
    arrayIds.ids = listIds;
    dispatch(changeViewednotification({ arrayIds, typeNotification }));
  };

  useEffect(() => {
    if (
      changednotificate &&
      changednotificate.resource &&
      changednotificate.resource === true &&
      isSendPost &&
      isPostSingleNaticatetion
    ) {
      dispatch(getNotificateCounters());
      if (shortTitle === 'Report' && clickOnType && whatIsType !== '') {
        switch (whatIsType.replace(/[^a-zа-яё]/gi, '')) {
          case 'AppModelsViolationReport':
            return navigate(`/violation-report/${clickedLoad}`);
            break;
          case 'AppModelsDamageReport':
            return navigate(`/damage-reports/${clickedLoad}`);
            break;
          case 'AppModelsAccidentReport':
            return navigate(`/accident-report/${clickedLoad}`);
            break;
          default:
            return navigate(`/reports-list`);
        }
      } else {
        navigate(`/edit-load/${clickedLoad}`);
      }
    } else if (
      changednotificate &&
      changednotificate.resource &&
      changednotificate.resource === true &&
      isSendPost &&
      !isPostSingleNaticatetion
    ) {
      getList(1);
      setIsSendPost(false);
      dispatch(getNotificateCounters());
    }
  }, [changednotificate]);

  const skeleton = <div className="notificate__item _skeleton"></div>;

  return (
    <>
      <div className={`dropdown__arrow ${isSendPost ? 'send-true' : ''}`}></div>
      <div className={`dropdown__notificate ${isSendPost ? 'send-true' : ''}`}>
        <div className="notificate__head">
          <div className="notificate__title">{title}</div>
          <div
            onClick={() => changeStatusnotificateForAll()}
            className={`notificate__check-all ${loading ? 'disabled' : ''}`}
          >
            Mark all as read
          </div>
        </div>
        <div className="notificate__list">
          {isLoadedList && loading === false ? (
            <>
              {shortTitle === 'Report'
                ? listnotification.data.resource.data.map((item: any) => {
                    return (
                      <div
                        key={item.id}
                        className={`notificate__item ${
                          item?.is_seen === 0 ? '_active' : ''
                        }`}
                      >
                        <span>For Load </span>
                        <span
                          className="notificate__load"
                          onClick={() =>
                            changeStatusnotificate(item.id, item.load_id, item.is_seen)
                          }
                        >
                          #{item.load_number ? item.load_number : ' - '}
                        </span>
                        <span> was created</span>
                        <span
                          onClick={() =>
                            goToDetailReport(
                              item.id,
                              item.owner_id,
                              item.is_seen,
                              item.owner_type,
                            )
                          }
                          className="notificate__load report"
                        >
                          {item.type ? item.type : ' - '}
                        </span>
                      </div>
                    );
                  })
                : shortTitle === 'Handoffs'
                ? listnotification.data.resource.data.map((item: any) => {
                    // : shortTitle === 'Handoffs' ? testData.data.resource.data.map((item: any) => {

                    return (
                      <div
                        key={item.id}
                        className={`notificate__item ${
                          item?.is_seen === 0 ? '_active' : ''
                        }`}
                      >
                        <span className="driverName">
                          {getFullName(
                            item.driver.profile.first_name,
                            item.driver.profile.last_name,
                          )}
                        </span>
                        <span> has requested a hand off for the load</span>
                        <span
                          className="notificate__load"
                          onClick={() =>
                            changeStatusnotificate(
                              item.id,
                              item.parent_load.id,
                              item.is_seen,
                            )
                          }
                        >
                          #
                          {item.parent_load?.load_number
                            ? item.parent_load.load_number
                            : ' - '}
                        </span>
                      </div>
                    );
                  })
                : listnotification.data.resource.data.map((item: any) => {
                    return (
                      <div
                        key={item.id}
                        className={`notificate__item ${
                          item?.is_seen === 0 ? '_active' : ''
                        }`}
                      >
                        <span>For Load </span>
                        <span
                          className="notificate__load"
                          onClick={() =>
                            changeStatusnotificate(
                              item.id,
                              item.parent_load.id,
                              item.is_seen,
                            )
                          }
                        >
                          #
                          {item.parent_load?.load_number
                            ? item.parent_load.load_number
                            : ' - '}
                        </span>
                        <span> {shortTitle}</span>
                        <span>
                          was set as
                          {item.date ? (
                            <>
                              {' '}
                              <Moment format="MM/DD/YYYY">{item.date}</Moment> at{' '}
                              <Moment format="hh:mm A">{item.date}</Moment>
                            </>
                          ) : (
                            ' - '
                          )}
                        </span>

                        {shortTitle === 'Delay' && (
                          <div className="notesIconContainer">
                            <MarkChatUnreadIcon
                              className={`notesIcon ${
                                item.reason ? '' : 'hideNotesIcon'
                              }`}
                            />
                            <div className="notesReason">
                              <div>
                                Reason: <span>{item.reason}</span>
                              </div>
                              {item.notes && <div>
                                Notes: <span>{item.notes}</span>
                              </div>}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
            </>
          ) : (
            skeleton
          )}
        </div>
        {isLoadedList &&
        listnotification.data.resource?.total &&
        listnotification.data.resource?.total > 10 ? (
          <div className="pagination_notification">
            <Pagination
              pageNumber={page}
              amountPages={amountPages}
              changePage={changePage}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Dropdown;
