import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { createDriverData_ } from './testData';
import './UsersList.scss';
import { useAppDispatch } from '../../../../hooks/global';
import { getDriversList } from '../../../../store/driver/driver';
import { useNavigate } from 'react-router';
import Loader from '../../../common/Loader/Loader';
import { getMaskedPhone } from '../../../../helpers';

interface IDriversList {
  partialBlockAccess?: boolean;
}

const DriversList: FC<IDriversList> = ({ partialBlockAccess }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [drivers, setDrivers] = useState<any>(null);
  const [listLoading, setListLoading] = useState<boolean>(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  const getPhoneString = (
    firstPhone?: string | number,
    secondPhone?: string | number,
  ) => {
    if (firstPhone && secondPhone) {
      return `${firstPhone + '\xa0\xa0' + '|' + '\xa0\xa0' + secondPhone}`;
    } else if (firstPhone && !secondPhone) {
      return firstPhone;
    } else if (!firstPhone && secondPhone) {
      return secondPhone;
    } else {
      return '';
    }
  };

  useEffect(() => {
    const id = localStorage.getItem('owner_id');

    if (id) {
      setListLoading(true);
      const dataForGetList: any = {
        id,
        type:
          parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
            ? 'tc'
            : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
            ? 'fa'
            : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
            ? 'id'
            : null,
      };

      dispatch(getDriversList(dataForGetList)).then(res => {
        if (res.type === 'getDriversList/fulfilled') {
          if (res.payload.resource.data) {
            const driversArr: any = [];
            res?.payload?.resource?.data?.map((driver: any) => {
              let fullName = '';
              if (driver.profile.first_name && driver.profile.last_name) {
                fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
              } else if (driver.profile.first_name && !driver.profile.last_name) {
                fullName = driver.profile.first_name;
              } else if (!driver.profile.first_name && driver.profile.last_name) {
                fullName = driver.profile.last_name;
              }

              driversArr.push(
                createDriverData_(
                  fullName,
                  driver.company?.owner?.title ?? ' - ',
                  `${driver.email}`,
                  getPhoneString(driver.address.phone, driver.address.cell_phone),
                  driver.address.phone,
                  driver.address.cell_phone,
                  'Active',
                  driver.id,
                  id,
                  dataForGetList.type,
                  driver?.company?.owner_id,
                ),
              );
            });

            setDrivers(driversArr);
            setListLoading(false);
          }
        }
      });
    }
  }, []);

  const goToEdit = (driverId: number, id: string, type: string, ownerId: number | string) => {
    if (partialBlockAccess) {
      return null;
    } else {
      navigate('/edit-driver', { state: { id, driverId, type, ownerId } });
    }

    // navigate('/edit-driver', { state: { id, driverId, type } });
  };

  return (
    <div className="tableMain">
      <Loader loading={listLoading} />

      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell align="left">
                Full Name
              </StyledTableCell>
              <StyledTableCell align="left">
                Company Name
              </StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Contact Numbers</StyledTableCell>
              {/* <StyledTableCell align="left">
                Status{' '}
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                />
              </StyledTableCell> */}
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {drivers &&
              drivers.map((row: any, index: number) => (
                <StyledTableRow
                  key={`${row.email}-${index}`}
                  onClick={() => goToEdit(row.driverId, row.id, row.type, row.ownerId)}
                >
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.fullName}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="greyCell alignLeft">
                    {/* {parsedData.company_name} */}
                    {row.truckCompany}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="greenCell alignLeft">
                    {row.email}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="nowrapText">
                    {getPhoneString(
                      getMaskedPhone(row.contactNumber1),
                      getMaskedPhone(row.contactNumber2),
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="left" className="alignLeft">
                  {row.status}
                </StyledTableCell> */}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default DriversList;
