import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';
import { IReportsList } from '../../components/pages/ReportsList/interfaces/interface';
import FileSaver from 'file-saver';


export const getReportsList = createAsyncThunk('getReportsList', async(data : any) => {
  
  const reportType = data.reportType && data.reportType !== null ? `&type=${data.reportType}` : "";
  const startDate = data.dateFrom && data.dateFrom !== null && data.dateFrom !== null ? `&start_date=${data.dateFrom}` : "";
  const endDate = data.dateTo && data.dateTo !== null && data.dateTo !== null ? `&end_date=${data.dateTo}` : "";

  const response = await api.get(`reports/list?page=${data.page}&ipp=20&order_by=${data.sort}&order_dir=${data.sortDirection}${reportType}${startDate}${endDate}`);
  return response;
});

export const getReportAccident = createAsyncThunk('getReportAccident', async (data: any) => {
  const response = await api.get(`reports/accident-report/${data.id}`);
  return response.data;
});

export const getReportDamage = createAsyncThunk('getReportDamage', async (data: any) => {
  const response = await api.get(`reports/damage-report/${data.id}`);
  return response.data;
});

export const getTicketIncident = createAsyncThunk('getTicketIncident', async (data: any) => {
  const response = await api.get(`reports/violation-report/${data.id}`);
  return response.data;
});

export const getTicketIncidentPdf = createAsyncThunk('getTicketIncidentPdf', async (data: any) => {
  const response = await api.get(`reports/violation-report-pdf/${data.id}`, {responseType: "blob"});
  return response;
});

export const getReportDamagePdf = createAsyncThunk('getReportDamagePdf', async (data: any) => {
  const response = await api.get(`reports/damage-report-pdf/${data.id}`, {responseType: "blob"});
  return response;
});

export const getReportAccidentPdf = createAsyncThunk('getReportAccidentPdf', async (data: any) => {
  const response = await api.get(`reports/accident-report-pdf/${data.id}`, {responseType: "blob"});
  return response;
});

interface IInitialState{
  loading: boolean,
  reportsListState: IReportsList | null,
  exportLoading: boolean,
}

const initialState: IInitialState = {
  reportsListState: null,
  loading: false,
  exportLoading: false,
};

export const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getReportsList.pending, state => {
        state.loading = true;
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.reportsListState = action.payload;
        state.loading = false;
      })
      .addCase(getReportDamagePdf.pending, (state, action: any) => {
        state.exportLoading = true;
      })
      .addCase(getReportDamagePdf.fulfilled, (state, action: any) => {
        const info = new Blob([action.payload], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(info);
        FileSaver.saveAs(fileURL, "EquipmentLossFreightDamageReport.pdf");
        state.exportLoading = false;
      })
      .addCase(getTicketIncidentPdf.pending, (state, action: any) => {
        state.exportLoading = true;
      })
      .addCase(getTicketIncidentPdf.fulfilled, (state, action: any) => {
        const info = new Blob([action.payload], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(info);
        FileSaver.saveAs(fileURL, "TicketDotViolationReport.pdf");
        state.exportLoading = false;
      })
      .addCase(getReportAccidentPdf.pending, (state, action: any) => {
        state.exportLoading = true;
      })
      .addCase(getReportAccidentPdf.fulfilled, (state, action: any) => {
        const info = new Blob([action.payload], {type: 'application/pdf'});
        const fileURL = window.URL.createObjectURL(info);
        FileSaver.saveAs(fileURL, "AutoAccidentReport.pdf");
        state.exportLoading = false;
      })
      .addDefaultCase(() => {});
  },
});

export default reports.reducer;
