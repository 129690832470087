import React, { FC } from 'react';
import {
  Checkbox,
  ContainedInput,
  RadioButton,
  Selection,
  UploadPdfButton,
} from '../../../../../controls';
import GoogleAutocompleteComponent from '../../../../../controls/GoogleAutocomplete/Autocomplete';

interface IGeneral {
  generalOpened: boolean;
  control: any;
  generalInfo?: any;
  states: any;
  setAddressState: any;
  setBillingAddressState: any;
  setIsHazmat: any;
  isHazmat: any;
  setAddressComponents: any;
  zip?: any;
  state?: any;
  city?: any;
  setAddressMailingComponents: any;
  zipMailing: any;
  stateMailing: any;
  cityMailing: any;
  mailingAddress: any;
  address: any;
  customerType?: any;
}

const GeneralCustomer: FC<IGeneral> = ({
  generalOpened,
  control,
  generalInfo,
  states,
  setAddressState,
  setBillingAddressState,
  setIsHazmat,
  isHazmat,
  setAddressComponents,
  zip,
  state,
  city,
  setAddressMailingComponents,
  zipMailing,
  stateMailing,
  cityMailing,
  mailingAddress,
  address,
  customerType,
}) => {
  const getLabelName = () => {
    if (customerType === 'shipper') {
      return 'Shipper Name*'
    } else if (customerType === 'receiver') {
      return 'Receiver Name*'
    } else {
      return 'Customer Name*'
    }
  }

  return (
    <>
      {generalOpened && generalInfo?.id && (
        <>
          <div>
            <div className="general__line-1">
              <ContainedInput
                label={getLabelName()}
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.first_name}
                control={control}
                id="first_name"
              />
              <ContainedInput
                label="Phone"
                // defaultValue={generalInfo.carrierInfo}
                control={control}
                id="billingggg"
                className="inputFlexOne hiddenBlock"
                type="phone"
              />
              {/* <ContainedInput
                label="Last Name"
                defaultValue={generalInfo.last_name}
                control={control}
                id="last_name"
                className="inputFlexOne"
              /> */}
            </div>
            <div className="general__line-1">
              {/* <ContainedInput
                label="Address Line 1*"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.address.address}
                control={control}
                id="address_line"
              /> */}
              <GoogleAutocompleteComponent label="Address Line 1*" setAddressComponents={setAddressComponents} defaultValue={address}/>
              <ContainedInput
                label="Address Line 2"
                defaultValue={generalInfo.address.address2}
                control={control}
                id="address_line_second"
                className="inputFlexOne"
              />
            </div>
            <div className="general__line-1">
              {/* <Selection
                label="Country"
                className="marginRight inputFlexOne"
                items={states}
                setValue={setAddressState}
                // defaultValue={generalInfo.state}
              /> */}
              <ContainedInput
                label="City*"
                className="marginRight inputFlexOne"
                defaultValue={city}
                control={control}
                id="city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State*"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  setValue={setAddressState}
                  defaultValue={state}
                />
                <ContainedInput
                  label="ZIP Code*"
                  className="customWidthZip inputFlexOne"
                  defaultValue={zip}
                  control={control}
                  id="zip"
                />
              </div>
            </div>
            {/* <div className="checkboxContainer">
              <div className="label">Billing Address</div>
              <div className="customizedCheckbox">
                <Checkbox />

                <span>Same As Mailing Address</span>
              </div>
            </div> */}
            <div className="general__line-1">
              {/* <ContainedInput
                label="Billing Address 1"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.billing_address.address}
                control={control}
                id="billing_address"
              /> */}
              <GoogleAutocompleteComponent label="Billing Address 1" setAddressComponents={setAddressMailingComponents} defaultValue={mailingAddress}/>
              <ContainedInput
                label="Billing Address 2"
                defaultValue={generalInfo.billing_address.address2}
                control={control}
                id="billing_address_second"
                className="inputFlexOne"
              />
            </div>
            <div className="general__line-1">
              {/* <Selection
                label="Country"
                className="marginRight inputFlexOne"
                items={states}
                setValue={setAddressState}
                // defaultValue={generalInfo.state}
              /> */}
              <ContainedInput
                label="City"
                className="marginRight inputFlexOne"
                defaultValue={cityMailing}
                control={control}
                id="billing_city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  setValue={setBillingAddressState}
                  defaultValue={stateMailing}
                />
                <ContainedInput
                  label="ZIP Code"
                  className="customWidthZip inputFlexOne"
                  defaultValue={zipMailing}
                  control={control}
                  id="billing_zip"
                />
              </div>
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Phone"
                defaultValue={generalInfo.address.phone}
                control={control}
                id="phone"
                className="marginRight inputFlexOne"
                type="phone"
              />
              <ContainedInput
                label="Address Line 2"
                control={control}
                className="hiddenBlock inputFlexOne"
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Email"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.address.email}
                control={control}
                id="email"
              />
              <ContainedInput
                label="Fax"
                defaultValue={generalInfo.address.fax}
                control={control}
                id="fax"
                className="inputFlexOne"
                type="phone"
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Billing Email"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.billing_address.email}
                control={control}
                id="billing_email"
              />
              <ContainedInput
                label="Phone"
                defaultValue={generalInfo.billing_address.phone}
                control={control}
                id="billing_phone"
                className="inputFlexOne"
                type="phone"
              />
            </div>
            {/* <div className="general__line-1">
              <div className="inputsContainer inputFlexOne marginRight">
                <Selection
                  label="M.C. # / F.F. #"
                  className="marginRight inputFlexOne"
                  items={states}
                  setValue={setAddressState}
                  // defaultValue={generalInfo.state}
                />
                <ContainedInput
                  label="Address Line 2"
                  // defaultValue={generalInfo.carrierInfo}
                  control={control}
                  id="carrier_info"
                  className="hiddenBlock inputFlexOne"
                />
              </div>
              <div className="inputsContainer inputFlexOne marginRight">
                <ContainedInput
                  label="hidden label"
                  className="marginRight inputFlexOne hiddenLabel"
                  // defaultValue={generalInfo.officeFax}
                  control={control}
                  id="office_fax"
                  type="phone"
                />
                <span className="inputFlexOne fmcsa">FMCSA</span>
              </div>
              <ContainedInput
                label="URS #"
                // defaultValue={generalInfo.cellPhone}
                control={control}
                id="cell_phone"
                type="phone"
                className="inputFlexOne"
              />
            </div> */}
            {/* <div className="general__line-1">
              <div className="checkboxContainer inputFlexOne marginRight">
                <div className="label">Blacklisted</div>
                <div className="customizedCheckbox">
                  <Checkbox />

                  <span>This customer is blacklisted</span>
                </div>
              </div>
              <div className="checkboxContainer inputFlexOne marginRight">
                <div className="label">Is Broker</div>
                <div className="customizedCheckbox">
                  <Checkbox />

                  <span>This is a Broker</span>
                </div>
              </div>
              <div className="checkboxContainer inputFlexOne hiddenBlock"></div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default GeneralCustomer;
