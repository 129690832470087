import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import { createTrailer, createTruck, updateTrailer } from '../../../../../store/load/load';

interface IUserModal {
  open: any;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setLocation?: any;
  setTrailerId_?: any;
  editMode?: boolean;
  trailerId_?: any;
  selectedTC?: any;
}

const CreateTrailerModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setLocation,
  setTrailerId_,
  editMode,
  trailerId_,
  selectedTC,
}) => {
  const dispatch = useAppDispatch();
  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newTrailer, setNewTrailer] = useState<any>(null);

  const [trailerNumber, setTrailerNumber] = useState<any>(null);
  const [trailerId, setTrailerId] = useState<any>(null);

  useEffect(() => {
    if (trailerId_?.id) {
      setTrailerId(trailerId_.id);
      setTrailerNumber(trailerId_.value);
    }
  }, [trailerId_])

  const onSubmit_ = () => {
    if (selectedTC?.id) {
      const type =
        parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
          ? 'tc'
          : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
          ? 'fa'
          : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
          ? 'id'
          : null;

      const data = {
        type: 'tc',
        id: selectedTC?.id,
        number: trailerNumber,
      };

      const dataForUpdate = {
        type: 'tc',
        id: selectedTC?.id,
        number: trailerNumber,
        trailerId,
      };

      if (editMode) {
        dispatch(updateTrailer(dataForUpdate)).then((res: any) => {
          if (res.type === 'updateTrailer/fulfilled') {
            const filteredUsersList = usersList.filter((item: any) => item.id !== trailerId_.id);
            setUsersList([
              ...filteredUsersList,
              {
                id: trailerId_.id,
                value: trailerNumber,
              },
            ]);
            setOpen(false);
          }
        });
      } else {
        dispatch(createTrailer(data)).then((res: any) => {
          if (res.type === 'createTrailer/fulfilled') {
            setTrailerId(res.payload.data.resource.id);
            setUsersList([
              ...usersList,
              {
                id: res.payload.data.resource.id,
                value: trailerNumber,
              },
            ]);
          }
        });
      }
    }
  };

  useEffect(() => {
    setTrailerId_({
      id: trailerId,
      value: trailerNumber
    });

    if (trailerId && !editMode) {
      setOpen(false);
    }
  }, [trailerId, trailerNumber])

  const onChange_ = (e: any) => {
    setTrailerNumber(e.target.value);
  };

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={onSubmit_}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="label">Trailer Number</div>
          <input
            className="customizedInputContainer__input"
            defaultValue={trailerNumber}
            onChange={onChange_}
            style={{ width: '50%', marginBottom: '10px' }}
          />
        </div>
      }
    />
  );
};

export default CreateTrailerModal;
