import { TextField } from '@mui/material';
import React, { FC } from 'react';
import { ReactPanZoom } from './Ra';
import './ImageViewer.scss';

interface IImageViewer {
  image: string;
  closeImage: any;
}

const ImageViewer: FC<IImageViewer> = ({ image, closeImage }) => {
  return (
    <div className="imageViewerContainer">
      {/* <div
        style={{
          color: '#4fda41',
          fontWeight: 'bold',
          cursor: 'pointer',
          marginBottom: 10,
          padding: 7,
          backgroundColor: '#323030',
          zIndex: 1000,
        }}
        onClick={() => closeImage()}
      >
        <div className="popup-data-puck__close" style={{ position: 'initial', zIndex: 1000 }}></div>
      </div> */}
      <ReactPanZoom image={image} alt="test" close={() => closeImage()}/>
    </div>
  );
};

export default ImageViewer;