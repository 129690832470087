import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import './LoginAs.scss';
import { useAppDispatch } from '../../../hooks/global';
import { getTruckCompanyInfoFromAdmin } from '../../../store/truckCompany/truckCompany';
import { getFreightAgentInfoFromAdmin } from '../../../store/freightAgent/freightAgent';
import { getIndependentDispatcherInfoFromAdmin } from '../../../store/independentDispatcher/independentDispatcher';
import { getUser } from '../../../store/user/user';

interface ILoginAs {
  setHeaderHidden: any;
}

const LoginAs: FC<ILoginAs> = ({setHeaderHidden}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loginAsToken = localStorage.getItem('token');
    const entityType = localStorage.getItem('entity_type');

    if (!entityType) {
      window.location.reload();
    }

    if (loginAsToken && entityType) {
      dispatch(getUser()).then(res => {
        if (entityType === 'tc') {
          dispatch(getTruckCompanyInfoFromAdmin(res.payload.resource.entity.owner_id)).then((res: any) => {
            if (res.type === 'getTruckCompanyInfoFromAdmin/fulfilled') {
              setHeaderHidden(false);
              navigate('/dashboard')
            }
          });
        }

        if (entityType === 'fa') {
          dispatch(getFreightAgentInfoFromAdmin(res.payload.resource.entity.owner_id)).then((res: any) => {
            if (res.type === 'getFreightAgentInfoFromAdmin/fulfilled') {
              setHeaderHidden(false);
              navigate('/dashboard')
            }
          });
        }

        if (entityType === 'id') {
          dispatch(getIndependentDispatcherInfoFromAdmin(res.payload.resource.entity.owner_id)).then((res: any) => {
            if (res.type === 'getIndependentDispatcherInfoFromAdmin/fulfilled') {
              setHeaderHidden(false);
              navigate('/dashboard')
            }
          });
        }
      })
    }
  }, []);

  return (
    <div className="setPassword l-popup">
      <div className="setPassword__container">
        <img
          src={require('../../../assets/images/icons/reset-password.png')}
          alt="set-password"
          className="setPassword__icon"
        />

        <h1 className="setPassword__title">Login Process...</h1>
      </div>
    </div>
  );
};

export default LoginAs;
