import React, { FC, useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppDispatch } from '../../../../../../hooks/global';
import {
  getTrailersList,
  getTrucksList,
} from '../../../../../../store/load/load';
import { getSpecificLoad } from '../../../../../../store/specificLoad/specificLoad';
import { genders } from '../../../../../../constants/main';
import { ContainedInput, Selection } from '../../../../../controls';
import CreateTruckModal from '../../EditDriver/parts/CreateTruckModal';
import CreateTrailerModal from '../../EditDriver/parts/CreateTrailerModal copy';
import AutocompleteComponent from '../../../../../controls/AutocompleteSelection/Autocomplete';
import { getTruckCompanyInfo, getTruckCompanyList } from '../../../../../../store/truckCompany/truckCompany';

interface IGeneral {
  generalOpened: boolean;
  control: any;
  setGender: any;
  setBirth: any;
  birth: any;
  truckId: any;
  setTruckId: any;
  trailerId: any;
  setTrailerId: any;
  tcId: any;
  setTcId: any;
  tcForModal?: any;
}

const General: FC<IGeneral> = ({
  generalOpened,
  control,
  truckId,
  setTruckId,
  trailerId,
  setTrailerId,
  setGender,
  setBirth,
  birth,
  tcId,
  setTcId,
  tcForModal,
}) => {
  const id = localStorage.getItem('owner_id');
  const entityId = localStorage.getItem('entity_id');
  const userData = localStorage.getItem('user');
  const dispatch = useAppDispatch();
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  let type_: 'tc' | 'fa' | 'id' | null = null;

  if (parsedData) {
    type_ =
      parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
        ? 'tc'
        : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
        ? 'fa'
        : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
        ? 'id'
        : null;
  }
  const [trucksList, setTrucksList] = useState<any>([]);
  const [trailersList, setTrailersList] = useState<any>([]);
  const [loadInfo, setLoadInfo] = useState<any>(null);
  const [ownerId, setOwnerId] = useState<any>(null);
  const [readOnly, setReadOnly] = useState<any>(true);
  const [createTruckModal, setCreateTruckModal] = useState<boolean>(false);
  const [createTrailerModal, setCreateTrailerModal] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tcList, setTcList] = useState<any>([]);

  useEffect(() => {
    if (tcId?.id) {
      const trucks: any = [];
      const trailers: any = [];

      const dataForDrivers: any = {
        id: tcId?.id,
        type: 'tc',
      };

      dispatch(getTrucksList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrucksList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((truck: any) => {
                trucks.push({ id: truck.id, value: truck.number });
              });
              setTrucksList(trucks);
            }
          }
        }
      });

      dispatch(getTrailersList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrailersList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((trailer: any) => {
                trailers.push({ id: trailer.id, value: trailer.number });
              });
              setTrailersList(trailers);
            }
          }
        }
      });
    }
  }, [tcId])

  useEffect(() => {
    const id = localStorage.getItem('owner_id');
    
    if (id && type_) {
      dispatch(getTruckCompanyList()).then(res => {
        if (res.type === 'getTruckCompanyList/fulfilled') {
          if (res.payload.resource.data) {
            const usersArr: any = [];
            res.payload.resource.data.map((user: any) => {
              usersArr.push({
                id: user.id,
                value: user.title,
              });
            });

            setTcList(usersArr);
          }
        }
      });
    }
  }, []);

  return (
    <>
      {generalOpened && (
        <>
          <div className="general__line-1">
            <ContainedInput
              label="First Name*"
              className="marginRight"
              control={control}
              id="first_name"
            />
            <ContainedInput
              label="Last Name"
              className="marginRight"
              control={control}
              id="last_name"
            />
            <div className="marginRight birth">
              <div className="label">Date of Birth</div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={birth}
                  onChange={setBirth}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <Selection
              label="Gender"
              className="customWidthState"
              items={genders}
              setValue={setGender}
            />
          </div>
          <div className="general__line-1">
            <ContainedInput
              label="Email*"
              className="marginRight"
              control={control}
              id="email"
            />
            <ContainedInput
              label="Phone"
              className="marginRight"
              control={control}
              id="phone"
              type="phone"
            />
            <ContainedInput
              label="Cell Phone"
              control={control}
              id="cell_phone"
              type="phone"
            />
          </div>
          <div className="general__line-1">
          <AutocompleteComponent
              items={tcList}
              label="Truck Company*"
              defaultValue={tcId}
              // valueId={trailerId}
              className="marginRight"
              // disabled={readOnly}
              control={control}
              setValueForInput={setTcId}
              withoutAddNew={true}
              disabled={(tcForModal || type_ === 'tc') ? true : false}
            />
            <AutocompleteComponent
              items={trucksList}
              label="Truck Number"
              className="marginRight"
              setModalOpen={setCreateTruckModal}
              defaultValue={truckId}
              // valueId={truckId}
              control={control}
              setValueForInput={setTruckId}
              disabled={tcId?.id ? false : true}
            />
            <AutocompleteComponent
              items={trailersList}
              label="Trailer Number"
              className="noMarginRight"
              setModalOpen={setCreateTrailerModal}
              defaultValue={trailerId}
              // valueId={trailerId}
              control={control}
              setValueForInput={setTrailerId}
              disabled={tcId?.id ? false : true}
            />
            {createTruckModal && (
              <CreateTruckModal
                open={createTruckModal}
                setOpen={setCreateTruckModal}
                onChange={() => null}
                onSubmit={() => null}
                confirmTitle="ADD"
                cancelTitle="CANCEL"
                contentTitle=""
                formType="create"
                loading={submitLoading}
                usersList={trucksList}
                setUsersList={setTrucksList}
                setTruckId_={setTruckId}
                ownerId={tcId?.id}
              />
            )}

            {createTrailerModal && (
              <CreateTrailerModal
                open={createTrailerModal}
                setOpen={setCreateTrailerModal}
                onChange={() => null}
                onSubmit={() => null}
                confirmTitle="ADD"
                cancelTitle="CANCEL"
                contentTitle=""
                formType="create"
                loading={submitLoading}
                usersList={trailersList}
                setUsersList={setTrailersList}
                setTrailerId_={setTrailerId}
                ownerId={tcId?.id}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default General;
