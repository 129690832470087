import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import { getSpecificLoad } from '../../../../../store/specificLoad/specificLoad';
import { displayProtectedImage, displayImageSrc, getFullName } from '../../../../../helpers';
import './DataPack.scss';
import { manageFilesForCustomer } from '../../../../../store/load/load';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import { IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DeleteOptionModal from './DeleteOptionModal';
import SelectCustomerModal from './SelectCustomerModal';
import Loader from '../../../../common/Loader/Loader';
import ImageViewer from '../../../../controls/ImageViewer/ImageViewer';
import { Modal } from '../../../../controls';
import PdfView from '../../../../controls/NewPdfViewer/PdfViewer';

export function DataPack({ changedataPack, dataPack, loadId, typeMediaOpen }: any) {
  const { load, loading } = useAppSelector(state => state.specificLoad);
  const { manageFileLoading } = useAppSelector(state => state.load);
  const [typeOpenMedia, setTypeOpenMedia] = useState<string>("");
  const [shippersData, setShippersData] = useState<any>([]);
  const [receiversData, setReceiversData] = useState<any>([]);
  const [dataForDelete, setDataForDelete] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [bolsData, setBolsData] = useState<any>([]);
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [customersList, setCustomersList] = useState<any>([]);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingImages, setLoadingImages] = useState<any>([]);
  const [loadingShippersImages, setLoadingShippersImages] = useState<any>([]);
  const [loadingReceiversImages, setLoadingReceiversImages] = useState<any>([]);
  const [shippersPics, setShippersPics] = useState<any>([]);
  const [receiversPics, setReceiversPics] = useState<any>([]);
  const [imageTest, setImageTest] = useState<any>(null);
  const [pdf, setPdf] = useState<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadId) dispatch(getSpecificLoad(loadId));
  }, []);

  useEffect(() => {
  }, [pdf])

  useEffect(() => {
    setLoadingProcess(manageFileLoading);
  }, [manageFileLoading])

  const getFiles = async (list: any, setValue: any) => {
    const files: any = [];
    await list?.map((item: any) => {
      if (item?.freight_pictures && item?.freight_pictures?.length) {
        item?.freight_pictures?.map((picture: any) => {
          files.push(picture);
        })
      }
    })
    setValue(files);
  }

  useEffect(() => {
    if (load?.data?.resource?.receivers && load?.data?.resource?.receivers?.length > 0) {
      setReceiversData(load?.data?.resource?.receivers)
      getFiles(load?.data?.resource?.receivers, setReceiversPics);
    }
    if (load?.data?.resource?.shippers && load?.data?.resource?.shippers?.length > 0) {
      setShippersData(load?.data?.resource?.shippers)
      getFiles(load?.data?.resource?.shippers, setShippersPics);
    }

    if (
      load?.data?.resource?.bols_scans &&
      load?.data?.resource?.bols_scans?.length > 0
    ) {
      setBolsData(load?.data?.resource?.bols_scans);
    }
  }, [load]);

  const handleClickOpen = () => {
    changedataPack(true);
  };

  const handleClose = () => {
    changedataPack(false);
    const root: HTMLElement | null = document.querySelector('html');
    root ? root.style.overflow = 'initial' : null;
    root ? root.style.paddingRight = '0px': null;
  };

  const skeletoon = (
    <div className="data-pack__row">
      <div className="data-pack__title">&nbsp;</div>
      <div className="data-pack__column">
        <div className="data-pack_item"></div>
        <div className="data-pack_item"></div>
        <div className="data-pack_item"></div>
        <div className="data-pack_item"></div>
      </div>
    </div>
  );

  useEffect(() => {
    const receivers: any = [];
    const shippers: any = [];

    if (
      load &&
      load.data &&
      load.data.resource &&
      load.data.resource.receivers &&
      load.data.resource.receivers.length > 0
    ) {
      load.data.resource.receivers.forEach((slice: any) => {
        receivers.push({
          value: slice.id,
          label: `${getFullName(
            slice.customer?.first_name,
            slice.customer?.last_name,
          )} (receiver)`,
          id: slice.id,
          type: 'receiver',
        });
        if (slice.bols_scans && slice.bols_scans.length > 0 && typeMediaOpen === "bol-scan")  {
          slice.bols_scans.forEach((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
        if (slice.freight_pictures && slice.freight_pictures.length > 0 && typeMediaOpen === "data-pack") {
          slice.freight_pictures.map((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
      });
    }

    if (
      load &&
      load.data &&
      load.data.resource &&
      load.data.resource.shippers &&
      load.data.resource.shippers.length > 0
    ) {
      load.data.resource.shippers.forEach((slice: any) => {
        shippers.push({
          value: slice.id,
          label: `${getFullName(
            slice.customer?.first_name,
            slice.customer?.last_name,
          )} (shipper)`,
          id: slice.id,
          type: 'shipper',
        });
        if (slice.bols_scans && slice.bols_scans.length > 0 && typeMediaOpen === "bol-scan") {
          slice.bols_scans.forEach((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
        if (slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack") {
          slice.freight_pictures.map((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
      });
    }

    setCustomersList([...shippers, ...receivers]);
  }, [load]);

  const loadImages = async (bols: any, setValue: any) => {
    const images = [];
    if (bols.length) {
      for (const bol of bols) {
        if (typeMediaOpen === 'bol-scan') {
          await displayProtectedImage(bol.id, bol.thumbnail, token);
          images.push(bol.id);
        }
      }
    }
    setValue(images);
  };

  // useEffect(() => {
  //   shippersData.forEach((slice: any) => {
  //     if (slice.bols_scans && slice.bols_scans.length > 0 && typeMediaOpen === "bol-scan") {
  //       slice.bols_scans.forEach((value: any) => {
  //         displayProtectedImage(value.id, value.thumbnail, token);
  //       });
  //     }
  //     if (slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack") {
  //       slice.freight_pictures.map((value: any) => {
  //         displayProtectedImage(value.id, value.thumbnail, token);
  //       });
  //     }
  //   });
  // }, [shippersData])

  // useEffect(() => {
  //   receiversData.forEach((slice: any) => {
  //     if (slice.bols_scans && slice.bols_scans.length > 0 && typeMediaOpen === "bol-scan") {
  //       slice.bols_scans.forEach((value: any) => {
  //         displayProtectedImage(value.id, value.thumbnail, token);
  //       });
  //     }
  //     if (slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack") {
  //       slice.freight_pictures.map((value: any) => {
  //         displayProtectedImage(value.id, value.thumbnail, token);
  //       });
  //     }
  //   });
  // }, [receiversData])


  // useEffect(() => {
  //   if (bolsData.length) {
  //     bolsData.forEach((bol: any) => {
  //       if (typeMediaOpen === 'bol-scan') {
  //         displayProtectedImage(bol.id, bol.thumbnail, token);
  //       }
  //     });
  //   }
  // }, [bolsData]);

  // useEffect(() => {
  //   loadImages(shippersData, setLoadingShippersImages);
  // }, [shippersData])

  // useEffect(() => {
  //   loadImages(receiversData, setLoadingReceiversImages);
  // }, [receiversData])

  // const loadImages_ = async (data: any, setValue: any) => {
  //   const images: any = [];
  //   if (data.length) {
  //     data.forEach((slice: any) => {
  //       if (slice.freight_pictures && slice.freight_pictures.length > 0 && typeMediaOpen === "data-pack") {
  //         slice.freight_pictures.map(async (value: any) => {
  //           console.log('here')
  //           await displayProtectedImage(value.id, value.thumbnail, token);
  //           images.push(value.id);
  //         });
  //       }
  //     });
  //   }
  //   setValue(images);
  // };

  const loadImages_ = async (pics: any, setValue: any) => {
    const images = [];
    if (pics.length) {
      for (const pic of pics) {
        if (typeMediaOpen === 'data-pack') {
          await displayProtectedImage(pic.id, pic.thumbnail, token);
          images.push(pic.id);
        }
      }
    }
    setValue(images);
  };

  useEffect(() => {
    console.log(shippersPics, 'shippersPics')
    loadImages_(shippersPics, setLoadingShippersImages);
  }, [shippersPics])
  
  useEffect(() => {
    console.log(receiversPics, 'receiversPics')
    loadImages_(receiversPics, setLoadingReceiversImages);
  }, [receiversPics]);

  useEffect(() => {
    loadImages(bolsData, setLoadingImages);
  }, [bolsData]);

  useEffect(() => {
    console.log(loadingShippersImages, 'loadingShippersImages')
  }, [loadingShippersImages])

  useEffect(() => {
    console.log(loadingReceiversImages, 'loadingReceiversImages')
  }, [loadingReceiversImages])

  const onAddNewFile = (customerId: number, fileType: string, e: any, customerType: string) => {
    setLoadingProcess(true);
    setCustomerModal(false);
    if (e && e.target && e.target.files) {
      const fd = new FormData();
      fd.append('file', e.target.files[0]);
      dispatch(fetchUploadFile(fd)).then((res: any) => {
        if (res.type === 'uploadFile/fulfilled') {
            dispatch(manageFilesForCustomer({
              loadId: load.data.resource.id,
              customerId,
              fileType,
              action: 'create',
              file: res.payload.file.filepath,
            })).then((result) => {
              if (customerType === 'shipper') {
                dispatch(getSpecificLoad(loadId));
              } else if (customerType === 'receiver') {
                dispatch(getSpecificLoad(loadId));
              } else {
                dispatch(getSpecificLoad(loadId));
              }
            })
        }
      });
    }
  };

  const onHandleOpenDeleteModal = (customerId: number, fileType: string, customerType: string | null, fileId: number) => {
    setDataForDelete({
      customerId,
      fileType,
      id: fileId,
      customerType,
    });
    setDeleteModal(true);
  }

  const onDeleteFile = () => {
    setDeleteModal(false);
    dispatch(manageFilesForCustomer({
      loadId: load.data.resource.id,
      customerId: dataForDelete.customerId,
      fileType: dataForDelete.fileType,
      action: 'delete',
      id: dataForDelete.id,
    })).then((result) => {
      if (dataForDelete.customerType === 'shipper') {
        dispatch(getSpecificLoad(loadId));
        setDataForDelete(null);
      } else if (dataForDelete.customerType === 'receiver') {
        dispatch(getSpecificLoad(loadId));
        setDataForDelete(null);
      } else {
        setDataForDelete(null);
        dispatch(getSpecificLoad(loadId));
      }
    })
  }

  const token = localStorage.getItem('token');

  const rowReceivers =
  receiversData.length > 0
    ? receiversData.map((slice: any) => {
        let count = 0;
        if (slice.bols_scans && slice.bols_scans.length > 0  && typeMediaOpen === "bol-scan") {
          count = count + slice.bols_scans.length;
        } else if (slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack") {
          count = count + slice.freight_pictures.length;
        }

        return (
          <div
            key={slice.index}
            className={`data-pack__row ${count === 0 ? 'none' : ''}`}
          >
            <div className="data-pack__title">
              {slice.type
                ? slice.type === 'receiver'
                  ? 'Delivery'
                  : slice.type
                : ' - '}{' '}
              {slice.index ? slice.index : ' - '}
            </div>
            <div className="data-pack__column">
              {slice.bols_scans && slice.bols_scans.length > 0  && typeMediaOpen === "bol-scan"
                ? slice.bols_scans.map((value: any) => {
                    return (
                      <>
                      <div
                        key={value.id}
                        id={value.id}
                        className="data-pack_item"
                        onClick={e => setImage(value.file, value?.ext ? value.ext : "pdf")}
                      >
                        <div className="spinner" style={{display: loadingReceiversImages.find(((id: any) => id === value.id)) ? "none" : "block"}}>
                          <div />
                          <div />
                        </div>
                        <span onClick={() => onHandleOpenDeleteModal(slice.id, 'bols_scan', 'receiver', value.id)} className="deleteBtn">+</span></div>
                      </>
                    );
                  })
                : ''}
                {typeMediaOpen === "bol-scan" && <div
                    className="data-pack_item uploadFileBtn"
                >
                  <div>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input type="file" onChange={(e) => onAddNewFile(slice.id, 'bols_scan', e, 'receiver')} id="file-input" hidden/>
                    <CameraAltOutlinedIcon />
                  </IconButton>
                  </div>
                </div>}
              {slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack"
                ? slice.freight_pictures.map((value: any) => {
                    return (
                      <div
                        key={value.id}
                        id={value.id}
                        className="data-pack_item"
                        onClick={e => setImage(value.file, value?.ext ? value.ext : "jpg")}
                      >
                        <div className="spinner" style={{display: loadingReceiversImages.find(((id: any) => id === value.id)) ? "none" : "block"}}>
                          <div />
                          <div />
                        </div>
                        <span onClick={() => onHandleOpenDeleteModal(slice.id, 'freight_picture', 'receiver', value.id)} className="deleteBtn">+</span>
                      </div>
                    );
                  })
                : ''}

                {typeMediaOpen === "data-pack" && <div
                    className="data-pack_item uploadFileBtn"
                >
                  <div>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input type="file" onChange={(e) => onAddNewFile(slice.id, 'freight_picture', e, 'receiver')} id="file-input" hidden/>
                    <CameraAltOutlinedIcon />
                  </IconButton>
                  </div>
                </div>}
            </div>
          </div>
        );
      })
    : skeletoon;

    const rowShippers =
    shippersData.length > 0
      ? shippersData.map((slice: any) => {
          let count = 0;
          if (slice.bols_scans && slice.bols_scans.length > 0  && typeMediaOpen === "bol-scan") {
            count = count + slice.bols_scans.length;
          } else if (slice.freight_pictures && slice.freight_pictures.length > 0 && typeMediaOpen === "data-pack") {
            count = count + slice.freight_pictures.length;
          }

          return (
            <div
              key={slice.index}
              className={`data-pack__row ${count === 0 ? 'none' : ''}`}
            >
              <div className="data-pack__title">
                {slice.type ? (slice.type === 'shipper' ? 'Pickup' : slice.type) : ' - '}{' '}
                {slice.index ? slice.index : ' - '}
              </div>
              <div className="data-pack__column">
                {slice.bols_scans && slice.bols_scans.length > 0  && typeMediaOpen === "bol-scan"
                  ? slice.bols_scans.map((value: any) => {
                      return (
                        <div
                          key={value.id}
                          id={value.id}
                          className="data-pack_item"
                          onClick={e => setImage(value.file, value?.ext ? value.ext : "pdf")}
                        >
                        <div className="spinner" style={{display: loadingShippersImages.find(((id: any) => id === value.id)) ? "none" : "block"}}>
                          <div />
                          <div />
                        </div>
                          <span onClick={() => onHandleOpenDeleteModal(slice.id, 'bols_scan', 'shipper', value.id)} className="deleteBtn">+</span>
                        </div>
                      );
                    })
                  : ''}
                  {typeMediaOpen === "bol-scan" && <div className="data-pack_item uploadFileBtn">
                    <div>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                      <input type="file" onChange={(e) => onAddNewFile(slice.id, 'bols_scan', e, 'shipper')} id="file-input" hidden/>
                      <CameraAltOutlinedIcon />
                    </IconButton>
                    </div>
                  </div>}
                {slice.freight_pictures && slice.freight_pictures.length > 0  && typeMediaOpen === "data-pack"
                  ? slice.freight_pictures.map((value: any) => {
                      return (
                        <div
                          key={value.id}
                          id={value.id}
                          className="data-pack_item"
                          onClick={e => setImage(value.file, value?.ext ? value.ext : "jpg")}
                        >
                        <div className="spinner" style={{display: loadingShippersImages.find(((id: any) => id === value.id)) ? "none" : "block"}}>
                          <div />
                          <div />
                        </div>
                          <span onClick={() => onHandleOpenDeleteModal(slice.id, 'freight_picture', 'shipper', value.id)} className="deleteBtn">+</span>
                        </div>
                      );
                    })
                  : ''}

                {typeMediaOpen === "data-pack" && <div
                    className="data-pack_item uploadFileBtn"
                >
                  <div>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input type="file" onChange={(e) => onAddNewFile(slice.id, 'freight_picture', e, 'shipper')} id="file-input" hidden/>
                    <CameraAltOutlinedIcon />
                  </IconButton>
                  </div>
                </div>}
              </div>
            </div>
          );
        })
      : skeletoon;


      const count = bolsData.length;

      const rowAllBols = (
        <div className={`data-pack__row ${count === 0 ? 'none' : ''}`}>
          <div className="data-pack__title">SIGNED BOL</div>
          <div className="data-pack__column">
            {bolsData.length > 0
              ? bolsData.map((bol: any) => {
                  return (
                    <>
                      <div
                        id={bol.id}
                        className="data-pack_item"
                        onClick={e => setImage(bol.file, bol?.ext ? bol.ext : 'pdf')}
                      >
                        <div className="spinner" style={{display: loadingImages.find(((id: any) => id === bol.id)) ? "none" : "block"}}>
                          <div />
                          <div />
                        </div>
                        <span
                          onClick={() => {
                            onHandleOpenDeleteModal(
                              bol.load_participant_id,
                              'bols_scan',
                              null,
                              bol.id,
                            );
                          }}
                          className="deleteBtn"
                        >
                          +
                        </span>
                      </div>
                    </>
                  );
                })
              : ''}
            {typeMediaOpen === 'bol-scan' && (
              <div className="data-pack_item uploadFileBtn">
                <div>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => setCustomerModal(true)}
                  >
                    {/* <input
                                  type="file"
                                  onChange={e => onAddNewFile(3, 'bols_scan', e, 'receiver')}
                                  id="file-input"
                                  hidden
                                /> */}
                    <CameraAltOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    

  const setImage = (file: string, type: string) => {
    setTypeOpenMedia(type === "pdf" ? type : "jpg");
    setSingleImage();
    displayImageSrc('place-image', file, token, type).then(res => {
      type !== "pdf" ? setImageTest(res) : setImageTest(null)

      if (type === "pdf") {
        setImageTest(null);
        setPdf(res);
      } else {
        setImageTest(res);
        setPdf(null);
      }
    })
  };
  const refContainer = useRef<HTMLDivElement>(null);

  const setSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '100000';
      refContainer.current.style.opacity = '1';
      refContainer.current.style.pointerEvents = 'initial';
    }
  };

  const sdeleteSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '-10000';
      refContainer.current.style.opacity = '0';
      refContainer.current.style.pointerEvents = 'none';
      const imageElement: HTMLElement | null = document.getElementById('place-image');
      if (imageElement) {
        imageElement.setAttribute('src', '');
      }
    }
  };

  const closeSingleImage = (event: any) => {
    
    if (
      !event.target.classList.contains('data-pack_item') &&
      !event.target.classList.contains('image-data-puck')
    ) {
      sdeleteSingleImage();
    }
  };

  return (
    <>
    <Loader loading={loadingProcess}/>
    <div
      // onClick={event => closeSingleImage(event)}
      className={`popup-data-puck ${dataPack ? '_active' : ''}`}
    >
      <div ref={refContainer} className="singleWrap">
        {/* <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
       {typeOpenMedia === "pdf" ? <><iframe id="place-image" frameBorder="0" className="bols-data-puck">Your browser does not support frames</iframe><div onClick={event => closeSingleImage(event)} className="popup-data-puck__close closeBtnCustom"></div></>
       : <><img id="place-image" className="image-data-puck" /> <div onClick={event => closeSingleImage(event)} className="popup-data-puck__close closeBtnCustom"></div></>
        } */}
      {(typeOpenMedia === "pdf") ? <>{pdf && <PdfView url={pdf} close={() => sdeleteSingleImage()}/>}</>
      : <ImageViewer image={imageTest} closeImage={() => sdeleteSingleImage()}/>
      }  
      </div>
      <div className="popup-data-puck__wrap">
        <div onClick={handleClose} className="popup-data-puck__close"></div>
        <div className="popup-data-puck__title">{typeMediaOpen === "data-pack" ? "data pack" :  typeMediaOpen === "bol-scan" ? "bol scan" : " - "}</div>
        <div className="data-pack__container">
          {loading ? (
            skeletoon
          ) : (
            <>
            {typeMediaOpen === 'bol-scan'
             ? rowAllBols
            : <>
              {rowReceivers}
              {rowShippers}
              </>}
            </>
          )}
        </div>
      </div>

      {deleteModal && (
                    <DeleteOptionModal
                      open={deleteModal}
                      setOpen={setDeleteModal}
                      onSubmit={onDeleteFile} /> )}

      {customerModal && (
        <SelectCustomerModal
          open={customerModal}
          setOpen={setCustomerModal}
          customers={customersList}
          addFileHandler={onAddNewFile}
        />
      )}
    </div>
    </>
  );
}
