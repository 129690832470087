export function createDriverData(fullName, truckCompany, email, contactNumbers, status) {
  return {
    fullName,
    truckCompany,
    email,
    contactNumbers,
    status,
  };
}

export function createDriverData_(
  fullName,
  truckCompany,
  email,
  contactNumbers,
  contactNumber1,
  contactNumber2,
  status,
  driverId,
  id,
  type,
  ownerId,
) {
  return {
    fullName,
    truckCompany,
    email,
    contactNumbers,
    contactNumber1,
    contactNumber2,
    status,
    driverId,
    id,
    type,
    ownerId,
  };
}

export function createTruckCompanyData(
  companyName,
  city,
  state,
  mcAuthority,
  scac,
  officePhone,
  officeFax,
  cellPhone,
  id,
) {
  return {
    companyName,
    city,
    state,
    mcAuthority,
    scac,
    officePhone,
    officeFax,
    cellPhone,
    id,
  };
}

export function createFreightAgentData(
  fullName,
  brokerageCompany,
  email,
  cellPhone,
  billingPhone,
  id,
) {
  return {
    fullName,
    brokerageCompany,
    email,
    cellPhone,
    billingPhone,
    id,
  };
}

export function createIndependentDispatcherData(
  fullName,
  company,
  email,
  contactNumber1,
  contactNumber2,
  id,
) {
  return {
    fullName,
    company,
    email,
    contactNumber1,
    contactNumber2,
    id,
  };
}

export function createCustomerData(customerName, id, billingAddress, phone, email) {
  return {
    customerName,
    id,
    billingAddress,
    phone,
    email,
  };
}

export const rowsData = [
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
  createDriverData(
    'John Doe',
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`,
  ),
];
