import React from 'react';
import PDFPrinter from './PDFPrinter';
import PropTypes from 'prop-types';

const ControlPanel = (props) => {
  const { file, pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="control-panel m-3 p-3 d-flex align-items-baseline justify-content-between">
      <div className="d-flex justify-content-between align-items-baseline">
        {/* <i
          className={`fas fa-fast-backward mx-3 ${firstPageClass}`}
          onClick={goToFirstPage}
        /> */}
        <span onClick={goToFirstPage} className={`fas fa-fast-backward mx-3 ${firstPageClass}`}>{'<<'}</span>
        {/* <i
          className={`fas fa-backward mx-3 ${firstPageClass}`}
          onClick={goToPreviousPage}
        /> */}
        <span onClick={goToPreviousPage} className={`fas fa-backward mx-3 ${firstPageClass}`}>{'<'}</span>
        <span>
          Page{' '}
          <input
            name="pageNumber"
            type="number"
            min={1}
            max={numPages || 1}
            className="p-0 pl-1 mx-2"
            value={pageNumber}
            onChange={onPageChange}
          />{' '}
          of {numPages}
        </span>
        {/* <i
          className={`fas fa-forward mx-3 ${lastPageClass}`}
          onClick={goToNextPage}
        /> */}
        <span onClick={goToNextPage} className={`fas fa-forward mx-3 ${lastPageClass}`}>{'>'}</span>
        {/* <i
          className={`fas fa-fast-forward mx-3 ${lastPageClass}`}
          onClick={goToLastPage}
        /> */}
        <span onClick={goToLastPage} className={`fas fa-fast-forward mx-3 ${lastPageClass}`}>{'>>'}</span>
      </div>
      <div className="d-flex justify-content-between align-items-baseline">
        {/* <i
          className={`fas fa-search-minus mx-3 ${zoomOutClass}`}
          onClick={zoomOut}
        /> */}
        <span onClick={zoomOut} className={`fas fa-search-minus mx-3 ${zoomOutClass}`}>-</span>
        <span>{(scale * 100).toFixed()}%</span>
        <span onClick={zoomIn} className={`fas fa-search-plus mx-3 ${zoomInClass}`}>+</span>
        {/* <i
          className={`fas fa-search-plus mx-3 ${zoomInClass}`}
          onClick={zoomIn}
        /> */}
      </div>
      <div className="mx-3">
        <a href="/assets/docs/file-sample.pdf" download={true} title="download">
          <i className="fas fa-file-download clickable" />
        </a>
      </div>
      <div className="mx-3">
        <PDFPrinter file={file} />
      </div>
      <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: 10,
        fontWeight: 'bold',
      }}
      className={`fas fa-search-minus mx-3`}
      onClick={() => {
        setPageNumber(1);
        setScale(1.0);
        props.onClose();
      }}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <line x1="1" y1="14" x2="14" y2="1" stroke="black" strokeWidth="2" />
        <line x1="1" y1="1" x2="14" y2="14" stroke="black" strokeWidth="2" />
      </svg>
    </div>
    </div>
  );
};

export default ControlPanel;

ControlPanel.propTypes = {
    file: PropTypes.any.isRequired, onClose: PropTypes.any.isRequired, pageNumber: PropTypes.any.isRequired, numPages: PropTypes.any.isRequired, setPageNumber: PropTypes.any.isRequired, scale: PropTypes.any.isRequired, setScale: PropTypes.any.isRequired
  };