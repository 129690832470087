import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer, CreateDriver, EditDriver } from '../../..';
import './CreateCustomerModal.scss';

interface IUserModal {
  open: boolean;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setSelectedDriver?: any;
  editMode?: boolean;
  selectedDriver?: any;
  selectedTC?: any;
}

const CreateDriverModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setSelectedDriver,
  editMode,
  selectedDriver,
  selectedTC,
}) => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newDriver, setNewDriver] = useState<any>(null);
  const [updatedDriver, setUpdatedDriver] = useState<any>(null);

  useEffect(() => {
    if (!open && setDefaultData) {
      setDefaultData(null);
    }
  }, [open]);

  useEffect(() => {
    if (updatedDriver) {
      if (updatedDriver.id && updatedDriver.value) {
        const filteredUsersList = usersList.filter((user: any) => user.id !== selectedDriver.id)
        setUsersList([...filteredUsersList, updatedDriver]);
        setSelectedDriver(updatedDriver);
        setClickOnSubmit(false);
        setOpen(false);
        setUpdatedDriver(null);
      }
    }
  }, [updatedDriver]);

  useEffect(() => {
    if (newDriver) {
      if (newDriver.id && newDriver.value) {
        setUsersList([...usersList, newDriver]);
        setSelectedDriver(newDriver);
        setClickOnSubmit(false);
        setOpen(false);
        setNewDriver(null);
      }
    }
  }, [newDriver]);

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      onConfirm={() => setClickOnSubmit(true)}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <>
        {editMode ? <EditDriver
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          selectedDriver={selectedDriver}
          setUpdatedDriver={setUpdatedDriver}
          selectedTC={selectedTC}
        /> : <CreateDriver
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          setNewDriver={setNewDriver}
          selectedTC={selectedTC}
        />}
        </>
      }
    />
  );
};

export default CreateDriverModal;
