import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user';
import truckCompanyReducer from './truckCompany/truckCompany';
import independentDispatcherReducer from './independentDispatcher/independentDispatcher';
import freightAgentReducer from './freightAgent/freightAgent';
import driverReducer from './driver/driver';
import loadsReducer from './loads/loads';
import loadReducer from './load/load';
import paymentReducer from './payment/payment';
import customerReducer from './customer/customer';
import specificLoadReducer from './specificLoad/specificLoad';
import notificateReducer from './notification/notification';
import reportsReducer from './reports/reports';
import invoicingReducer from './invoicing/invoicing';

export const store = configureStore({
  reducer: {
    user: userReducer,
    truckCompany: truckCompanyReducer,
    independentDispatcher: independentDispatcherReducer,
    freightAgent: freightAgentReducer,
    driver: driverReducer,
    customer: customerReducer,
    loads: loadsReducer,
    specificLoad: specificLoadReducer,
    notification : notificateReducer,
    reports: reportsReducer,
    invoicing: invoicingReducer,
    load: loadReducer,
    payment: paymentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
