import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { SignInModel, SignUpModel } from '../../models/user/user.model';

export const fetchSignIn = createAsyncThunk('signin', async (form: SignInModel) => {
  const response = await api.post('login', form);
  return response.data;
});

export const fetchSignUp = createAsyncThunk('signup', async (form: SignUpModel) => {
  const response = await api.post('signup', form);
  return response.data;
});

export const fetchLogOut = createAsyncThunk('logout', async () => {
  const response = await api.get('logout');
  return response.data;
});

export const fetchRecoveryPassword = createAsyncThunk(
  'recoveryPassword',
  async (email: any) => {
    const response = await api.post('password/recovery', email);
    return response.data;
  },
);

export const getUsersList = createAsyncThunk('usersList', async (data: any) => {
  const response = await api.get(`users/${data.entity_type}/${data.entity_id}/list`);
  return response.data;
});

export const createNewUser = createAsyncThunk('createUser', async (data: any) => {
  const response = await api.post(
    `users/${data.entity_type}/${data.entity_id}/user/create`,
    data.userData,
  );
  return response.data;
});

export const editUser = createAsyncThunk('editUser', async (data: any) => {
  const response = await api.post(
    `users/${data.entity_type}/${data.entity_id}/user/${data.userData.id}/update`,
    data.userData,
  );
  return response.data;
});

export const deleteUser = createAsyncThunk('deleteUser', async (data: any) => {
  const response = await api.post(
    `users/${data.entity_type}/${data.entity_id}/user/${data.user_id}/delete`,
  );
  return response.data;
});

export const fetchResetPassword = createAsyncThunk('resetPassword', async (data: any) => {
  const response = await api.post('password/restore', data);
  return response.data;
});

export const refreshToken = createAsyncThunk('refreshToken', async (token: string) => {
  const response = await api.post('refresh-token', { refresh_token: token });
  return response.data;
});

export const emailVerify = createAsyncThunk('emailVerify', async (token: string) => {
  const response = await api.post('verify-user', { verify_token: token });
  return response.data;
});

export const getUserInfo = createAsyncThunk('getUserInfo', async (data: any) => {
  const response = await api.get(
    `users/${data.entity_type}/${data.entity_id}/user/${data.user_id}/get`,
  );
  return response.data;
});

export const getUser = createAsyncThunk('getUser', async () => {
  const response = await api.get('user');
  return response.data;
});

export const globalDeleteCompany = createAsyncThunk('globalDeleteCompany', async (data: any) => {
  const response = await api.post(`${data.type}/${data.id}/delete`);
  return response.data;
});

interface AuthState {
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  errorMessage: string;
  companyName: any;
  global_access: boolean;
  isSubsidiary: boolean;
  canDelete: boolean;
}

const initialState: AuthState = {
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  errorMessage: '',
  companyName: null,
  global_access: true,
  isSubsidiary: false,
  canDelete: false,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    //Sign In fetch
    builder.addCase(fetchSignIn.fulfilled, (state, action) => {
      localStorage.removeItem('isSeenBlockAccess');
      const userData = {
        first_name: action.payload.resource.profile.first_name,
        last_name: action.payload.resource.profile.last_name,
        email: action.payload.resource.email,
        role: action.payload.resource.role.alias,
        company_name: action.payload.resource.entity.owner.title,
        is_profile_filled: action.payload.resource.entity.owner.is_profile_filled,
        creator_id: action.payload.resource.entity.creator_id,
        profile_id: action.payload.resource.profile_id,
        id: action.payload.resource.id,
      };
      state.email = action.payload.resource.email;
      state.firstName = action.payload.resource.profile.first_name;
      state.lastName = action.payload.resource.profile.last_name;
      state.role = action.payload.resource.role.alias;
      state.accessToken = action.payload.resource.credentials.access_token;
      localStorage.setItem('token', action.payload.resource.credentials.access_token);
      localStorage.setItem(
        'refresh_token',
        action.payload.resource.credentials.refresh_token,
      );
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem(
        'owner_id',
        JSON.stringify(action.payload.resource.entity.owner_id),
      );
      localStorage.setItem(
        'entity_id',
        JSON.stringify(action.payload.resource.entity.id),
      );
    });
    builder.addCase(fetchSignIn.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    //Sign Up fetch
    builder.addCase(fetchSignUp.fulfilled, (state, action) => {});
    builder.addCase(fetchSignUp.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    //Log out fetch
    builder.addCase(fetchLogOut.fulfilled, (state, action) => {
      localStorage.clear()
    });
    builder.addCase(fetchLogOut.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    //Sends a token for recovering password to a user's email
    builder.addCase(fetchRecoveryPassword.fulfilled, (state, action) => {
      toast.success(
        'If the email entered exists in our system, you will be emailed password instructions. Please be sure to check your spam folder.',
      );
    });
    builder.addCase(fetchRecoveryPassword.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    //Set New Password
    builder.addCase(fetchResetPassword.fulfilled, (state, action) => {
      toast.success('Your password has been successfully changed.');
    });
    builder.addCase(fetchResetPassword.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    //Refresh Token
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      localStorage.setItem('token', action.payload.resource.access_token);
      localStorage.setItem('refresh_token', action.payload.resource.refresh_token);
      window.location.reload();
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    builder.addCase(getUsersList.fulfilled, (state, action) => {
      // localStorage.setItem('token', action.payload.resource.credentials.access_token);
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    builder.addCase(createNewUser.fulfilled, (state, action) => {
      toast.success('Contact Person has been successfully created!');
    });
    builder.addCase(createNewUser.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    builder.addCase(editUser.fulfilled, (state, action) => {
      toast.success('Contact Person has been successfully edited!');
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.global_access = action?.payload?.resource?.entity?.is_failed_payments ? false : true;

      if (action?.payload?.resource?.entity?.founder_id === action?.payload?.resource?.id || action?.payload?.resource?.entity?.creator_id) {
        state.canDelete = true;
      }

      if (action?.payload?.resource?.entity?.is_failed_payments && !action?.payload?.resource?.entity?.creator_id) {
        toast.warning('The application failed to process the recent payment. For full access, please go to the payment history section and retry the payment.', {
          autoClose: 15000,
        })
      }

      if (action?.payload?.resource?.entity?.creator_id) {
        state.isSubsidiary = true;
      }

      if (!action?.payload?.resource?.entity?.creator_id && action?.payload?.resource?.entity?.owner?.title) {
        state.companyName = action?.payload?.resource?.entity?.owner?.title
      } else if (action?.payload?.resource?.entity?.owner?.title) {
        state.companyName = action?.payload?.resource?.entity?.owner?.title
      } else {
        state.companyName = action?.payload?.resource?.email
      }

      const userData = {
        role: action.payload.resource.role.alias
      }
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem(
        'owner_id',
        JSON.stringify(action.payload.resource.entity.owner_id),
      );
      localStorage.setItem(
        'entity_id',
        JSON.stringify(action.payload.resource.entity.id),
      );
      localStorage.setItem(
        'user_id',
        JSON.stringify(action.payload.resource.id),
      );

    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });
  },
});

export default user.reducer;
