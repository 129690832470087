import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import { createTruck, deleteEquipmentType, deleteTrailer, deleteTruck } from '../../../../../store/load/load';
import { deleteDriver } from '../../../../../store/driver/driver';
import { deleteCustomer } from '../../../../../store/customer/customer';
import { deleteIndependentDispatcher } from '../../../../../store/independentDispatcher/independentDispatcher';

interface IDeleteModal {
  open: any;
  setOpen: any;
  onSubmit?: any;
}

const DeleteOptionModal: FC<IDeleteModal> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  return (
    <Modal
      open={open}
      onConfirm={onSubmit}
      confirmTitle={'DELETE'}
      cancelTitle={'CANCEL'}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="delete-item-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="label">Are you sure you want to delete it?</div>
        </div>
      }
    />
  );
};

export default DeleteOptionModal;
