import { Suspense, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/global';
import '../MyProfileTC/MyProfileTC.scss';
import { Button } from '../../../controls';
import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { KeyboardBackspaceTwoTone as KeyboardBackspaceTwoToneIcon } from "@mui/icons-material"
import { useNavigate } from 'react-router';
import TransactionDetails from './TransactionDetails';
import Loader from '../../../common/Loader/Loader';
import { getPaymentsList } from '../../../../store/payment/payment';
import { Elements, useElements } from '@stripe/react-stripe-js';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';

const PaymentsHistory = () => {
  const card = useAppSelector(state => state.payment.card);
  const loading = useAppSelector(state => state.payment.listLoading);
  const paymentsList = useAppSelector(state => state.payment.paymentsList);
  const needUpdate = useAppSelector(state => state.payment.needUpdateList);
  const publishKey = useAppSelector(state => state.payment.stripePublishKey);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [transactionDetails, setTransactionDetails] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    dispatch(getPaymentsList());
  }, [])

  useEffect(() => {
    if (needUpdate) {
      dispatch(getPaymentsList());
    }
  }, [needUpdate])

  useEffect(() => {
    if (paymentsList) {
      const transactions = [];
      paymentsList.map(item => {
        let errorMsg = null;
        if ((item.status === 'failed' || item.status === 'confirmation') && item.error_msg) {
          errorMsg = item.error_msg;
        }
        const _date = moment(item.created_at);
        const _from = moment(item.from);
        const _to = moment(item.to);
        const _formatted_date = moment(_date, 'DD/MM/YYYY').format('MM/DD/YYYY');
        const _formatted_from = moment(_from, 'DD/MM/YYYY').format('MM/DD/YYYY');
        const _formatted_to = moment(_to, 'DD/MM/YYYY').format('MM/DD/YYYY');
        transactions.push({
          date: _formatted_date,
          from: _formatted_from,
          to: _formatted_to,
          total_paid: item.total_amount,
          monthly_paid: item.monthly_amount,
          package: item.package,
          receipt: item.receipt,
          status: item.status,
          id: item.id,
          errorMsg,
        })
      })
      setPayments(transactions);
    }
  }, [paymentsList])

  useEffect(() => {
    if (publishKey) {
      const stripePromise: any = loadStripe(publishKey);
      setStripePromise(stripePromise);
    }
  }, [publishKey]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  return (
    <div style={{ margin: '0px 30px' }}>
      <Loader loading={loading} />
      <div className="h2-title" style={{ marginTop: 40, marginBottom: 25 }}>
        <h2 className="h2-title__text" style={{marginBottom: 0}}>{transactionDetails ? 'Transaction details' : 'Payments and Usage History'}</h2>
        {transactionDetails && <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() => setTransactionDetails(null)}
                  className="mapBtn"
                  variant="outlined"
        />}
      </div>

      <div className="dashboardMain">
      {transactionDetails ? 
              stripePromise && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Elements stripe={stripePromise}>
        <TransactionDetails details={transactionDetails} setTransactionDetails={setTransactionDetails}/>
        </Elements>
        </Suspense>)
      : <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell>
                Package
                {/* <img
                  src={require('../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  // onClick={() => changeSortType('load_number')}
                /> */}
              </StyledTableCell>
              <StyledTableCell align="right">Date</StyledTableCell>
              <StyledTableCell align="right">Total Amount</StyledTableCell>
              <StyledTableCell align="right">Monthly Amount</StyledTableCell>
              <StyledTableCell align="right">From</StyledTableCell>
              <StyledTableCell align="right">To</StyledTableCell>
              <StyledTableCell align="right" style={{textAlign: "initial"}}>
                Status
                {/* <img
                  src={require('../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  // onClick={() => changeSortType('stops')}
                />{' '} */}
              </StyledTableCell>
              <StyledTableCell align="right">
                {' '}
                Receipt{' '}
                {/* <img
                  src={require('../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  // onClick={() => changeSortType('weight')}
                /> */}
              </StyledTableCell>
            </StyledTableHeadRow>
          </TableHead>

          {/* tempolary solution for working with real data and static */}
          {payments && (
                <TableBody>
                  {payments.map((row: any, i: number) => {
                    console.log('row.date', row.date)
                    return <StyledTableRow
                      key={`${row.id}`}
                      onClick={() => {
                        setTransactionDetails(row)
                      }}
                      // className={`${
                      //   row.status === 'in-progress'
                      //     ? 'inProgressRow'
                      //     : row.status === 'completed'
                      //     ? 'completedRow'
                      //     : 'pendingRow'
                      // } ${selectedLoadsIds?.find((id: any) => id === row.id) ? 'selectedLoadRow' : ''}`}
                      // onClick={(ev: any) => onRowHandleClick(row.id, ev)}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className="loadsCell"
                        style={{textDecoration: "none"}}
                      >
                        <span style={{display: "flex", alignItems: "center"}}>
                        <span style={{fontWeight: "bold", display: "inline-block", marginRight: 10, fontSize: 30, color: '#eb0000', opacity: row.status === 'failed' ? 1 : 0}}>&#8226;</span>
                        <span style={{textDecoration: "underline"}}>{row.package}</span>
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft" >
                          {row.date}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft" >
                          {row.total_paid ? `$${row.total_paid}` : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft" >
                          {row.monthly_paid ? `$${row.monthly_paid}` : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft" >
                          {row.from ? `${row.from}` : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft" >
                          {row.to ? `${row.to}` : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{textAlign: "initial"}}>
                        <div className={`statusIcon ${
                            row.status === 'free'
                              ? 'statucIconInProgress'
                              : row.status === 'Paid'
                              ? 'statucIconPaid'
                              : (row.status === 'failed' || row.status === 'confirmation')
                              ? 'statucIconFailed'
                              : row.status === 'Refunding'
                              ? 'statucIconRefunding'
                              : 'statucIconPending'
                          }`}
                        ></div>
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="nowrapText" >
                      {row.receipt && <img
                        src={require('../../../../assets/images/icons/download.png')}
                        alt="share"
                        width="18px"
                        height="18px"
                        className="sortBtn"
                        // onClick={() => changeSortType('stops')}
                      />}
                      </StyledTableCell>
                    </StyledTableRow>
                  })}
                </TableBody>
              )}
        </Table>
      </StyledTableContainer>}
    </div>

    {!transactionDetails && <Button
            title="Export to pdf"
            // onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="saveBtnPayment paymentHistory"
            // loading={submitLoading}
    />}
    </div>
  );
};

export default PaymentsHistory;