import { FC, useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import './Aside.scss';
import { displayProtectedImage } from '../../../../../helpers';

interface IAside {
  selectedInvoice?: boolean;
  resetAside: () => void;
  activeBols: any;
  generateDocument: (id: any) => void;
  selectedLoad: any;
}

const Aside: FC<IAside> = ({ selectedInvoice, resetAside, activeBols, generateDocument, selectedLoad }) => {
  const token = localStorage.getItem('token');

  // const testBols = [
  //   {
  //     thumbnail: 'https://api.freightlife.dnmiss.com/storage/temp/iDzDs8JKiwruWAeY9VEwrK9gFwkQDj2nenPqhQOO_thumbnail.jpg',
  //   },
  //   {
  //     thumbnail: 'https://api.freightlife.dnmiss.com/storage/temp/iDzDs8JKiwruWAeY9VEwrK9gFwkQDj2nenPqhQOO_thumbnail.jpg',
  //   },
  //   {
  //     thumbnail: 'https://api.freightlife.dnmiss.com/storage/temp/iDzDs8JKiwruWAeY9VEwrK9gFwkQDj2nenPqhQOO_thumbnail.jpg',
  //   },
  //   {
  //     thumbnail: 'https://api.freightlife.dnmiss.com/storage/temp/iDzDs8JKiwruWAeY9VEwrK9gFwkQDj2nenPqhQOO_thumbnail.jpg',
  //   },
  //   {
  //     thumbnail: 'https://api.freightlife.dnmiss.com/storage/temp/iDzDs8JKiwruWAeY9VEwrK9gFwkQDj2nenPqhQOO_thumbnail.jpg',
  //   },
  // ]

  return (
    <div className="aside aside-container">
      <div className="aside-head">
        <div className="aside-title">Create Invoice</div>
        <div className="aside-subtitle">Please see instruction below</div>
        <div className="aside-arrow">
          <img
            src={require('../../../../../assets/images/icons/arrow-down.png')}
            alt="arrow"
          />
        </div>
        <div className="aside-load-number">Load Number</div>
        <div className="aside-load-number" style={{color: "#4eda3f", fontSize: 24, marginTop: 15}}>{selectedLoad?.load_number}</div>
      </div>
      <div className="aside-main">
        {!selectedInvoice && (
          <div className="aside-empty">
            <p>
              Please choose load from loads lists from LHS. After choose in this area you
              will be see preview icon for load details and BOL scan.
            </p>
            <p>
              At the bottom of that screen please click a button that says, Invoice
              preview and submission.
            </p>
            <p>
              After that, you can view the contents of all files and edit the information
              if necessary.{' '}
            </p>
          </div>
        )}
        {selectedInvoice && (
          <div className="aside-media_container">
            <div className="aside-media_title">Bols</div>
            <div className={`aside-media_wrap `} style={{overflowY: activeBols?.length ? 'scroll' : 'visible'}}>
              {activeBols?.map((bol: any, i: number) => {
                displayProtectedImage(`acide-bol-${i}`, bol.thumbnail, token);
                return (
                  <div className="asideBolContainer" key={`activeBol-${i}`}>
                    <div className="aside-media_item" id={`acide-bol-${i}`}>
                      {/* <a
                        href={bol.file}
                        rel="noreferrer"
                        target="_blank"
                        className="fullpathLink"
                      >
                        click
                      </a> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {selectedInvoice && selectedLoad && (
        <div className="aside-btns">
          <Stack spacing={2} direction="column">
            <Button size="large" className="btn-invoice" variant="contained" onClick={() => generateDocument(selectedLoad?.id)}>
              Invoice preview and submission
            </Button>
            <Button
              size="large"
              className="btn-reset"
              variant="outlined"
              onClick={() => resetAside()}
            >
              reset
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default Aside;
